import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { convertDateWithFullMonth } from '../../utility';
import { CouponItemProps } from './interface';
import styles from './myCoupon.module.scss';

interface CouponAction extends CouponItemProps {
  onItemClick?: (item: CouponItemProps) => void;
  isPublic?: boolean;
  showBackground?: boolean;
  isDetail?: boolean;
}

const CouponItem = (props: CouponAction) => {
  const {
    onItemClick,
    isPublic = false,
    showBackground = true,
    isDetail = false,
    ...rest
  } = props;
  const { t } = useTranslation();
  const isUnavailable = isPublic
    ? props.status !== 'Available' && props.status !== 'Transferred'
    : props.status !== 'Available';
  // console.log('props', props);
  const handleStatusLayout = useCallback(() => {
    switch (props.status) {
      case 'Expired':
        return (
          <div className={`${styles.status} grey`}>{t('coupon.expired')}</div>
        );
      case 'Used':
        return (
          <div className={`${styles.status} grey`}>{t('coupon.used')}</div>
        );
      default:
        return (
          <div className={`${styles.status} yellow`}>
            {t('coupon.transferred')}
          </div>
        );
    }
  }, [props.status]);

  return (
    <div className={styles.couponContainer}>
      {isUnavailable && handleStatusLayout()}
      <div
        className={styles.coupon}
        style={{
          backgroundImage:
            props.background && showBackground
              ? `url("${props.background}")`
              : 'none',
          backgroundColor: props.backgroundColor,
          opacity: isUnavailable ? 0.5 : 1,
        }}
        {...(onItemClick && { onClick: () => onItemClick({ ...rest }) })}
      >
        <div
          className={styles.left}
          {...(props.usedDate && { style: { gap: '6px' } })}
        >
          <div className={styles.name} style={{ color: props.primaryColor }}>
            {props.name}
          </div>
          {!isDetail && (
            <>
              <div
                className={styles.code}
                style={{ color: props.secondaryColor }}
              >
                {t('coupon.couponCode')}: {props.code}
              </div>
              <div
                className={styles.expiryDate}
                style={{ color: props.secondaryColor }}
              >
                {props.usedDate ? t('coupon.usedDate') + ': ' : null}
                {props.usedDate
                  ? convertDateWithFullMonth(props.usedDate)
                  : null}
                {props.usedDate && <br />}
                {t('coupon.validUntil')}:{' '}
                {convertDateWithFullMonth(props.expiryDate)}
              </div>
            </>
          )}
        </div>
        <div className={styles.right}>
          <div className={styles.logo}>
            <img src={props.logo} alt="coupon logo" />
          </div>
          <div
            className={styles.denomination}
            style={{ color: props.secondaryColor }}
          >
            HK
            <strong style={{ color: props.primaryColor }}>
              ${props.denomination}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponItem;
