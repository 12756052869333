import React, { FC } from 'react';

import {
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './search-field.module.scss';

interface SearchFieldProps extends InputProps {
  value: string;
  onReset: () => void;
  onClose?: () => void;
  onFilter?: () => void;
  activeFilter?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  showSearchIcon?: boolean;
}

const SearchField: FC<SearchFieldProps> = (props) => {
  const {
    value,
    onReset,
    onClose,
    onFilter,
    activeFilter,
    showSearchIcon = true,
    ...inputProps
  } = props;
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" className={styles.searchFieldSection}>
      <Grid item xs>
        <Input
          value={value}
          startAdornment={
            showSearchIcon && (
              <InputAdornment position="start" className={styles.searchIcon}>
                <img src="/assets/images/toolbar_search_black.svg" alt="" />
              </InputAdornment>
            )
          }
          endAdornment={
            value && (
              <InputAdornment position="end">
                <IconButton
                  id="resetButton"
                  disableRipple
                  className={styles.resetButton}
                  onClick={onReset}
                >
                  <img src="/assets/images/close_btn_filled.svg" alt="" />
                </IconButton>
              </InputAdornment>
            )
          }
          className={styles.searchField}
          {...inputProps}
          autoComplete="off"
          type="search"
        />
      </Grid>
      {onClose && (
        <Grid item xs="auto">
          <Button
            id="cancelButton"
            disableRipple
            className={styles.cancelButton}
            onClick={onClose}
          >
            {t('general.cancel')}
          </Button>
        </Grid>
      )}

      {onFilter && (
        <Grid item xs="auto">
          <img
            src={
              activeFilter
                ? '/assets/images/filter_active.svg'
                : '/assets/images/filter.svg'
            }
            onClick={onFilter}
          />
        </Grid>
      )}

      {props.children && (
        <Grid item xs="auto">
          {props.children}
        </Grid>
      )}
    </Grid>
  );
};

export default SearchField;
