import { FC, useEffect, useState } from 'react';

import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { setUserState, UserState } from '../../reducers/user-slice';
import {
  getPermission,
  postUserLoginSSO,
  postUserLoginTW,
  setAuthorizationToken,
} from '../../services/api-services';
import { getChatConfig } from '../../services/homepage';
import { getUserInfo } from '../../services/user';
import { RootState } from '../../store/store';
import { convertUserProfile, handleLogoutAction } from '../../utility';

interface CheckAuthorizationProps {
  children?: React.ReactNode;
}

const CheckAuthorization: FC<CheckAuthorizationProps> = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const initLocation =
    searchParams.get('initLocation') ??
    window.location.href.replace(window.location.origin, '');
  const [isLoading, setIsLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState({ open: false, type: '' });
  const [apiErrorMessages, setApiErrorMessages] = useState<string>(
    'Api Error, please try again later'
  );
  const { t } = useTranslation();

  useEffect(() => {
    const storageAccessToken = localStorage.getItem('ACCESS_TOKEN');
    const storageRefreshToken = localStorage.getItem('REFRESH_TOKEN');
    const storageIsAcceptedTnc =
      localStorage.getItem('IS_ACCEPTED_TNC') ?? 'true';
    const storageMsToken = localStorage.getItem('MS_TOKEN');

    const requestParams = localStorage.getItem('SSO_REQUEST');
    // const requestParams = searchParams.get("request");

    const loginAndGetUserInfo = async (
      accessToken: string,
      refreshToken: string | null,
      isAcceptedTnc: boolean,
      msToken: string,
      isRefreshPage: boolean = false
    ) => {
      setIsLoading(true);
      setAuthorizationToken(accessToken);
      try {
        const permissionResponse = await getPermission();
        const response = await getUserInfo();
        const chatConfigResponse = await getChatConfig();
        const { userProfile } = response.data;
        let userInfo: UserState = convertUserProfile(userProfile) as UserState;

        userInfo = {
          ...userInfo,
          permission: permissionResponse.data,
          chat: chatConfigResponse.data,
          accessToken: accessToken,
          refreshToken: refreshToken ?? '',
          isAcceptedTnc: isAcceptedTnc,
        };
        if (msToken && msToken !== '') {
          userInfo = {
            ...userInfo,
            msToken: msToken,
          };
        }
        document.cookie = `Authorization=Bearer ${accessToken};domain=${process.env.REACT_APP_API_SUBDOMAIN};path=/;`;
        document.cookie = `Source=webapp;domain=${process.env.REACT_APP_API_SUBDOMAIN};path=/;`;

        dispatch(setUserState(userInfo));
        setIsLoading(false);
        if (isRefreshPage && initLocation && !initLocation.includes('/login')) {
          if (
            initLocation.includes('/survey') &&
            !initLocation.includes('/survey/form') &&
            !userInfo.permission?.SurveyAdmin
          ) {
            setAlertOpen({ open: true, type: 'permission' });
            navigate('/home');
          } else if (
            initLocation.includes('/event/create') &&
            !userInfo.permission?.EventAdmin
          ) {
            setAlertOpen({ open: true, type: 'permission' });
            navigate('/event');
          } else if (
            (initLocation.includes('/social-wall/create') ||
              initLocation.includes('/social-wall/edit')) &&
            !userInfo.permission?.SocialWallAdmin
          ) {
            setAlertOpen({ open: true, type: 'permission' });
            navigate('/social-wall');
          } else if (
            initLocation.includes('/partner-network/management') &&
            !userInfo.permission?.SocialWallAdmin
          ) {
            setAlertOpen({ open: true, type: 'permission' });
            navigate('/partner-network');
          } else {
            navigate(initLocation);
          }
        } else {
          if (isAcceptedTnc) {
            navigate(`/home`);
          } else {
            navigate(`/first-login`);
          }
        }
      } catch (error: any) {
        setIsLoading(false);
        navigate(`/login?status=${error.response.data.status}`);
      }
    };

    if (!window.location.pathname.includes('/receive-coupon')) {
      if (storageAccessToken) {
        // localStorage.removeItem("ACCESS_TOKEN");
        // localStorage.removeItem("REFRESH_TOKEN");
        // localStorage.removeItem("IS_ACCEPTED_TNC");
        // console.log('storageIsAcceptedTnc', storageIsAcceptedTnc === 'true');
        loginAndGetUserInfo(
          storageAccessToken,
          storageRefreshToken ?? '',
          storageIsAcceptedTnc === 'true',
          storageMsToken ?? '',
          true
        );
        // request params just for DEV, without Azure SSO
      } else if (requestParams) {
        setIsLoading(true);
        window.history.replaceState(null, '', '/login');

        if (
          process.env.REACT_APP_LOCATION === 'PHL' ||
          (process.env.REACT_APP_LOCATION === 'HK' &&
            localStorage.getItem('AAD_LOGIN') === 'true')
        ) {
          const request = JSON.parse(requestParams);
          const msToken = request.access_token;

          //eslint-disable-next-line
          postUserLoginSSO(request as any)
            .then((response: any) => {
              const accessToken = response.data.access_token;
              const refreshToken = response.data.refresh_token;
              const isAcceptedTnc = response.data.isAcceptedTnc;

              loginAndGetUserInfo(
                accessToken,
                refreshToken,
                isAcceptedTnc,
                msToken
              );
            })
            .catch((error: any) => {
              handleLogoutAction();
            });
        } else if (process.env.REACT_APP_LOCATION === 'TWN') {
          const request = {};
          //eslint-disable-next-line
          postUserLoginTW(request as any)
            .then((response: any) => {
              const accessToken = response.data.access_token;
              const refreshToken = response.data.refresh_token;
              const isAcceptedTnc = response.data.isAcceptedTnc;

              loginAndGetUserInfo(accessToken, refreshToken, isAcceptedTnc, '');
            })
            .catch((error: any) => {
              handleLogoutAction();
            });
        }
      } else if (!window.location.pathname.includes('/login')) {
        handleLogoutAction('/login?initLocation=' + window.location.pathname);
      }
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Not work on Mobile
  // useBeforeUnload(
  //   useCallback(() => {
  //     console.log("useBeforeUnload useCallback");
  //     console.log("userState.accessToken", userState.accessToken);
  //     if (userState.accessToken) {
  //       localStorage.setItem("ACCESS_TOKEN", userState.accessToken);
  //     }

  //     if (userState.refreshToken) {
  //       localStorage.setItem("REFRESH_TOKEN", userState.refreshToken);
  //     }

  //     if (userState.isAcceptedTnc) {
  //       localStorage.setItem(
  //         "IS_ACCEPTED_TNC",
  //         userState.isAcceptedTnc.toString()
  //       );
  //     }
  //   }, [userState])
  // );

  useEffect(() => {
    const storageAccessToken = localStorage.getItem('ACCESS_TOKEN');
    const storageRefreshToken = localStorage.getItem('REFRESH_TOKEN');
    const storageIsAcceptedTnc = localStorage.getItem('IS_ACCEPTED_TNC');
    const storageMsToken = localStorage.getItem('MS_TOKEN');

    if (userState.partnerId) {
      localStorage.setItem('PARTNER_ID', userState.partnerId);
    }

    if (userState.accessToken && storageAccessToken !== userState.accessToken) {
      localStorage.setItem('ACCESS_TOKEN', userState.accessToken);
      document.cookie = `Authorization=Bearer ${userState.accessToken};domain=${process.env.REACT_APP_API_SUBDOMAIN};path=/;`;
      document.cookie = `Source=webapp;domain=${process.env.REACT_APP_API_SUBDOMAIN};path=/;`;
    }
    if (
      userState.refreshToken &&
      storageRefreshToken !== userState.refreshToken
    ) {
      localStorage.setItem('REFRESH_TOKEN', userState.refreshToken);
    }
    if (storageIsAcceptedTnc !== userState.isAcceptedTnc.toString()) {
      // console.log(
      //   'userState.isAcceptedTnc.toString()',
      //   userState.isAcceptedTnc.toString()
      // );
      localStorage.setItem(
        'IS_ACCEPTED_TNC',
        userState.isAcceptedTnc.toString()
      );
    }
    if (userState.msToken && storageMsToken !== userState.msToken) {
      localStorage.setItem('MS_TOKEN', userState.msToken);
    }
    // const PwaIcon = document.querySelector('#pwa-icon');
    // const favicon = document.querySelector('#favicon');
    // console.log('favicon', favicon);
    // if (userState.avatar) {
    //   PwaIcon &&
    //     PwaIcon.setAttribute(
    //       'href',
    //       'https://sb-partnerapp-hk-web-dev.mtel.ws/logo512uat.png'
    //     );

    //   favicon &&
    //     favicon.setAttribute(
    //       'href',
    //       'https://sb-partnerapp-hk-web-dev.mtel.ws/logo512uat.png'
    //     );
    // }
  }, [userState]);

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <Snackbar
        open={alertOpen.open}
        autoHideDuration={6000}
        onClose={() => setAlertOpen({ open: false, type: '' })}
      >
        <Alert
          severity="error"
          onClose={() => setAlertOpen({ open: false, type: '' })}
        >
          {alertOpen.type === 'permission'
            ? t('general.withoutPermissionMsg')
            : apiErrorMessages}
        </Alert>
      </Snackbar>
      {props.children}
    </>
  );
};

export default CheckAuthorization;
