import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { CustomSwiper } from '../../components';

import styles from './sections.module.scss';

type Props = {};

const isHK = process.env.REACT_APP_LOCATION === 'HK';

const NewsSupportsSection = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const hkSwiperData = [
    {
      id: 'HKSC_PRO_Hub',
      image: '/assets/images/homepage_adp.png',
      title: t('homepage.contentHub.HKSC_PRO_Hub'),
      url: 'https://retailstarbucks1com.sharepoint.com/sites/HKSCPROHub2',
    },
    {
      id: 'MissionValue',
      image: '/assets/images/homepage_newsletters.png',
      title: t('homepage.contentHub.MissionValue'),
      url: 'https://retailstarbucks1com.sharepoint.com/sites/company/SitePages/Mission.aspx',
    },
    {
      id: 'theGreenBox',
      image: '/assets/images/homepage_theGreenBox.png',
      title: t('homepage.greenBox'),
      url: '/the-green-box',
    },
    {
      id: 'FWDMedicalClaim',
      image: '/assets/images/homepage_fwd.png',
      title: t('homepage.FWDMedicalClaim'),
      url: 'https://www.fwd.com.hk/en/business/claims/medical/',
    },
    {
      id: 'fidelityBeanStock',
      image: '/assets/images/homepage_fidelity.png',
      title: t('homepage.fidelityBeanStock'),
      url: 'https://nb.fidelity.com/static/mybenefits/netbenefitslogin/#/login',
    },
    {
      id: 'careerSite',
      image: '/assets/images/homepage_announcement.png',
      title: 'Career Site',
      url: 'https://apaccareers.starbucks.com/',
    },
    {
      id: 'BenefitSite',
      image: '/assets/images/homepage_partner_anniversary.png',
      title: t('homepage.contentHub.BenefitSite'),
      url: 'https://apacbenefits.starbucks.com/',
    },
    {
      id: 'more',
      image: '',
      title: t('homepage.more'),
      url: '/menu',
    },
  ];

  const swiperData = isHK
    ? hkSwiperData
    : [
        {
          id: 'Announcement',
          image: '/assets/images/homepage_announcement.png',
          title: t('homepage.contentHub.Announcements'),
          url: '/announcement',
        },
        {
          id: 'HKSC_PRO_Hub',
          image: '/assets/images/homepage_adp.png',
          title: t('homepage.contentHub.HKSC_PRO_Hub'),
          url: 'https://retailstarbucks1com.sharepoint.com/sites/HKSCPROHub2',
          disabled: process.env.REACT_APP_LOCATION === 'PHL',
        },
        {
          id: 'MissionValue',
          image: '/assets/images/homepage_newsletters.png',
          title: t('homepage.contentHub.MissionValue'),
          url: 'https://retailstarbucks1com.sharepoint.com/sites/company/SitePages/Mission.aspx',
          disabled: process.env.REACT_APP_LOCATION === 'PHL',
        },
        {
          id: 'partnerAnniversary',
          image: '/assets/images/homepage_partner_anniversary.png',
          title: t('homepage.contentHub.PartnerAnniversary'),
          url: '/partner-anniversary',
          disabled: process.env.REACT_APP_LOCATION === 'HK',
        },
        {
          id: 'theGreenBox',
          image: '/assets/images/homepage_theGreenBox.png',
          title: t('homepage.greenBox'),
          url: '/the-green-box',
          disabled: process.env.REACT_APP_LOCATION === 'PHL',
        },
        {
          id: 'GreenApronCard',
          image: '/assets/images/homepage_theGreenBox.png',
          title: t('homepage.contentHub.GreenApronCard'),
          url: '/green-apron-card',
          disabled: process.env.REACT_APP_LOCATION === 'PHL',
        },
        {
          id: 'FWDMedicalClaim',
          image: '/assets/images/homepage_fwd.png',
          title: t('homepage.FWDMedicalClaim'),
          url: '/FWDMedicalClaim',
          disabled: process.env.REACT_APP_LOCATION === 'PHL',
        },
        {
          id: 'BenefitSite',
          image: '/assets/images/homepage_partner_anniversary.png',
          title: t('homepage.contentHub.BenefitSite'),
          url: 'https://apacbenefits.starbucks.com/',
          disabled: process.env.REACT_APP_LOCATION === 'PHL',
        },
        {
          id: 'fidelityBeanStock',
          image: '/assets/images/homepage_fidelity.png',
          title: t('homepage.fidelityBeanStock'),
          url: 'https://nb.fidelity.com/static/mybenefits/netbenefitslogin/#/login',
          disabled: process.env.REACT_APP_LOCATION === 'PHL',
        },
        // {
        //   id: 'ADP',
        //   image: '/assets/images/homepage_adp.png',
        //   title: t('homepage.ADP'),
        //   url: '/adp',
        //   disabled: process.env.REACT_APP_LOCATION !== 'HK',
        // },
        // {
        //   id: 'newsletters',
        //   image: '/assets/images/homepage_newsletters.png',
        //   title: t('homepage.contentHub.Newsletters'),
        //   url: '/newsletters',
        //   disabled: process.env.REACT_APP_LOCATION === 'PHL',
        // },
        {
          id: 'faqs',
          image: '/assets/images/homepage_faqs.png',
          title: t('homepage.contentHub.Faq'),
          url: '/faqs',
        },
        {
          id: 'more',
          image: '',
          title: t('homepage.more'),
          url: '/menu',
          disabled: process.env.REACT_APP_LOCATION === 'TWN',
        },
      ];

  const getSwiperList = (data: any) => {
    return (
      <Box
        className={`${styles.swiperItem}${
          data.content.id === 'more' ? ` ${styles.more}` : ''
        }`}
        key={data.content.id}
        {...(data.content.image && {
          style: { backgroundImage: `url("${data.content.image}")` },
        })}
        onClick={() => {
          // open the url in new tab if it is external link
          if (data.content.url.startsWith('http')) {
            window.open(data.content.url, '_blank');
            return;
          }
          navigate(data.content.url);
        }}
      >
        <label>{data.content.title}</label>
      </Box>
    );
  };

  return (
    <Box className={styles.sectionContainer}>
      <Box className={styles.sectionWrapper}>
        <Box className={styles.sectionHeader}>
          <Typography
            className={styles.sectionTitle}
            variant="body1"
            style={{ fontSize: '18px' }}
          >
            {t('homepage.PRO')}
          </Typography>

          <img
            src="/assets/images/chevron_right.svg"
            alt=""
            onClick={() => {
              isHK ? navigate('/menu') : navigate('/pro');
            }}
          />
        </Box>
        <Box>
          <CustomSwiper
            data={swiperData.filter((item) => !item.disabled)}
            swiperId={`social-wall-swiper`}
            content={getSwiperList}
            slidesPerView={2.12}
            slidesPerGroup={1}
            spaceBetween={12}
            isAutoHeight={false}
            isAutoWidth
            isPagination
            isFreeMode
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NewsSupportsSection;
