import { createContext, useContext, useState } from 'react';

import { Helmet } from 'react-helmet';
import { NavigateFunction, Outlet } from 'react-router-dom';

import ReceiveCouponPopup from '../../components/receive-coupon-popup/receive-coupon-popup';

interface CouponContextProps {
  receiveCouponPopupOpen?: boolean;
  setReceiveCouponOpen?: (open: boolean) => void;
  navigate?: NavigateFunction;
}
type Props = {};

const CouponContext = createContext<CouponContextProps>({});

const CouponProvider = ({ children }: any) => {
  const [receiveCouponPopupOpen, setReceiveCouponOpen] = useState(true);

  return (
    <CouponContext.Provider
      value={{ receiveCouponPopupOpen, setReceiveCouponOpen }}
    >
      {children}
      <ReceiveCouponPopup />
    </CouponContext.Provider>
  );
};

const CouponContainer = (props: Props) => {
  return (
    <CouponProvider>
      <Helmet>
        <meta name="description" content="Digital Coupons" />
      </Helmet>
      <Outlet />
      {/* <BottomNavMenu /> */}
    </CouponProvider>
  );
};

export const useCoupon = () => useContext(CouponContext);
export { CouponContainer, CouponContext, CouponProvider };
