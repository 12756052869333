import SbChannelList from '@sendbird/uikit-react/ChannelList';
import { useNavigate } from 'react-router-dom';

import styles from './chat.module.scss';

export function ChannelList() {
  const navigate = useNavigate();
  return (
    <SbChannelList
      isMessageReceiptStatusEnabled={true}
      isTypingIndicatorEnabled={true}
      className={styles.channelList}
      disableAutoSelect
      onChannelSelect={(channel: any) => {
        navigate(`/chat/${channel.url}`);
      }}
    />
  );
}
