import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Popup, RoundButton } from '../../components';
import InputField from '../../components/input-field';

import { transferCoupons } from '../../services/coupon';
import { FormValueType, useFormHook } from '../../utility/useFormHook';
import styles from './transferCoupons.module.scss';

type Props = {
  title: any;
  selected: string[];
  transferPopupOpen: boolean;
  setTransferPopupOpen: (open: boolean) => void;
};

type TransferPopupContentProps = {
  selected: string[];
  shareTitle: string;
  onCancelClick: () => void;
  onConfirmClick: (remark: string) => void;
};

const TransferPopupContent = (props: TransferPopupContentProps) => {
  const { selected, shareTitle } = props;
  const { t } = useTranslation();
  // console.log(`${userState.name} shares with you ${selected} ${shareTitle}:`);
  const contentLimit = 50;
  const defaultFormValue: FormValueType = {
    remarks: {
      value: '',
      isError: false,
      isRequired: false,
    },
  };
  const { formValue, handleTextareaFieldChange } = useFormHook({
    value: defaultFormValue,
  });

  return (
    <Grid container className={styles.popupContent}>
      <p>{t('coupon.popupContent', { number: selected.length })}</p>
      <div className={styles.remarksText}>{t('coupon.remarks')}</div>
      <Box className={styles.formField}>
        <InputField
          id="remarks"
          name="remarks"
          placeholder={t('coupon.remarksHere')}
          value={formValue.remarks.value}
          error={formValue.remarks.isError}
          required={formValue.remarks.isRequired}
          multiline
          helperText={
            <Typography variant="body2" align="right">
              {`${formValue.remarks.value.length}/${contentLimit}`}
            </Typography>
          }
          onChange={(e) => {
            const { name, value } = e.target;
            handleTextareaFieldChange(name, value, contentLimit);
          }}
        />
      </Box>
      <Grid item className={styles.popupAction}>
        <RoundButton
          className={`reversetype ${styles.cancel}`}
          onClick={props.onCancelClick}
        >
          {t('general.cancel')}
        </RoundButton>
        <RoundButton
          disabled={formValue.remarks.isError}
          className={styles.confirm}
          onClick={() => props.onConfirmClick(formValue.remarks.value)}
        >
          {t('coupon.transfer')}
        </RoundButton>
      </Grid>
    </Grid>
  );
};

const TransferCouponPopup = (props: Props) => {
  const { t } = useTranslation();

  const handleSubmit = async (remark: string) => {
    try {
      console.log('handleSubmit', remark);
      console.log('selected', props.selected);
      const response = await transferCoupons({
        couponList: props.selected,
        remark: remark,
      });
      console.log('transferCoupons', response);
      console.log(
        'share',
        `${t('coupon.shareTitle', { userName: response.data.name, number: response.data.updateCount, couponTitle: props.title })}:`
      );
      console.log(
        `${window.location.origin}/receive-coupon/${response.data.hash}`
      );
      if (response.status === 200) {
        if (navigator.share) {
          navigator
            .share({
              title: `${t('coupon.shareTitle', { userName: response.data.name, number: response.data.updateCount, couponTitle: props.title })}:`,
              text: `${t('coupon.shareTitle', { userName: response.data.name, number: response.data.updateCount, couponTitle: props.title })}:`,
              url: `${window.location.origin}/receive-coupon/${response.data.hash}`,
            })
            .then(() => {
              props.setTransferPopupOpen(false);
              console.log('Successful share');
            })
            .catch((error) => console.error('Error sharing', error));
        }
        window.location.reload();
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <Popup
      id={styles.detailPopup}
      isOpen={props.transferPopupOpen}
      setIsOpen={(close: boolean) => props.setTransferPopupOpen(close)}
      title={<>{t('coupon.transferCoupons')}</>}
      content={
        <TransferPopupContent
          onCancelClick={() => props.setTransferPopupOpen(false)}
          selected={props.selected}
          shareTitle={props.title}
          onConfirmClick={handleSubmit}
        />
      }
      disableActions={true}
    />
  );
};

export default TransferCouponPopup;
