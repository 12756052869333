import React, { FC, useRef, useState } from 'react';

import {
  Alert,
  Avatar,
  Badge,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { Cropper, ReactCropperElement } from 'react-cropper';
import { useTranslation } from 'react-i18next';

import { formatFileSize } from '../../utility';
import InputField, { InputFieldProps } from '../input-field';
import Popup from '../popup';
import styles from './upload-file-field.module.scss';

import 'cropperjs/dist/cropper.css';

import theme from '../../theme';

interface UploadFileFieldProps
  extends Omit<InputFieldProps, 'value' | 'multiline' | 'onChange'> {
  value?: FileObjectType | Array<FileObjectType> | any | null;
  label?: string;
  inputFieldType?: 'input' | 'grid' | 'avatar' | 'post' | 'question' | 'answer';
  accept?: string;
  multiple?: boolean;
  dimension?: {
    width: number;
    height: number;
  };
  disableDimensionChecking?: boolean;
  limitCount?: number;
  limitSize?: number;
  enableEdit?: boolean;
  optionsTitle?: string;
  disabled?: boolean;
  // onChange: (files: FileObjectType | Array<FileObjectType> | null) => void;
  onChange: (files: File | Array<File> | null) => void;
  onRemove?: (files: FileObjectType | Array<FileObjectType> | null) => void;
  viewOnly?: boolean;
}

interface FileObjectType {
  id: string;
  fileName: string;
  type: string;
  data: string;
  size: number;
}

interface ReturnFileObjectType {
  status: boolean;
  file: File;
  message: string;
}

const UploadFileField: FC<UploadFileFieldProps> = (props) => {
  const { t } = useTranslation();
  const {
    inputFieldType = 'grid',
    dimension,
    disableDimensionChecking = false,
    limitCount,
    limitSize,
    enableEdit,
    optionsTitle = t('event.editCoverPhoto'),
    onChange,
    onRemove,
    viewOnly,
    ...inputProps
  } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [unsavedEditPopup, setUnsavedEditPopup] = useState(false);
  const [uploadFailPopup, setUploadFailPopup] = useState({
    open: false,
    msg: '',
  });
  const cropperRef = useRef<ReactCropperElement>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  let cropped = '';
  const [initCropped, setInitCropped] = useState({
    name: '',
    data: '',
  });
  const isStyled =
    inputFieldType === 'input' ||
    inputFieldType === 'question' ||
    inputFieldType === 'answer';
  let dimensionStyle = {};

  if (dimension) {
    dimensionStyle = {
      aspectRatio: `${dimension.width}/${dimension.height}`,
    };
  }

  const handleFileInputFieldClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    fileInputRef.current?.click();
  };

  const filesValidation = (
    file: File,
    fileDimensions:
      | {
          width: number;
          height: number;
        }
      | undefined,
    fileSize: number | undefined
  ) => {
    console.log('filesValidation', file);
    let reader = new FileReader();
    let img = new Image();
    let returnData: ReturnFileObjectType = {
      status: true,
      file: file,
      message: 'File is allowed',
    };

    return new Promise<ReturnFileObjectType>((resolve) => {
      reader.onloadend = () => {
        const readerResult = reader.result as string;

        if (fileSize && file.size > fileSize) {
          resolve({
            ...returnData,
            status: false,
            message: 'File size is not allowed',
          });
          return;
        }

        if (file.type.split('/')[0] === 'image') {
          img.src = readerResult;
          img.onload = () => {
            const imgWidth = img.width;
            const imgHeight = img.height;

            if (fileDimensions) {
              if (
                imgWidth !== fileDimensions.width ||
                imgHeight !== fileDimensions.height
              ) {
                resolve({
                  ...returnData,
                  status: false,
                  message: 'File dimension is not allowed',
                });
                return;
              }
            }

            if (enableEdit) {
              setInitCropped({
                name: file.name,
                data: readerResult,
              });
            }
            resolve(returnData);
            return;
          };
        } else {
          if (
            file.type.split('/')[0] !== 'application' &&
            file.type.split('/')[0] !== 'video' &&
            props.inputFieldType !== 'post'
          ) {
            resolve({
              ...returnData,
              status: false,
              message: 'File type is not allowed',
            });
          }

          resolve(returnData);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  const checkLimitCount = (files: FileList) => {
    if (!limitCount) return true;

    let existCount = 0;
    if (inputProps.value && Array.isArray(inputProps.value)) {
      existCount = inputProps.value.length;
    }

    return existCount + files.length <= limitCount;
  };

  const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (open) setOpen(false);
    const { files } = event.target;
    // console.log("handleUploadFile", files);
    if (files && files.length > 0) {
      if (!checkLimitCount(files)) {
        setUploadFailPopup({
          open: true,
          msg: t('event.maxAttachmentsAnsSize', {
            limit: 10,
            size: formatFileSize(10 * 1000 * 1000), //10MB
          }),
        });
        return false;
      }
      let imageCount = 0;

      let resolveList: Array<File> = [];
      let rejectList: Array<string> = [];

      if (Array.isArray(inputProps.value)) {
        imageCount = inputProps.value.length;
      }

      Promise.all(
        Array.from(files).map((data: File, index: number) => {
          return filesValidation(
            data,
            !disableDimensionChecking && dimension ? dimension : undefined,
            limitSize
          );
        })
      ).then((response) => {
        response.forEach((data) => {
          if (data.status) {
            resolveList.push(data.file);
          } else {
            rejectList.push(data.file.name);
          }
        });

        if (resolveList.length > 0) {
          if (inputProps.multiple) {
            onChange(resolveList);
          } else {
            if (enableEdit) {
              if (resolveList[0]) {
                setEditOpen(true);
              }
            } else {
              onChange(resolveList[0]);
            }
          }
        }
        console.log('rejectList', rejectList);
        if (rejectList.length > 0) {
          console.log('Promise', response);
          const limitSizeWording = limitSize ? formatFileSize(limitSize) : '';
          if (response[0].message === 'File type is not allowed') {
            setUploadFailPopup({
              open: true,
              msg: t('files.fileTypeNotAllow'),
            });
          } else {
            setUploadFailPopup({
              open: true,
              msg: t('event.exceedsFileSizeLimit', {
                file: rejectList.join(', '),
                size: limitSizeWording,
              }),
            });
          }
        }
      });
    }
  };

  const handleRemoveFile = (id?: string) => {
    if (!onRemove) return false;
    if (open) setOpen(false);
    if (id && Array.isArray(inputProps.value)) {
      const filterList = inputProps.value.filter(
        (value: FileObjectType) => value.id !== id
      );
      onRemove(
        filterList.map((value: FileObjectType, index: number) => ({
          ...value,
          id: index.toString(),
        }))
      );
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      onRemove(null);
    }
  };

  const handleCloseMsg = () => {
    setUploadFailPopup((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const PopupTitle = (props: {
    title: string;
    isReverse?: boolean;
    iconSrc?: string;
    onClose?: () => void;
  }) => {
    const { iconSrc = '/assets/images/close_btn.svg' } = props;

    return (
      <Stack
        direction={props.isReverse ? 'row-reverse' : 'row'}
        alignItems="center"
        gap="10px"
      >
        <Grid item xs>
          <Typography variant="h4">{props.title}</Typography>
        </Grid>
        <Grid item xs="auto">
          <IconButton
            disableRipple
            size="small"
            className={styles.closeButton}
            onClick={() => (props.onClose ? props.onClose() : setOpen(false))}
          >
            <img src={iconSrc} alt="" />
          </IconButton>
        </Grid>
      </Stack>
    );
  };

  const PopupOption = () => {
    return (
      <List className={styles.editOptionList}>
        <ListItem disablePadding>
          <ListItemButton
            className={styles.editOption}
            onClick={handleFileInputFieldClick}
          >
            <ListItemIcon>
              <img src="/assets/images/image_circle.svg" alt="" />
            </ListItemIcon>
            <ListItemText primary={t('event.selectImage')} />
          </ListItemButton>
        </ListItem>
        {/* {inputProps.value && enableEdit && (
          <ListItem disablePadding>
            <ListItemButton
              className={styles.editOption}
              onClick={() => setEditOpen(true)}
            >
              <ListItemIcon>
                <img
                  src="/assets/images/scheduledpostActionIcon_edit.svg"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText primary={t("general.edit")} />
            </ListItemButton>
          </ListItem>
        )} */}
        {onRemove && (
          <ListItem disablePadding>
            <ListItemButton
              className={styles.editOption}
              onClick={() => handleRemoveFile()}
            >
              <ListItemIcon>
                <img src="/assets/images/trash_alt.svg" alt="" />
              </ListItemIcon>
              <ListItemText primary={t('general.remove')} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    );
  };

  const EditIconButton = () => {
    return (
      <IconButton className={styles.editButton} onClick={() => setOpen(true)}>
        <img src="/assets/images/editpen.svg" alt="" />
      </IconButton>
    );
  };

  const GridType = () => {
    return inputProps.value ? (
      <Box className={styles.previewImage}>
        {!props.disabled && <EditIconButton />}
        {inputProps.value && Array.isArray(inputProps.value) ? (
          <img src={inputProps.value[0].data} alt="preview" />
        ) : (
          <img
            src={
              inputProps.value.imageUrl
                ? inputProps.value.imageUrl
                : inputProps.value.coverImageUrl
                  ? inputProps.value.coverImageUrl
                  : inputProps.value.data
            }
            alt="preview"
          />
        )}
      </Box>
    ) : (
      <>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={styles.uploadImage}
          onClick={handleFileInputFieldClick}
          data-error={inputProps.error}
        >
          <img
            src="/assets/images/upload_image_bg.png"
            alt=""
            className={styles.uploadImageBg}
          />
          <Grid item xs="auto">
            <img src="/assets/images/circle_plus.svg" alt="" />
          </Grid>
          <Grid item xs>
            <Typography variant="body1">{inputProps.label}</Typography>
            {dimension && (
              <Typography variant="caption">
                {t('event.dimension', {
                  width: dimension.width,
                  height: dimension.height,
                })}
              </Typography>
            )}
          </Grid>
        </Grid>
        {inputProps.error && inputProps.helperText && (
          <Typography
            variant="body2"
            className={styles.uploadImageHelperText}
            data-error={inputProps.error}
          >
            {inputProps.helperText}
          </Typography>
        )}
      </>
    );
  };
  const InputType = () => {
    return (
      <>
        {inputProps.value && (
          <Grid container spacing={1} sx={{ marginTop: 0, marginBottom: 0 }}>
            {Array.isArray(inputProps.value) ? (
              inputProps.value.map((value: FileObjectType, index: number) => {
                let thumbnail = value.data;
                const imageType = ['image', 'png', 'jpg', 'jpeg', 'gif'];
                if (
                  !imageType.includes(value.type.split('/')[0].toLowerCase())
                ) {
                  const applicationType = value.data.split('.').pop();
                  if (
                    applicationType === 'doc' ||
                    applicationType === 'docx' ||
                    applicationType === 'document'
                  ) {
                    thumbnail = '/assets/images/files/file_word.svg';
                  } else if (applicationType === 'pdf') {
                    thumbnail = '/assets/images/files/file_pdf.svg';
                  } else if (
                    applicationType === 'ppt' ||
                    applicationType === 'pptx'
                  ) {
                    thumbnail = '/assets/images/files/file_presentation.svg';
                  } else if (
                    applicationType === 'xls' ||
                    applicationType === 'xlsx'
                  ) {
                    thumbnail = '/assets/images/files/file_spreadsheet.svg';
                  } else if (
                    applicationType === 'mp4' ||
                    applicationType === 'mov'
                  ) {
                    thumbnail = '/assets/images/files/file_video.svg';
                  } else {
                    thumbnail = '/assets/images/files/file_other.svg';
                  }
                }

                return (
                  <Grid key={index} item xs={6}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      wrap="nowrap"
                      className={styles.multipleFileItem}
                    >
                      <Grid item xs="auto" className={styles.multipleFileIcon}>
                        <img src={thumbnail} alt="" />
                      </Grid>
                      <Grid item xs className={styles.multipleFileName}>
                        <Typography component="p" variant="caption">
                          {value.fileName}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs="auto"
                        alignSelf="flex-start"
                        className={styles.multipleFileRemove}
                      >
                        <IconButton
                          className={styles.removeButton}
                          onClick={() => handleRemoveFile(value.id)}
                        >
                          <img
                            src="/assets/images/close_btn_filled.svg"
                            alt="remove"
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                {inputProps.value.fileName}
              </Grid>
            )}
          </Grid>
        )}
        <Box onClick={handleFileInputFieldClick}>
          <InputField
            label={inputProps.placeholder}
            {...inputProps}
            value=""
            onChange={(e) => {
              e.preventDefault();
            }}
          />
        </Box>
      </>
    );
  };
  const AvatarType = () => {
    let imageData = '';
    if (inputProps.value) {
      if (Array.isArray(inputProps.value)) {
        imageData = inputProps.value[0].data;
      } else {
        imageData = inputProps.value.data;
      }
    }

    return (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={<EditIconButton />}
      >
        <Avatar
          alt="preview"
          src={imageData}
          className={styles.previewAvatar}
        />
      </Badge>
    );
  };
  const PostType = () => {
    return (
      <Box onClick={handleFileInputFieldClick} className="fileLabel">
        <img
          // key={index}
          src="/assets/images/postTypeIcon_media.svg"
          alt=""
          // onClick={() => {

          // handleChangePostType(item.action, false);
          // }}
        />
        {inputProps.label && (
          <Typography variant="body1">{inputProps.label}</Typography>
        )}
      </Box>
    );
  };
  const QuestionType = () => {
    return inputProps.value ? (
      <>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          gap={'10px'}
          className={`${styles.questionImage} ${styles.uploaded}}`}
        >
          <Grid className={styles.img}>
            <img src={inputProps.value} alt="uploaded" />
          </Grid>
          <Grid
            sx={{ display: 'flex', flex: 1 }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="body2"
              color={theme.vars.palette.black.opacity87}
            >
              {t('survey.create.uploaded')}
            </Typography>
            <Grid
              sx={{ display: 'flex' }}
              alignItems="center"
              justifyContent="center"
              gap={'10px'}
            >
              <IconButton
                className={styles.editButton}
                onClick={handleFileInputFieldClick}
              >
                <img src="/assets/images/survey_edit.svg" alt="remove" />
              </IconButton>
              <IconButton
                className={styles.removeButton}
                onClick={() => {
                  onRemove && onRemove(null);
                }}
              >
                <img
                  src="/assets/images/scheduledpostActionIcon_delete.svg"
                  alt="remove"
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    ) : (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        gap={'10px'}
        className={styles.questionImage}
        onClick={handleFileInputFieldClick}
        data-error={inputProps.error}
      >
        <Grid item xs="auto" className={styles.img}>
          <img src="/assets/images/survey_uploadImage.svg" alt="" />
        </Grid>
        <Grid item xs>
          <Typography variant="body2" className={styles.label}>{`${
            inputProps.label
          }${
            !inputProps.required ? ` ${t('general.optional')}` : ''
          }`}</Typography>
          <Typography variant="body2" className={styles.helperText}>
            {inputProps.helperText}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  const AnswerType = ({ viewOnly }: { viewOnly?: boolean }) => {
    return inputProps.value ? (
      <>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          gap={'10px'}
          className={styles.answerImage}
          style={{ ...dimensionStyle }}
          onClick={() => {
            if (viewOnly) return;
            handleFileInputFieldClick();
          }}
          data-error={inputProps.error}
        >
          <img
            className={styles.uploadedImg}
            src={inputProps.value}
            alt="uploaded"
          />
          {!viewOnly && (
            <span className={styles.editMsg}>{t('socialWall.edit')}</span>
          )}
        </Grid>
      </>
    ) : (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        gap={'10px'}
        className={styles.answerImage}
        style={{ ...dimensionStyle }}
        onClick={handleFileInputFieldClick}
        data-error={inputProps.error}
      >
        <img src="/assets/images/survey_answer.svg" alt="" />
      </Grid>
    );
  };

  const ReturnField = ({ viewOnly }: { viewOnly?: boolean }) => {
    switch (inputFieldType) {
      case 'input':
        return <InputType />;
      case 'question':
        return <QuestionType />;
      case 'avatar':
        return <AvatarType />;
      case 'post':
        return <PostType />;
      case 'answer':
        return <AnswerType viewOnly={viewOnly} />;
      default:
        return <GridType />;
    }
  };

  const CropperSection = () => {
    if (!editOpen) return null;
    let imageSrc = '';
    if (initCropped.data !== '') {
      imageSrc = initCropped.data;
      cropped = initCropped.data;
    } else {
      if (!inputProps.value || Array.isArray(inputProps.value)) return null;
      imageSrc = inputProps.value.data;
      cropped = inputProps.value.data;
    }

    let aspectRatioOption = {
      initialAspectRatio: 1,
      aspectRatio: 1,
    };
    let imageDimension = {
      aspectRatio: 1,
    };

    if (dimension) {
      aspectRatioOption = {
        initialAspectRatio: dimension.width / dimension.height,
        aspectRatio: dimension.width / dimension.height,
      };
      imageDimension = {
        aspectRatio: dimension.width / dimension.height,
      };
    }

    const onCrop = () => {
      const cropper = cropperRef.current?.cropper;
      if (cropper) {
        cropped = cropper.getCroppedCanvas().toDataURL('image/jpeg');
      }
    };

    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        className={styles.cropperContainer}
      >
        <Cropper
          ref={cropperRef}
          src={imageSrc}
          {...aspectRatioOption}
          guides={false}
          zoomable={false}
          viewMode={1}
          cropend={onCrop}
          autoCropArea={1}
          background={false}
          style={{
            display: 'block',
            width: '100%',
            ...imageDimension,
          }}
        />
        <Typography variant="body1">
          {t('myProfile.editProfile.dragToReposition')}
        </Typography>
      </Stack>
    );
  };

  const handleCancelCropped = () => {
    cropped = '';
    setUnsavedEditPopup(false);
    setEditOpen(false);
  };

  const handleConfirmCropped = async () => {
    setCroppedImage(
      cropperRef?.current?.cropper?.getCroppedCanvas()?.toDataURL('image/jpeg')
    );
    setEditOpen(false);
    if (Array.isArray(inputProps.value)) return false;
    if (cropped) {
      const fileName = initCropped.name
        ? initCropped.name
        : inputProps.value.fileName;

      try {
        const fileObject = await dataUrlToFile(
          cropperRef?.current?.cropper
            ?.getCroppedCanvas()
            ?.toDataURL('image/jpeg') as string,
          fileName
        );
        onChange(fileObject);
      } catch (error) {
        throw error;
      }

      cropped = '';
    }
  };

  async function dataUrlToFile(
    dataUrl: string,
    fileName: string
  ): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  }

  return (
    <Stack
      id={inputProps.id}
      className={styles.uploadFileSection}
      sx={isStyled ? {} : { ...dimensionStyle }}
      {...(inputFieldType === 'question' && { style: { width: '100%' } })}
    >
      <input
        ref={fileInputRef}
        name={inputProps.name}
        type="file"
        multiple={inputProps.multiple}
        accept={inputProps.accept}
        required={inputProps.required}
        onChange={handleUploadFile}
      />
      <ReturnField viewOnly={viewOnly} />
      {(inputProps.value || inputFieldType === 'avatar') && (
        <Popup
          isOpen={open}
          setIsOpen={(close: boolean) => setOpen(close)}
          title={<PopupTitle title={optionsTitle} />}
          content={<PopupOption />}
          disableActions
          onClickCancel={() => {
            setOpen(false);
          }}
          onClickConfirm={() => {
            setOpen(false);
          }}
        />
      )}
      {enableEdit && editOpen && (
        <>
          <Popup
            id={`cropperPopup-${inputProps.id}`}
            isOpen={editOpen}
            setIsOpen={(close: boolean) => setEditOpen(close)}
            title={
              <PopupTitle
                title={inputProps.label ?? ''}
                isReverse
                onClose={() => setUnsavedEditPopup(true)}
              />
            }
            content={<CropperSection />}
            fullScreen
            disableCancelClose
            confirmBtnText={t('general.save')}
            cancelBtnText={t('myProfile.editProfile.changePhoto')}
            onClickCancel={() => {
              fileInputRef.current?.click();
            }}
            onClickConfirm={handleConfirmCropped}
          />
          <Popup
            isOpen={unsavedEditPopup}
            setIsOpen={(close: boolean) => setUnsavedEditPopup(close)}
            title={t('myProfile.editProfile.unsavedChanges')}
            content={t('myProfile.editProfile.unsavedChangesDescription')}
            onClickCancel={() => {
              setUnsavedEditPopup(false);
            }}
            onClickConfirm={() => {
              handleCancelCropped();
            }}
          />
        </>
      )}

      <Snackbar
        open={uploadFailPopup.open}
        autoHideDuration={6000}
        onClose={handleCloseMsg}
      >
        <Alert severity="error">{uploadFailPopup.msg}</Alert>
      </Snackbar>
    </Stack>
  );
};

export default UploadFileField;
