import { AxiosPromise } from 'axios';

import { removeApiCacheSession } from '../utility';
import { instance, publicInstance } from './api-services';

const servicePath = '/coupon';

export const getAvailableCouponList = (params: {
  page: number;
  pageSize: number;
}): AxiosPromise =>
  instance({
    method: 'GET',
    id: `getCoupon-available-list-${params.page}-${params.pageSize}}`,
    url: `${servicePath}/list`,
    params: params,
  });

export const getCouponDetail = (id: string): AxiosPromise =>
  instance({
    method: 'GET',
    id: `getCoupon-detail-${id}`,
    url: `${servicePath}/detail/${id}`,
  });

export const getCouponsConfirmationCount = (): AxiosPromise =>
  instance({
    method: 'GET',
    id: `getCoupon-confirmations-count`,
    cache: { ttl: 10 * 1000 },
    url: `${servicePath}/couponsConfirmationCount`,
  });

export const confirmCoupons = (): AxiosPromise => {
  removeApiCacheSession('getCoupon-');
  return instance({
    method: 'POST',
    url: `${servicePath}/couponsConfirmation`,
  });
};

export const transferCoupons = (data: {
  couponList: string[];
  remark: string;
}): AxiosPromise => {
  removeApiCacheSession('getCoupon-');
  return instance({
    method: 'POST',
    url: `${servicePath}/transfer`,
    data,
  });
};

export const getHistoryCount = (): AxiosPromise =>
  instance({
    method: 'GET',
    id: `getCoupon-history-count`,
    url: `${servicePath}/historyCount`,
  });

export const getHistoryList = (params: {
  page: number;
  pageSize: number;
  type: string;
}): AxiosPromise =>
  instance({
    method: 'GET',
    id: `getCoupon-history-list-${params.type}-${params.page}-${params.pageSize}}`,
    url: `${servicePath}/historyList`,
    params: params,
  });

export const getTransferredCouponDetail = (id: string): AxiosPromise =>
  instance({
    method: 'GET',
    id: `getCoupon-transferred-detail-${id}`,
    url: `${servicePath}/transferredCouponDetail/${id}`,
  });

export const redeemCoupons = (data: { hash: string }): AxiosPromise => {
  return publicInstance({
    method: 'POST',
    url: `${servicePath}/redeem`,
    data,
  });
};

export const redeemFromDogeSoft = (data: { code: string }): AxiosPromise => {
  return publicInstance({
    method: 'POST',
    url: `${servicePath}/redeemFromDogeSoft`,
    data,
  });
};
