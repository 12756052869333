import React from 'react';

import styles from './splash-screen.module.scss';

const SplashScreen: React.FC = () => {
  return (
    <div className={`${styles.splashScreen}`}>
      <img
        src="/assets/images/Icon/StarbucksLogo.svg"
        alt="Starbucks Logo"
        className={`${styles.starbucksLogo}`}
      />
    </div>
  );
};

export default SplashScreen;
