import { useEffect, useState } from 'react';

import { Box, Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

import { NoResults } from '../../components';
import Header from '../../components/header/header';

import { getMyRewardsList } from '../../services/leaderboard';
import styles from './myRewards.module.scss';

function MyRewards() {
  useEffect(() => {
    setIsLoading(true);
    getMyRewardsList().then((response) => {
      setData(response.data.rewardWinningList);
      setIsLoading(false);
    });
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const navigate = useNavigate();

  const rewardItem = (item: any, index: number) => (
    <Stack
      direction="row"
      gap="12px"
      className={styles.rewardItemWrapper}
      onClick={() =>
        navigate(`/leaderboard/my-rewards/details/${item.rewardCampaignId}`)
      }
      key={index}
    >
      <Stack className={styles.rewardImageWrapper}>
        <img src={item.prizeImage} />
      </Stack>

      <Stack gap="8px">
        <Typography variant="body1">{item.prizeName}</Typography>
        <Typography variant="body2" className={styles.caption}>
          {item.rewardCampaignName}
        </Typography>
      </Stack>
      <Typography
        variant="subtitle2"
        className={`${styles.statusTag} ${
          item.isDelivered
            ? item.isReceived
              ? 'collected'
              : 'onTheWay'
            : 'pending'
        }`}
      >
        {t(
          `leaderboard.status.${
            item.isDelivered
              ? item.isReceived
                ? 'collected'
                : 'onTheWay'
              : 'pending'
          }`
        )}
      </Typography>
    </Stack>
  );

  return (
    <>
      <Box className={styles.headerContainer}>
        <Header
          title={t('leaderboard.myRewards')}
          enableBackButton
          disableBottomBorder
          closeButtonFunction={() => {
            navigate(-1);
          }}
        ></Header>
      </Box>

      {!isLoading && data?.length > 0 && (
        <Grid item xs className={styles.contentWrapper}>
          <Box className={styles.contentContainer}>
            {data?.map((record: any, index: number) =>
              rewardItem(record, index)
            )}
          </Box>
        </Grid>
      )}
      {!isLoading && data?.length === 0 && (
        <Box className={styles.noRewardContentContainer}>
          <NoResults resultsType="REWARD" />
        </Box>
      )}
    </>
  );
}
export default MyRewards;
