import React, { FC, forwardRef, useEffect, useRef, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import toHtml from 'html-react-parser';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Checkbox,
  DragAndDrop,
  Popup,
  RoundButton,
  ShortcutMenu,
} from '../../components';
import Header from '../../components/header/header';
import InputField from '../../components/input-field';

import { getSurvey, postCreateSurveyAnswer } from '../../services/survey';
import {
  checkExpiration,
  convertDate,
  convertPeriod,
  getApiDataByLang,
} from '../../utility';
import styles from './surveyForm.module.scss';

function SurveyForm(props: {
  data?: any;
  coverImage?: any;
  onClose?: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [filter, setFilter] = useState(Array<any>);
  const [open, setOpen] = useState<boolean>(true);
  const [formValue, setFormValue] = useState(Array<any>);
  const [activeQuestion, setActiveQuestion] = useState<number>(0);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [disableNext, setDisableNext] = useState<boolean>(true);
  const [exactNumberError, setExactNumberError] = useState<boolean>(false);
  const [wordCountError, setWordCountError] = useState<boolean>(false);
  const [surveyErrorCode, setSurveyErrorCode] = useState<string>(null!);
  const [isOtherError, setIsOtherError] = useState(false);
  useEffect(() => {
    if (
      activeQuestion > 0 &&
      (data?.questions[activeQuestion - 1].isRequired === false ||
        // (data?.questions[activeQuestion - 1].isRequired === true &&
        (Array.isArray(formValue[activeQuestion - 1]) &&
          formValue[activeQuestion - 1].length > 0) ||
        (!Array.isArray(formValue[activeQuestion - 1]) &&
          formValue[activeQuestion - 1] !== ''))
    ) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }
  }, [activeQuestion, formValue]);
  // useEffect(() => {
  //   console.log("exactNumberError", exactNumberError);
  // }, [exactNumberError]);
  const navigate = useNavigate();
  let { id } = useParams();
  useEffect(() => {
    setIsLoading(true);
    if (id && !props.data) {
      getSurvey(id as string, { isEdit: false })
        .then((response) => {
          setData(response.data.survey);
          const initFormValue = response.data.survey.questions.map(
            (item: any) => {
              if (item.questionType.name === 'rankOrder') {
                const options = item.orderAnswers.map(
                  (option: any, index: number) => {
                    return {
                      id: option._id,
                      index: index,
                      value: option.value,
                      ...(option.imageName && {
                        file: { value: option.imageName },
                      }),
                    };
                  }
                );
                return options;
              } else {
                return '';
              }
            }
          );
          setFormValue(initFormValue);

          setIsLoading(false);
        })
        .catch((error) => {
          const response = error.response;
          // console.log("error", response.data);
          // setSurveyErrorCode(true);
          if (
            response.data.status === 4014 ||
            response.data.status === 4086 ||
            response.data.status === 4003
          ) {
            setSurveyErrorCode(response.data.status as string);
          }
        });
    } else {
      const convertedData = props.data.questions.map((item: any) => {
        if (item.questionType === '6448afcf45a431c8981ff6d5') {
          const convertedItem = item.orderAnswers.map(
            (option: any, index: number) => {
              return {
                _id: index.toString(),
                value: option.value,
                ...(option.imageName && {
                  file: { value: option.imageName },
                  imageName: option.imageName,
                }),
              };
            }
          );
          return {
            ...item,
            orderAnswers: convertedItem,
          };
        } else if (item.questionType === '6448afac1dde4cea6034cda0') {
          const convertedItem = item.answerChoices.map(
            (option: any, index: number) => {
              return {
                _id: index.toString(),
                value: option.value,
                ...(option.imageName && {
                  file: { value: option.imageName },
                  imageName: option.imageName,
                }),
                ...(option.isOthers && {
                  isOthers: true,
                })
              };
            }
          );
          return {
            ...item,
            answerChoices: convertedItem,
          };
        } else {
          return item;
        }
      });
      setData({
        ...props.data,
        questions: convertedData,
        // coverImageUrl: props.coverImage?.data,
      });
      const initFormValue = props.data.questions.map((item: any) => {
        if (item.questionType === '6448afcf45a431c8981ff6d5') {
          const options = item.orderAnswers.map(
            (option: any, index: number) => {
              return {
                id: index.toString(),
                index: index,
                value: option.value,
                ...(option.imageName && {
                  file: { value: option.imageName },
                  imageName: option.imageName,
                }),
              };
            }
          );
          return options;
        } else {
          return '';
        }
      });
      setFormValue(initFormValue);
      setIsLoading(false);
    }
  }, []);
  const inputFieldLimit = 200;

  const handleTextareaFieldChange = (
    index: number,
    value: any,
    limit: number
  ) => {
    // const formName = name as keyof typeof formValue;
    // let inValid = formValue[formName].isRequired
    //   ? value === "" || !value
    //   : false;

    setWordCountError(value.length > limit);

    setFormValue((prev) =>
      formValue.length === 1
        ? [value]
        : [...prev.slice(0, index), value, ...prev.slice(index + 1)]
    );
  };

  function checkMCIsOtherOption() {
    let isValid = true;
    const currentQuestionIndex = activeQuestion - 1;
    const currentFormValue = formValue[currentQuestionIndex];
    // console.log(":213~", currentFormValue);
    if (
      typeof currentFormValue === 'object' &&
      !Array.isArray(currentFormValue) &&
      currentFormValue !== null
    ) {
      if ('openTextAns' in currentFormValue) {
        if (
          currentFormValue['openTextAns'] === '' ||
          !currentFormValue['openTextAns']
        )
          isValid = false;
      }
    }
    const questionDetail = data?.questions[activeQuestion - 1];
    if (questionDetail?.isRequired && questionDetail?.isMultipleSelection) {
      if (
        currentFormValue?.answerChoicesId?.length !==
        questionDetail.answerNumbers
      ) {
        return false;
      }
      if (questionDetail?.otherOption) {
        const isOtherOptionId = questionDetail?.answerChoices.find(
          (item: any) => item.isOthers === true
        )?._id;
        if (currentFormValue?.answerChoicesId.includes(isOtherOptionId)) {
          if (
            currentFormValue?.openTextAns === '' ||
            !currentFormValue?.openTextAns
          )
            return false;
        }
      }
    }
    // console.log("isValid", isValid);
    return isValid;
  }
  useEffect(() => {
    const questionDetail = data?.questions[activeQuestion - 1];
    if (questionDetail)
      if (
        questionDetail.questionType.name === 'multipleChoice' &&
        questionDetail.isRequired
      )
        setIsOtherError(!checkMCIsOtherOption());
  }, [formValue]);

  const handleRankOrderChange = (index: number, value: any) => {
    // const formName = name as keyof typeof formValue;
    // let inValid = formValue[formName].isRequired
    //   ? value === "" || !value
    //   : false;

    // if (!inValid && limit) {
    //   inValid = value.length > limit;
    // }
    setFormValue((prev) =>
      formValue.length === 1
        ? [value]
        : [...prev.slice(0, index), value, ...prev.slice(index + 1)]
    );
  };
  const handleChangeOptionScale = (index: number, rating: number) => {
    setFormValue((prev) =>
      formValue.length === 1
        ? [rating + 1]
        : [...prev.slice(0, index), rating + 1, ...prev.slice(index + 1)]
    );
  };
  const handleChangeMC = (
    index: number,
    value: string,
    isOther?: boolean,
    otherValue?: string
  ) => {
    const updatedFormValue = [...formValue];
    if (isOther) {
      updatedFormValue[index] = {
        openTextAns: otherValue,
        answerChoicesId: [value],
      };
    } else {
      updatedFormValue[index] = { answerChoicesId: [value] };
    }
    setFormValue(updatedFormValue);
    // if(isOther) {
    //   setFormValue({

    //   })
    // }
    // setFormValue((prev) =>
    //   formValue.length === 1
    //     ? [value]
    //     : [...prev.slice(0, index), value, ...prev.slice(index + 1)]
    // );
  };
  const handleChangeMultipleMC = (
    index: number,
    value: string,
    limit: number,
    otherValue?: string,
    typing?: boolean,
    isOther?: boolean
  ) => {
    const updatedCloneFormValue = [...formValue];
    const isOtherHasClicked =
      typeof formValue[index] === 'object' &&
      !Array.isArray(formValue[index]) &&
      'openTextAns' in formValue[index];
    let tempData = {
      answerChoicesId: Array.isArray(formValue[index].answerChoicesId)
        ? formValue[index].answerChoicesId
        : [],
      ...((isOther || isOtherHasClicked) && {
        openTextAns: otherValue ?? formValue[index]?.openTextAns ?? '',
      }),
    };
    let otherOptionRemoved = false;
    let updateFormValue = tempData.answerChoicesId;
    if (!typing) {
      if (updateFormValue.includes(value)) {
        updateFormValue = updateFormValue.filter(
          (item: string) => item !== value
        );
        if (isOther) {
          // console.log("OtherOption is removed");
          otherOptionRemoved = true;
        }
      } else {
        if (updateFormValue.length < limit) {
          if (updateFormValue.length) {
            updateFormValue = [...updateFormValue, value];
          } else {
            updateFormValue = [value];
          }
        }
      }
    }

    tempData = { ...tempData, answerChoicesId: updateFormValue };
    const currentQuestionIndex = activeQuestion - 1;
    const questionDetail = data?.questions[activeQuestion - 1];
    let otherHasSelected = false;
    if (questionDetail?.otherOption) {
      const isOtherOptionId = questionDetail?.answerChoices.find(
        (item: any) => item.isOthers === true
      )?._id;
      if (updateFormValue.includes(isOtherOptionId)) {
        otherHasSelected = true;
      }
    }
    const { openTextAns, ...restData } = tempData;
    if (otherOptionRemoved || !otherHasSelected) {
      updatedCloneFormValue[index] = restData;
    } else {
      updatedCloneFormValue[index] = tempData;
    }
    setFormValue(updatedCloneFormValue);

    // updatedFormValue[index] = { answerChoicesId: updateFormValue };
    // setFormValue(updatedFormValue);

    // setFormValue((prev) =>
    //   formValue.length === 1
    //     ? [updateFormValue]
    //     : [...prev.slice(0, index), updateFormValue, ...prev.slice(index + 1)]
    // );
  };
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      navigate('/home');
    }
  };
  const handleEndSurvey = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      navigate('/home');
    }
  };
  const handleQuestionChecking = () => {
    const questionDetail = data.questions[activeQuestion - 1];

    if (
      questionDetail.questionType === '6448afac1dde4cea6034cda0' ||
      questionDetail.questionType.name === 'multipleChoice'
    ) {
      if (
        questionDetail.multipleSelection &&
        questionDetail.multipleSelection?.toLowerCase() === 'exact number' &&
        Number(questionDetail.answerNumbers) !==
          formValue[activeQuestion - 1]?.answerChoicesId?.length
      ) {
        if (questionDetail.isRequired) {
          setExactNumberError(true);
          return false;
        } else if (
          !questionDetail.isRequired &&
          formValue[activeQuestion - 1]?.answerChoicesId?.length !== 0
        ) {
          setExactNumberError(true);
          return false;
        }
      }
    }
    // (data?.questions[activeQuestion - 1]. === false ||
    // (data?.questions[activeQuestion - 1].isRequired === true &&
    // formValue[activeQuestion - 1] !== "")
    setExactNumberError(false);
    if (wordCountError) {
      return false;
    }
    return true;
  };

  const submitForm = () => {
    if (props.onClose) {
      setSubmitted(true);
    } else {
      const answers = data.questions.map((question: any, index: number) => {
        const commonField = {
          questionId: question._id,
          questionNo: question.questionNo,
          questionTypeId: question.questionType._id,
        };
        if (question.questionType._id === '6448afac1dde4cea6034cda0') {
          return {
            ...commonField,
            answerChoicesId:
              typeof formValue[index] === 'string'
                ? []
                : formValue[index].answerChoicesId,
            ...(formValue[index].openTextAns && {
              openTextAns: formValue[index].openTextAns,
            }),
          };
        } else if (question.questionType._id === '6448afbb939f86fd85f4a02d') {
          return {
            ...commonField,
            starRating: formValue[index],
          };
        } else if (question.questionType._id === '6448af76a5f840a74e23814f') {
          return {
            ...commonField,
            opinionScale: formValue[index],
          };
        } else if (question.questionType._id === '6448afcf45a431c8981ff6d5') {
          const orderAnswers = formValue[index].map(
            (orderAnswer: any, index: number) => {
              return {
                orderAnsId: orderAnswer.id,
                rank: index + 1,
              };
            }
          );
          return {
            ...commonField,
            orderAnswers: orderAnswers,
          };
        } else if (question.questionType._id === '6448c5c9062b1934c6e2abe7') {
          return {
            ...commonField,
            openTextAns: formValue[index],
          };
        }
      });
      const request = {
        surveyId: data._id,
        answers: answers,
      };
      // console.log("onSubmit: ", request);
      postCreateSurveyAnswer(request)
        .then((res) => {
          setSubmitted(true);
        })
        .catch((err) => {
          console.error('err', err);
        });
    }
  };
  return (
    <>
      {data && !data.notInScope && (
        <>
          {!isLoading && activeQuestion === 0 && (
            <>
              <Grid
                item
                xs="auto"
                className={styles.pageContainer}
                id="coverPage"
              >
                <Box className={styles.headerContainer}>
                  <img
                    src="/assets/images/surveyForm_backButton.svg"
                    alt="Back button"
                    className={styles.backBtn}
                    onClick={handleClose}
                  />{' '}
                  <img
                    src={data.imageUrl ? data.imageUrl : data.coverImageUrl}
                    alt="survey cover photo"
                  />
                </Box>

                <Box className={styles.surveyDetailContainer}>
                  <Typography variant="subtitle1" className={styles.title}>
                    {!id && data.surveyTitle.length === 1
                      ? data.surveyDescription[0].value
                      : getApiDataByLang(data.surveyTitle)}
                  </Typography>
                  <Typography variant="body2" className={styles.date}>
                    {convertPeriod(data.surveyStartDate, data.surveyEndDate)}
                  </Typography>
                  <Typography variant="body1" className={styles.content}>
                    {!id && data.surveyDescription.length === 1
                      ? data.surveyDescription[0].value
                      : getApiDataByLang(data.surveyDescription)}
                  </Typography>
                  {data.anonymousResponses && (
                    <Typography variant="body2" className={styles.remarks}>
                      {t('survey.survey.info')}
                    </Typography>
                  )}
                </Box>
                <Box className={styles.buttonContainer}>
                  <RoundButton
                    id="startButton"
                    // fullWidth={!multiple}
                    disabled={data.isSubmitted || checkExpiration(data.endDate)}
                    onClick={() => setActiveQuestion(1)}
                  >
                    {checkExpiration(data.endDate)
                      ? t('survey.survey.answer.expired')
                      : data.isSubmitted === true
                        ? t('survey.survey.answer.surveySubmitted')
                        : t('survey.survey.date.start')}
                  </RoundButton>
                </Box>
              </Grid>
            </>
          )}
          {data && formValue && !submitted && activeQuestion > 0 && (
            <Grid
              item
              xs="auto"
              className={styles.pageContainer}
              id="contentPage"
            >
              <Header
                title={t('survey.title')}
                enableCloseButton
                closeButtonFunction={handleClose}
              ></Header>
              {data.questions.map((question: any, index: number) => {
                return (
                  activeQuestion === index + 1 && (
                    <div className={styles.questionWrapper} key={index}>
                      {question.questionImageName && (
                        <img
                          src={question.questionImageName}
                          alt={`question-image-${index}`}
                          className={styles.questionImage}
                        />
                      )}
                      <Box className={styles.contentContainer}>
                        <Typography
                          variant="body1"
                          className={styles.numOfQuestion}
                        >
                          {index + 1}/{data.questions.length}{' '}
                          {t('survey.create.question')}
                        </Typography>
                        <Typography>
                          {question.isRequired === true && (
                            <span className={styles.required}>*</span>
                          )}
                          {question.question}
                        </Typography>
                        <>
                          {(question.questionType.name === 'multipleChoice' ||
                            question.questionType ===
                              '6448afac1dde4cea6034cda0') && (
                            <MultipleChoiceSection
                              question={question}
                              index={index}
                              pictureChoice={question.pictureChoice}
                              formValue={formValue}
                              exactNumberError={exactNumberError}
                              handleChangeMC={handleChangeMC}
                              handleChangeMultipleMC={handleChangeMultipleMC}
                              id={id}
                            />
                          )}
                          {(question.questionType.name === 'opinionScale' ||
                            question.questionType ===
                              '6448af76a5f840a74e23814f') && (
                            <>
                              <Typography variant="body2">
                                (1: {question.lowScoreLabel};{' '}
                                {question.opinionScale}:{' '}
                                {question.highScoreLabel})
                              </Typography>
                              <Box className={styles.scaleItemContainer}>
                                {Array(Number(question.opinionScale))
                                  .fill(null)
                                  .map((value, opinionScaleIndex) => (
                                    <Box
                                      key={opinionScaleIndex}
                                      className={`${styles.scaleItem} ${
                                        formValue[index] ===
                                        opinionScaleIndex + 1
                                          ? 'active'
                                          : ''
                                      }`}
                                      onClick={() =>
                                        handleChangeOptionScale(
                                          index,
                                          opinionScaleIndex
                                        )
                                      }
                                    >
                                      {opinionScaleIndex + 1}
                                    </Box>
                                  ))}
                              </Box>
                            </>
                          )}
                          {(question.questionType.name === 'starRating' ||
                            question.questionType ===
                              '6448afbb939f86fd85f4a02d') && (
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              className={styles.ratingStarContainer}
                            >
                              {Array(5)
                                .fill(null)
                                .map((value, ratingIndex) => (
                                  <img
                                    src={
                                      formValue[index] > ratingIndex
                                        ? '/assets/images/surveyForm_ratingStar_active.svg'
                                        : '/assets/images/surveyForm_ratingStar.svg'
                                    }
                                    onClick={() =>
                                      handleChangeOptionScale(
                                        index,
                                        ratingIndex
                                      )
                                    }
                                  ></img>
                                ))}
                            </Stack>
                          )}
                          {(question.questionType.name === 'openText' ||
                            question.questionType ===
                              '6448c5c9062b1934c6e2abe7') && (
                            <Box className={styles.formField}>
                              <InputField
                                id={`openTextQuestion${index}`}
                                name="openTextQuestion"
                                placeholder={t(
                                  'survey.survey.inputFieldPlaceholder'
                                )}
                                value={formValue[index]}
                                error={wordCountError}
                                required={question.isRequired}
                                // disabled={editMode}
                                multiline
                                helperText={
                                  <>
                                    {wordCountError && (
                                      <Typography
                                        variant="body2"
                                        className={styles.wordCountError}
                                      >
                                        {t(
                                          'event.descriptionWordCountOverHelperText'
                                        )}
                                      </Typography>
                                    )}
                                    <Typography variant="body2" align="right">
                                      {`${formValue[index].length}/${inputFieldLimit}`}
                                    </Typography>
                                  </>
                                }
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  handleTextareaFieldChange(index, value, 200);
                                }}
                              />
                            </Box>
                          )}
                          {(question.questionType.name === 'rankOrder' ||
                            question.questionType ===
                              '6448afcf45a431c8981ff6d5') && (
                            <RankOrderSection
                              index={index}
                              pictureChoice={question.pictureChoice}
                              formValue={formValue}
                              handleRankOrderChange={handleRankOrderChange}
                            />
                          )}
                        </>
                      </Box>
                    </div>
                  )
                );
              })}{' '}
              <Stack
                direction="row"
                gap="10px"
                className={styles.buttonContainer}
              >
                {activeQuestion > 1 && (
                  <RoundButton
                    id="startButton"
                    className="reversetype"
                    // fullWidth={false}
                    onClick={() => {
                      // if (handleQuestionChecking()) {
                      setActiveQuestion((prev) => prev - 1);
                      // }
                    }}
                  >
                    {t('general.previous')}
                  </RoundButton>
                )}
                {activeQuestion < data.questions.length && (
                  <RoundButton
                    id="startButton"
                    // fullWidth={activeQuestion > 1 ? false : true}
                    disabled={disableNext || isOtherError === true}
                    onClick={() => {
                      if (handleQuestionChecking()) {
                        setActiveQuestion((prev) => prev + 1);
                      }
                    }}
                  >
                    {t('general.next')}
                  </RoundButton>
                )}
                {activeQuestion === data.questions.length && (
                  <RoundButton
                    id="startButton"
                    // fullWidth={activeQuestion > 1 ? false : true}
                    disabled={disableNext}
                    onClick={() => {
                      if (handleQuestionChecking()) {
                        submitForm();
                      }
                    }}
                  >
                    {t('general.submit')}
                  </RoundButton>
                )}
              </Stack>
            </Grid>
          )}
          {submitted && (
            <Grid
              item
              xs="auto"
              className={styles.pageContainer}
              id="thankyouPage"
            >
              <img
                src="/assets/images/surveyForm_finishImage.svg"
                alt="Survey Submitted"
              />
              <Typography variant="h3">
                {t('survey.survey.finish.title')}
              </Typography>
              <Typography variant="body1">
                {t('survey.survey.finish.description')}
              </Typography>
              <Box className={styles.buttonContainer}>
                <RoundButton
                  // fullWidth={activeQuestion > 1 ? false : true}
                  onClick={handleEndSurvey}
                >
                  {t('general.done')}
                </RoundButton>
              </Box>
            </Grid>
          )}
        </>
      )}
      {((data && data.notInScope) || surveyErrorCode !== null) && (
        <>
          <Box className={styles.headerContainer} sx={{ position: 'absolute' }}>
            <Header
              title=""
              enableCloseButton
              closeButtonNavigation="/home"
            ></Header>
          </Box>
          <Grid item xs="auto" className={styles.pageContainer} id="errorPage">
            <img
              src="/assets/images/surveyForm_notEligibleImage.svg"
              alt="Survey Submitted"
            />
            <Typography variant="h3">
              {t('survey.survey.notInScope.title')}
            </Typography>
            <Typography variant="body1">
              {surveyErrorCode === '4014'
                ? t('survey.survey.notExist')
                : surveyErrorCode === '4086'
                  ? t('survey.survey.expired')
                  : t('survey.survey.notInScope.description')}
            </Typography>
          </Grid>
        </>
      )}
    </>
  );
}
const initMaxLength = 300;
const LengthCounter = ({
  children,
  maxLength = initMaxLength,
}: {
  children: React.ReactNode;
  maxLength?: number;
}) => (
  <span className={styles.lengthCounter}>
    {children}
    {`/${maxLength}`}
  </span>
);

type OtherInputComponentProps = (
  | { withCounter: true; counterChildren: React.ReactNode }
  | {
      withCounter?: false;
    }
) &
  React.InputHTMLAttributes<HTMLInputElement>;
const OtherInputComponent = React.forwardRef<
  HTMLInputElement,
  OtherInputComponentProps
>((props, ref) => {
  const {
    type = 'text',
    className,
    placeholder = 'Type your answer',
    maxLength = initMaxLength,
    withCounter,
    ...rest
  } = props;
  return (
    <div className={styles.inputWrapper}>
      <input
        ref={ref}
        type={type}
        className={`${styles.inputField} ${className ? className : ''}`}
        placeholder={placeholder}
        maxLength={maxLength}
        {...rest}
      />
      {withCounter && <LengthCounter>{props.counterChildren}</LengthCounter>}
    </div>
  );
});

function MultipleChoiceSection({
  question,
  index,
  pictureChoice,
  formValue,
  exactNumberError,
  handleChangeMC,
  handleChangeMultipleMC,
  id,
}: {
  question: any;
  index: number;
  pictureChoice?: boolean;
  formValue: any;
  exactNumberError: boolean;
  handleChangeMC: (
    index: number,
    value: string,
    isOther?: boolean,
    otherValue?: string
  ) => void;
  handleChangeMultipleMC: (
    index: number,
    value: string,
    limit: number,
    otherValue?: string,
    typing?: boolean,
    isOther?: boolean
  ) => void;
  id?: string;
}) {
  const [inputValue, setInputValue] = React.useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  if (pictureChoice) {
    return (
      <>
        {question.isMultipleSelection === true &&
          question.multipleSelection?.toLowerCase() === 'exact number' && (
            <Typography
              variant="body2"
              className={`${styles.remarks} ${
                exactNumberError ? styles.error : ''
              }`}
            >
              {t('survey.survey.selectOptions', {
                options: question.answerNumbers,
              })}
            </Typography>
          )}
        <Box className={styles.optionContainerWithImage}>
          {question.answerChoices.map((item: any, questionIndex: number) => {
            // console.log("🚀 ~ {question.answerChoices.map ~ item:", item);
            const itemChecked = checkIdInArray(
              item._id,
              formValue[index]?.answerChoicesId
            );
            if (question.isMultipleSelection === false) {
              return (
                <div
                  key={questionIndex}
                  className={`${styles.multipleChoiceWithImageWrapper} ${
                    itemChecked ? styles.check : ''
                  }`}
                  onClick={() => {
                    if (item.isOthers && itemChecked) return;
                    handleChangeMC(
                      index,
                      item._id ? item._id : item.value,
                      item.isOthers,
                      inputValue
                    );
                  }}
                >
                  <img src={item.imageName} alt="" />
                  {item.isOthers && itemChecked ? (
                    <OtherInputComponent
                      defaultValue={formValue[index].openTextAns ?? ''}
                      onFocus={(e) =>
                        handleChangeMC(
                          index,
                          item._id ? item._id : item.value,
                          item.isOthers,
                          e.target.value
                        )
                      }
                      onChange={(e) => {
                        handleChangeMC(
                          index,
                          item._id ? item._id : item.value,
                          item.isOthers,
                          e.target.value
                        );
                        setInputValue(e.target.value);
                      }}
                    />
                  ) : (
                    <div>{item.value}</div>
                  )}
                </div>
              );
            } else {
              return (
                <div
                  key={item._id}
                  className={`${styles.multipleChoiceWithImageWrapper} ${
                    itemChecked ? styles.check : ''
                  }`}
                >
                  <img
                    src={item.imageName}
                    alt=""
                    onClick={() => {
                      handleChangeMultipleMC(
                        index,
                        item._id ? item._id : index.toString(),
                        question.answerNumbers,
                        inputValue === '' ? undefined : inputValue,
                        undefined,
                        item.isOthers ? true : false
                      );
                    }}
                  />
                  {item.isOthers && itemChecked ? (
                    <OtherInputComponent
                      defaultValue={formValue[index].openTextAns ?? ''}
                      onFocus={(e) => {
                        handleChangeMultipleMC(
                          index,
                          item._id ? item._id : index.toString(),
                          question.answerNumbers,
                          e.target.value,
                          true,
                          item.isOthers
                        );
                      }}
                      onChange={(e) => {
                        handleChangeMultipleMC(
                          index,
                          item._id ? item._id : index.toString(),
                          question.answerNumbers,
                          e.target.value,
                          true,
                          item.isOthers
                        );
                        setInputValue(e.target.value);
                      }}
                    />
                  ) : (
                    <div>{item.value}</div>
                  )}
                </div>
              );
            }
          })}
        </Box>
      </>
    );
  }

  return (
    <>
      {question.isMultipleSelection === true &&
        question.multipleSelection?.toLowerCase() === 'exact number' && (
          <Typography
            variant="body2"
            className={`${styles.remarks} ${
              exactNumberError ? styles.error : ''
            }`}
          >
            {t('survey.survey.selectOptions', {
              options: question.answerNumbers,
            })}
          </Typography>
        )}
      <Box className={styles.optionContainer}>
        {question.answerChoices.map((item: any, questionIndex: number) => {
          const itemChecked = checkIdInArray(
            item._id,
            formValue[index]?.answerChoicesId
          );
          if (question.isMultipleSelection === false) {
            return (
              <ListItem key={questionIndex} disablePadding>
                <ListItemButton
                  className={styles.languageSelectOption}
                  onClick={() => {
                    handleChangeMC(
                      index,
                      item._id ? item._id : item.value,
                      item.isOthers
                    );
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={itemChecked}
                      setChecked={(checked) => false}
                      type="radioBtn"
                    />
                  </ListItemIcon>
                  {item.isOthers && itemChecked ? (
                    <OtherInputComponent
                      ref={inputRef}
                      defaultValue={formValue[index].openTextAns ?? ''}
                      onFocus={(e) => {
                        handleChangeMultipleMC(
                          index,
                          item._id ? item._id : index.toString(),
                          question.answerNumbers,
                          e.target.value,
                          true,
                          item.isOthers
                        );
                      }}
                      onChange={(e) => {
                        handleChangeMultipleMC(
                          index,
                          item._id ? item._id : index.toString(),
                          question.answerNumbers,
                          e.target.value,
                          true,
                          item.isOthers
                        );
                        setInputValue(e.target.value);
                      }}
                      withCounter={true}
                      counterChildren={`${
                        inputRef.current?.value.length ??
                        formValue[index]?.openTextAns?.length ??
                        0
                      }`}
                    />
                  ) : (
                    <ListItemText primary={item.value} />
                  )}
                </ListItemButton>
              </ListItem>
            );
          } else {
            return (
              <ListItem disablePadding key={questionIndex}>
                <ListItemButton
                  className={styles.languageSelectOption}
                  onClick={() => {
                    if (item.isOthers && itemChecked) return;
                    handleChangeMultipleMC(
                      index,
                      item._id ? item._id : index.toString(),
                      question.answerNumbers,
                      undefined,
                      false,
                      item.isOthers
                    );
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      onChange={() => {
                        if (item.isOthers)
                          handleChangeMultipleMC(
                            index,
                            item._id ? item._id : index.toString(),
                            question.answerNumbers,
                            undefined,
                            false,
                            item.isOthers
                          );
                      }}
                      checked={itemChecked}
                      setChecked={(checked) => false}
                    />
                  </ListItemIcon>
                  {item.isOthers && itemChecked ? (
                    <OtherInputComponent
                      ref={inputRef}
                      defaultValue={formValue[index].openTextAns ?? ''}
                      onFocus={(e) =>
                        handleChangeMultipleMC(
                          index,
                          item._id ? item._id : index.toString(),
                          question.answerNumbers,
                          e.target.value,
                          true,
                          item.isOthers
                        )
                      }
                      onChange={(e) => {
                        handleChangeMultipleMC(
                          index,
                          item._id ? item._id : index.toString(),
                          question.answerNumbers,
                          e.target.value,
                          true,
                          item.isOthers
                        );
                        setInputValue(e.target.value);
                      }}
                      withCounter={true}
                      counterChildren={`${
                        inputRef.current?.value.length ??
                        formValue[index]?.openTextAns?.length ??
                        0
                      }`}
                    />
                  ) : (
                    <ListItemText primary={item.value} />
                  )}
                </ListItemButton>
              </ListItem>
            );
          }
        })}
      </Box>
    </>
  );
}

function RankOrderSection({
  index,
  pictureChoice,
  formValue,
  handleRankOrderChange,
}: {
  index: number;
  pictureChoice?: boolean;
  formValue: any;
  handleRankOrderChange: (index: number, value: any) => void;
}) {
  return (
    <Stack className={styles.dragAndDropWrapper} gap="12px">
      <Typography>{t('survey.create.answerChoices')}</Typography>
      <DragAndDrop
        options={formValue[index]}
        isPictureChoice={pictureChoice}
        disableInput
        onChange={(value) => {
          handleRankOrderChange(index, value.options);
        }}
      ></DragAndDrop>
    </Stack>
  );
}

function checkIdInArray(id: string, array?: Array<any>, idKey?: string) {
  if (!array || !Array.isArray(array)) return false;
  if (idKey) return array.some((item) => item[idKey] === id);
  return array.some((item) => item === id);
}
export default SurveyForm;
