import React, { useEffect, useState } from 'react';

import { decode, encode } from 'html-entities';
import { useSearchParams } from 'react-router-dom';

import { BottomNavMenu } from '../../components';
import ContentHubList from '../../components/content-hub/content-hub';

import {
  getContentHubDetail,
  getContentHubList,
  Module,
} from '../../services/homepage';
import { getApiDataByLang } from '../../utility';

type Props = {
  pageTitle: string;
  type: Module;
};

const ContentHub = (props: Props) => {
  const { pageTitle, type } = props;
  // console.log("type", type);
  const [data, setData] = useState<any>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get('search')
      ? decodeURI(searchParams.get('search') as string)
      : ''
  );
  const [activeItem, setActiveItem] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // get content hub list
    setLoading(true);
    setData([]);
    const getContentHubData = async () => {
      try {
        const response: any = await getContentHubList({
          module: type,
          search: searchValue,
        });

        if (response && response.data) {
          response.data.contentHubModuleList.length > 0 &&
            response.data.contentHubModuleList.forEach((item: any) => {
              const temp =
                type === 'FAQS'
                  ? {
                      id: item.id,
                      title: getApiDataByLang(item.question),
                      tab: item.contentCategory.categoryName
                        ? getApiDataByLang(item.contentCategory.categoryName)
                        : '',
                      color: item.contentCategory.color,
                      content: decode(getApiDataByLang(item.answer)).replace(
                        /\[img /g,
                        '<img src="'
                      ),
                    }
                  : {
                      id: item.id,
                      title: getApiDataByLang(item.title),
                      image: item.coverImage,
                      tab: item.contentCategory.categoryName
                        ? getApiDataByLang(item.contentCategory.categoryName)
                        : '',
                      color: item.contentCategory.color,
                      date: item.startDate,
                    };
              // console.log("getContentHubList", temp);
              setData((prev: any) => [...prev, temp]);
            });
        }
        setLoading(false);
      } catch (error) {
        // console.log(error);
        setLoading(false);
        throw error;
      }
    };
    getContentHubData();
  }, [searchValue]);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setIsLoading(true);
    const { value } = e.target;
    // console.log("handleValueChange", value);
    setSearchValue(value);
  };
  const handleResetField = () => {
    // setIsLoading(true);
    setSearchValue('');
  };
  const handleCloseChange = () => {
    // setIsLoading(true);
    setSearchValue('');
    let params = new URLSearchParams(searchParams);
    params.delete('search');
    setSearchParams(params);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  // console.log("data", data);

  const handleGetDetail = (id: string) => {
    setLoading(true);
    const getDetail = async () => {
      try {
        const response: any = await getContentHubDetail(id, {
          module: type,
        });
        // console.log("getDetail", response);
        const detail = response.data;
        if (response && detail) {
          const temp = {
            id: detail.id,
            title: getApiDataByLang(detail.title),
            image: detail.coverImage,
            tab: getApiDataByLang(detail.contentCategory.categoryName),
            color: detail.contentCategory.color,
            date: detail.startDate,
            content: decode(getApiDataByLang(detail.content)).replace(
              /\[img /g,
              '<img src="'
            ),
          };
          setActiveItem(temp);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        // console.log(error);
        throw error;
      }
    };
    getDetail();
  };
  return (
    <>
      <ContentHubList
        pageTitle={pageTitle}
        listData={data}
        searchValue={searchValue}
        onChange={handleValueChange}
        onReset={handleResetField}
        onClose={handleCloseChange}
        onDetailClick={(id: string) => handleGetDetail(id)}
        activeItem={activeItem}
        isLoading={loading}
        {...(type === 'FAQS' && { isFaq: true })}
      />
      <BottomNavMenu />
    </>
  );
};

export default ContentHub;
