import { Configuration, LogLevel } from '@azure/msal-browser';

const getParameterValue = (key: string) => {
  var urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

export const msalConfig: Configuration = {
  auth: {
    clientId:
      getParameterValue('loginType') === 'AD' ||
      localStorage.getItem('AAD_LOGIN') === 'true'
        ? (process.env.REACT_APP_MTEL_AZURE_CLIENT_ID as string)
        : (process.env.REACT_APP_AZURE_CLIENT_ID as string),
    authority: `https://login.microsoftonline.com/${getParameterValue('loginType') === 'AD' || localStorage.getItem('AAD_LOGIN') === 'true' ? process.env.REACT_APP_MTEL_AZURE_APP_ID : process.env.REACT_APP_AZURE_APP_ID}`,
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT,
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes:
    process.env.REACT_APP_LOCATION === 'TWN'
      ? []
      : [
          'Calendars.Read',
          'Channel.ReadBasic.All',
          'Contacts.Read',
          'email',
          'Files.Read',
          'Files.Read.All',
          'offline_access',
          'openid',
          'profile',
          'Sites.Read.All',
          'Team.ReadBasic.All',
          'User.Read',
        ],
  nonce: '2a4792aa6807d257f4f5',
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
