import React, { FC, useCallback, useEffect, useState } from 'react';

import { Alert, Box, Grid, IconButton, Link, Snackbar } from '@mui/material';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { BottomNavMenu, EventCard } from '../../components';
import Calendar from '../../components/calendar/calendar';
import EventCardSkeleton from '../../components/event-card/skeleton';
import Header from '../../components/header/header';
import NoResults from '../../components/no-results';
import QrCodeScanner from '../../components/qr-code-scanner';

import { ObjectKey } from '../../interfaces/common-interface';
import { EventCardBaseType } from '../../interfaces/event-interface';
import { UserState } from '../../reducers/user-slice';
import { EventListRequestType, getEventList } from '../../services/event';
import { RootState } from '../../store/store';
import { getApiDataByLang } from '../../utility';
import styles from './event.module.scss';
import EventTabsList from './eventTabsSection';

type Props = {
  isGroupEvent?: boolean;
};

const Event: FC<Props> = (props) => {
  const { isGroupEvent = false } = props;
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string>('UPCOMING');
  const [eventList, setEventList] = useState<Array<any>>([]);
  const [eventDayList, setEventDayList] = useState<Array<number>>([]);
  const [activeDate, setActiveDate] = useState<ObjectKey>({
    year: parseInt(moment().format('YYYY')),
    month: parseInt(moment().format('M')),
  });
  const [isLoading, setIsLoading] = useState(true);
  const listRef = React.useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [createSuccessBar, setCreateSuccessBar] = useState(false);
  const [permissionErrorBar, setPermissionErrorBar] = useState(false);
  const [openScanner, setOpenScanner] = useState(false);
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const { groupId } = useParams();
  // console.log("userState.permission", userState.permission);

  const isAdmin = groupId
    ? Boolean(userState.permission?.SocialWallAdmin)
    : Boolean(userState.permission?.EventAdmin);

  function handleChangeTab(active: string) {
    if (active === activeTab) return false;
    setEventList([]);
    setActiveTab(active);
  }

  function handleSelectedDateChange(date: Moment) {
    listRef.current
      ?.querySelector(`[data-event-day="${moment(date).format('YYYY-MM-DD')}"]`)
      ?.scrollIntoView({ behavior: 'smooth' });
  }

  function handleActiveDateChange(date: Moment) {
    setEventDayList([0]);
    setEventList([]);
    setActiveDate({
      month: parseInt(moment(date).format('M')),
      year: parseInt(moment(date).format('YYYY')),
    });
  }

  function handleCloseMsgBar() {
    setCreateSuccessBar(false);
  }

  const getList = useCallback(async () => {
    let active = true;
    setIsLoading(true);

    try {
      let listRequest: EventListRequestType = {
        month: activeDate.month,
        year: activeDate.year,
        eventType: 'Open,Upcoming,Full',
        registerType: '', //Open, Upcoming, Registered, Ended, Fulled
      };
      if (groupId) {
        listRequest = {
          ...listRequest,
          partnerNetworkGroupId: groupId,
        };
      }
      switch (activeTab) {
        case 'UPCOMING':
          listRequest = {
            ...listRequest,
            registerType: 'Open,Upcoming,Full',
          };
          break;
        case 'MY_EVENT':
          listRequest = {
            ...listRequest,
            registerType: 'Registered,Checked-In',
          };
          break;
        case 'MANAGING':
          listRequest = {
            ...listRequest,
            // registerType: "Open,Upcoming,Registered,Checked-In,Fulled",
            isAdmin: true,
          };
          break;
      }

      const response = await getEventList(listRequest);
      if (response.status === 200 && response.data) {
        const { events } = response.data;

        let haveEventDayList: Array<number> = [0];
        events.forEach((event: ObjectKey) => {
          const eventStartTimes = moment
            .unix(event.eventStartTimestamp)
            .format();
          const eventEndTimes = moment.unix(event.eventEndTimestamp).format();
          const isSameMonth = moment(eventStartTimes).isSame(
            eventEndTimes,
            'month'
          );

          if (isSameMonth) {
            if (moment(eventStartTimes).isSame(eventEndTimes, 'day')) {
              // case: 7 / 15 - 7 / 15;
              const newDay = parseInt(moment(eventStartTimes).format('D'));
              if (!haveEventDayList.includes(newDay)) {
                haveEventDayList.push(newDay);
              }
            } else {
              // case: 7 / 14 - 7 / 18;
              const startDate = parseInt(moment(eventStartTimes).format('D'));
              const endDate = parseInt(moment(eventEndTimes).format('D'));
              for (let i = startDate; i <= endDate; i++) {
                if (!haveEventDayList.includes(i)) {
                  haveEventDayList.push(i);
                }
              }
            }
          } else if (
            parseInt(moment(eventStartTimes).format('M')) === activeDate.month
          ) {
            // case: 7 / 15 - 8 / 15;
            const startDate = parseInt(moment(eventStartTimes).format('D'));
            const endDate = parseInt(
              moment(eventStartTimes).endOf('month').format('D')
            );
            for (let i = startDate; i <= endDate; i++) {
              if (!haveEventDayList.includes(i)) {
                haveEventDayList.push(i);
              }
            }
          } else if (
            parseInt(moment(eventEndTimes).format('M')) === activeDate.month
          ) {
            // case: 6 / 15 - 7 / 15;
            const startDate = parseInt(
              moment(eventEndTimes).startOf('month').format('D')
            );
            const endDate = parseInt(moment(eventEndTimes).format('D'));
            for (let i = startDate; i <= endDate; i++) {
              if (!haveEventDayList.includes(i)) {
                haveEventDayList.push(i);
              }
            }
          } else {
            // case: 6 / 15 - 8 / 15;
            const startDate = 1;
            const endDate = parseInt(moment().endOf('month').format('D'));
            for (let i = startDate; i <= endDate; i++) {
              if (!haveEventDayList.includes(i)) {
                haveEventDayList.push(i);
              }
            }
          }
        });

        const convertEvents = events.map((event: ObjectKey) => {
          // let eventStatus = "upcoming";
          // if (typeof event.status === "string") {
          //   eventStatus =
          //     event.status.charAt(0).toLowerCase() +
          //     event.status.slice(1).replace("-", "");
          // } else if (event.status.register === "Open") {
          //   eventStatus =
          //     event.status.register.charAt(0).toLowerCase() +
          //     event.status.register.slice(1).replace("-", "");
          // }
          let eventStatus =
            event.status.register.charAt(0).toLowerCase() +
            event.status.register.slice(1).replace('-', '');

          return {
            id: event._id,
            name: event.eventName,
            partnerNetworkGroupId: event.partnerNetworkGroupId ?? null,
            groupName: event?.partnerNetworkGroupName
              ? getApiDataByLang(event.partnerNetworkGroupName)
              : null,
            imageUrl: event.eventCoverImage,
            startsFrom: moment.unix(event.eventStartTimestamp).format(),
            endsAt: moment.unix(event.eventEndTimestamp).format(),
            category: event.category
              ? {
                  id: event.category._id,
                  name: getApiDataByLang(event.category.categoryName),
                  bgColor: event.category.color,
                }
              : null,
            status: eventStatus,
            location:
              event.eventLocationId &&
              event.eventLocation &&
              typeof event.eventLocation === 'object'
                ? {
                    id: event.eventLocation._id,
                    name: event.eventLocation.storeName,
                    address: event.eventLocation.address,
                  }
                : {
                    id: '',
                    name: event.eventLocation,
                    address: '',
                  },
          };
        });

        if (active) {
          setEventDayList(haveEventDayList);
          setEventList(convertEvents);
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }

    return () => {
      active = false;
    };
  }, [activeTab, activeDate]);

  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    if (
      searchParams.get('create') &&
      searchParams.get('id') &&
      searchParams.get('create') === 'success'
    ) {
      window.history.replaceState(
        null,
        '',
        groupId ? `/partner-network/event/${groupId}` : `/event`
      );
      setCreateSuccessBar(true);
    } else if (
      searchParams.get('error') &&
      searchParams.get('error') === 'permission'
    ) {
      window.history.replaceState(
        null,
        '',
        groupId ? `/partner-network/event/${groupId}` : `/event`
      );
      setPermissionErrorBar(true);
    }
  }, [searchParams]);

  return (
    <>
      <Header
        title={isGroupEvent ? t('partnerNetwork.groupEvent') : t('event.title')}
        {...(isGroupEvent && {
          enableBackButton: true,
          // closeButtonNavigation: `/partner-network/detail/${groupId}`,
        })}
      >
        <Grid container direction="row" justifyContent="flex-end">
          {isAdmin && (
            <Grid item xs="auto">
              <IconButton
                aria-label="Scan"
                onClick={() => setOpenScanner(true)}
              >
                <img src="/assets/images/toolbar_scan_black.svg" alt="" />
              </IconButton>
              <QrCodeScanner
                open={openScanner}
                onClose={() => setOpenScanner(false)}
              />
            </Grid>
          )}
          <Grid item xs="auto">
            <IconButton
              aria-label="Search"
              onClick={() => navigate('./search')}
            >
              <img src="/assets/images/toolbar_search_black.svg" alt="" />
            </IconButton>
          </Grid>
          {isAdmin && (
            <Grid item xs="auto">
              <IconButton
                aria-label="Plus"
                onClick={() => navigate('./create')}
              >
                <img src="/assets/images/toolbar_plus_black.svg" alt="" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Header>
      <Grid container item xs flexWrap="wrap" className={styles.eventContainer}>
        <EventTabsList
          activeTab={activeTab}
          activeMonth={activeDate.month}
          activeYear={activeDate.year}
          isAdmin={isAdmin}
          onTabClick={handleChangeTab}
        />
        <Box className={styles.eventCalendar}>
          <Calendar
            activeMonth={activeDate.month}
            activeYear={activeDate.year}
            highlightDayList={eventDayList}
            disableHighlightToday
            disablePast
            isLoading={isLoading}
            onSelectDate={(date: Moment) => handleSelectedDateChange(date)}
            onSelectMonth={(date: Moment) => handleActiveDateChange(date)}
          />
        </Box>
        <Grid ref={listRef} item xs className={styles.eventList}>
          {isLoading && <EventCardSkeleton size="large" />}
          {!isLoading && eventList.length === 0 && <NoResults />}
          {eventList.map((data: EventCardBaseType, index: number) => {
            // console.log("data", data);
            return (
              <>
                {data.groupName && data.partnerNetworkGroupId && (
                  <Link
                    className={styles.groupName}
                    href={`/partner-network/detail/${data.partnerNetworkGroupId}`}
                  >
                    <img src="/assets/images/group_icon.svg" alt="group" />
                    {data.groupName}
                  </Link>
                )}
                <EventCard
                  key={index}
                  {...data}
                  name={
                    typeof data.name === 'string'
                      ? data.name
                      : getApiDataByLang(data.name)
                  }
                  size="large"
                  onClick={(id: string) =>
                    navigate(
                      data?.partnerNetworkGroupId
                        ? `/partner-network/event/${data.partnerNetworkGroupId}/${id}`
                        : `/event/${id}`
                    )
                  }
                />
              </>
            );
          })}
        </Grid>
      </Grid>
      <BottomNavMenu />
      <Snackbar
        open={createSuccessBar}
        autoHideDuration={6000}
        onClose={handleCloseMsgBar}
      >
        <Alert
          severity="success"
          action={
            <Link
              component="button"
              variant="body2"
              underline="none"
              onClick={() => navigate(`./${searchParams.get('id')}`)}
            >
              {t('event.viewEvents')}
            </Link>
          }
        >
          {t('event.createdSuccessfully')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={permissionErrorBar}
        autoHideDuration={6000}
        onClose={() => setPermissionErrorBar(false)}
      >
        <Alert severity="error" onClose={() => setPermissionErrorBar(false)}>
          {t('general.withoutPermissionMsg')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Event;
