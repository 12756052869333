import React, { useEffect, useState } from 'react';

import './App.css';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BrowserRouter, Link, Navigate, Route, Routes } from 'react-router-dom';

import CheckAuthorization from './components/check-authorization/check-authorization';
import SplashScreen from './components/splash-screen';
import Chat from './pages/chat/';
import ContentHub from './pages/contentHub/contentHub';
import ContentHubDetail from './pages/contentHub/contentHubDetail';
import Event from './pages/event/event';
import EventAttachments from './pages/event/eventAttachments';
import EventAttendeesList from './pages/event/eventAttendeesList';
import EventCreate from './pages/event/eventCreate';
import EventDetail from './pages/event/eventDetail';
import EventDetailContainer from './pages/event/eventDetailContainer';
import EventSearch from './pages/event/eventSearch';
import EventTickets from './pages/event/eventTickets';
import Files from './pages/files/files';
import PHFiles from './pages/files/phFiles';
import FirstLogin from './pages/firstLogin/firstLogin';
import GreenApronCard from './pages/greenApronCard/greenApronCard';
import GreenApronCardContainer from './pages/greenApronCard/greenApronCardContainer';
import GreenApronCardDetail from './pages/greenApronCard/greenApronCardDetail';
import GreenApronCardPreview from './pages/greenApronCard/greenApronCardPreview';
import GreenApronCardReceived from './pages/greenApronCard/greenApronCardReceived';
import GreenApronCardSelection from './pages/greenApronCard/greenApronCardSelection';
import GreenApronCardSend from './pages/greenApronCard/greenApronCardSend';
import GreenApronCardSent from './pages/greenApronCard/greenApronCardSent';
import Homepage from './pages/homepage/homepage';
import Menu from './pages/homepage/menu';
import Leaderboard from './pages/leaderboard/leaderboard';
import MyRewards from './pages/leaderboard/myRewards';
import PastWinners from './pages/leaderboard/pastWinners';
import PastWinnersList from './pages/leaderboard/pastWinnersList';
import PointRecord from './pages/leaderboard/pointRecord';
import Ranking from './pages/leaderboard/ranking';
import RewardDetail from './pages/leaderboard/rewardDetail';
import Login from './pages/login/login';
import MeSection from './pages/me/me';
import MeAboutUs from './pages/me/meAboutUs';
import MeContactUs from './pages/me/meContactUs';
import MeMyProfile from './pages/me/meMyProfile';
import MeMyProfileEdit from './pages/me/meMyProfileEdit';
import MeTermsConditions from './pages/me/meTermsConditions';
import MsTeamsEventDetail from './pages/myCalendar/msTeamsEventDetail';
import MyCalendar from './pages/myCalendar/myCalendar';
import CouponDetail from './pages/myCoupon/couponDetail';
import { CouponContainer } from './pages/myCoupon/couponProvider';
import CouponHistory from './pages/myCoupon/history';
import MyCoupon from './pages/myCoupon/myCoupon';
import TransferCoupons from './pages/myCoupon/transferCoupons';
import TransferredCoupons from './pages/myCoupon/transferredCoupons';
import NewsAndSupports from './pages/newsAndSupports/newsAndSupports';
import TheGreenBox from './pages/newsAndSupports/theGreenBox/theGreenBox';
import PartnerNetwork from './pages/partnerNetwork/partnerNetwork';
import PartnerNetworkAbout from './pages/partnerNetwork/partnerNetworkAbout';
import PartnerNetworkDetail from './pages/partnerNetwork/partnerNetworkDetail';
import PartnerNetworkForm from './pages/partnerNetwork/partnerNetworkForm';
import PartnerNetworkGallery from './pages/partnerNetwork/partnerNetworkGallery';
import PartnerNetworkJoined from './pages/partnerNetwork/partnerNetworkJoined';
import PartnerNetworkManagement from './pages/partnerNetwork/partnerNetworkManagement';
import PartnerNetworkSearch from './pages/partnerNetwork/partnerNetworkSearch';
import ReceiveCoupon from './pages/receiveCoupon/receiveCoupon';
import Roster from './pages/roster/roster';
import SocialWall from './pages/socialWall/socialWall';
import SocialWallCreate from './pages/socialWall/socialWallCreate';
import SocialWallPollResult from './pages/socialWall/socialWallPollResult';
import SocialWallPostDetail from './pages/socialWall/socialWallPostDetail';
import SocialWallPostList from './pages/socialWall/socialWallPostList';
import SocialWallReportLog from './pages/socialWall/socialWallReportLog';
import SocialWallSearch from './pages/socialWall/socialWallSearch';
import SocialWallSuspendedPostDetail from './pages/socialWall/socialWallSuspendedPostDetail';
import StaffDirectory from './pages/staffDirectory/staffDirectory';
import StoreAndFinancialUpdates from './pages/storeAndFinancialUpdates/storeAndFinancialUpdates';
import StoreDetails from './pages/storeDirectory/storeDetails';
import StoreDirectory from './pages/storeDirectory/storeDirectory';
import Survey from './pages/survey/survey';
import SurveyCreate from './pages/survey/surveyCreate';
import SurveyForm from './pages/survey/surveyForm';
import SurveyTemplate from './pages/survey/surveyTemplate';
import { UserState } from './reducers/user-slice';
import { RootState } from './store/store';

function App() {
  const { t } = useTranslation();
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); // Set isLoading to false to hide the splash screen
    }, 1000); // Adjust the timeout duration as per your requirements
  }, []);

  return isLoading ? (
    <SplashScreen />
  ) : (
    <BrowserRouter>
      <CheckAuthorization />
      <Routes>
        <Route path={`/`} element={<Homepage />} />
        <Route path={`/home`} element={<Homepage />} />
        <Route path={`/menu`} element={<Menu />} />
        <Route path={`/login`} element={<Login />} />
        <Route path={`/first-login`} element={<FirstLogin />} />
        {/* For azure redirect not update */}
        <Route path={`/login/PHL`} element={<Login />} />
        <Route path={`/social-wall`}>
          <Route index element={<SocialWall />} />
          <Route path={`search`} element={<SocialWallSearch />} />
          <Route path={`search/:tag`} element={<SocialWallSearch />} />
          <Route path={`create`} element={<SocialWallCreate />} />
          <Route path={`edit/:id`} element={<SocialWallCreate />} />
          <Route path={`post-detail/:id`} element={<SocialWallPostDetail />} />
          <Route path={`view-poll/:id`} element={<SocialWallPollResult />} />
          <Route path={`reported-post`}>
            <Route index element={<SocialWallPostList type="reported" />} />{' '}
            <Route path={`log`} element={<SocialWallReportLog />} />
            <Route
              path={`post-detail/:id`}
              element={<SocialWallSuspendedPostDetail />}
            />
            <Route
              path={`log-detail/:id`}
              element={<SocialWallSuspendedPostDetail />}
            />
          </Route>
          <Route
            path={`scheduled-post`}
            element={<SocialWallPostList type="scheduled" />}
          />
        </Route>
        <Route path={`/event`}>
          <Route index element={<Event />} />
          <Route path={`search`} element={<EventSearch />} />
          <Route path={`:id`} element={<EventDetailContainer />}>
            <Route index element={<EventDetail />} />
            <Route path={`attendeesList`} element={<EventAttendeesList />} />
            <Route path={`attachments`} element={<EventAttachments />} />
            <Route path={`my-tickets`} element={<EventTickets />} />
          </Route>
          <Route path={`create`} element={<EventCreate />} />
          <Route path={`edit/:id`} element={<EventCreate />} />
        </Route>
        <Route path={`/green-apron-card`} element={<GreenApronCardContainer />}>
          <Route index element={<GreenApronCard />} />
          <Route path={`sent`} element={<GreenApronCardSent />} />
          <Route path={`received`} element={<GreenApronCardReceived />} />
          <Route path={`selection`} element={<GreenApronCardSelection />} />
          <Route path={`send`} element={<GreenApronCardSend />} />
          <Route path={`send/:id`} element={<GreenApronCardSend />} />
          <Route path={`send/preview`} element={<GreenApronCardPreview />} />
          <Route path={`:id`}>
            <Route index element={<GreenApronCardDetail />} />
            <Route path={`reply`} element={<GreenApronCardDetail />} />
          </Route>
        </Route>
        <Route path={`/leaderboard`}>
          <Route index element={<Leaderboard />} />
          <Route path={`point-record`} element={<PointRecord />} />
          <Route path={`my-rewards`}>
            <Route index element={<MyRewards />} />
            <Route
              path={`details/:id`}
              element={<RewardDetail redeemable={true} />}
            />
          </Route>
          <Route
            path={`reward-detail/:id`}
            element={<RewardDetail redeemable={false} />}
          />
          <Route path={`past-winners`}>
            <Route index element={<PastWinners />} />
            <Route path={`list/:id`} element={<PastWinnersList />} />
          </Route>
          <Route path={`rankings`} element={<Ranking />} />
        </Route>
        <Route path={`/discover-people`}>
          <Route index element={<StaffDirectory />} />
          <Route path={`profile/:id`} element={<MeMyProfile />} />
        </Route>

        {process.env.REACT_APP_LOCATION === 'PHL' && (
          <Route path={`/store-directory`}>
            <Route index element={<StoreDirectory />} />
            <Route path={`details/:id`}>
              <Route index element={<StoreDetails />} />
              <Route path={`store-partners`} element={<StaffDirectory />} />
            </Route>
          </Route>
        )}

        <Route path={`/survey`}>
          <Route index element={<Survey />} />
          <Route path={`template`} element={<SurveyTemplate />} />
          <Route path={`create`}>
            <Route index element={<SurveyCreate mode="create" />} />
            <Route path={`:id`} element={<SurveyCreate mode="create" />} />
          </Route>
          <Route path={`edit`}>
            <Route path={`:id`} element={<SurveyCreate mode="edit" />} />
          </Route>
          <Route path={`form/:id`} element={<SurveyForm />} />
        </Route>
        <Route path={`/me`}>
          <Route index element={<MeSection />} />
          <Route path={`about-us`} element={<MeAboutUs />} />
          <Route path={`terms-conditions`} element={<MeTermsConditions />} />
          <Route path={`contact-us`} element={<MeContactUs />} />

          <Route path={`my-profile`}>
            <Route index element={<MeMyProfile />} />
            <Route path={`edit`} element={<MeMyProfileEdit />} />
          </Route>
        </Route>
        <Route path={`/files`}>
          {process.env.REACT_APP_LOCATION !== 'PHL' ? (
            <>
              <Route index element={<Files />} />
              <Route path={`:id`} element={<Files />} />
            </>
          ) : (
            <>
              <Route index element={<PHFiles />} />
              <Route path={`:driveId/:id`} element={<Files />} />
            </>
          )}
        </Route>

        {process.env.REACT_APP_LOCATION === 'TWN' && (
          <Route path={`roster`} element={<Roster />} />
        )}
        <Route path={`calendar`}>
          <Route index element={<MyCalendar />} />
          <Route path={`:id`} element={<MsTeamsEventDetail />} />
        </Route>
        <Route
          path={`/art-walk`}
          element={
            <ContentHub
              pageTitle={t('homepage.contentHub.ArtWalk')}
              type="ART_WALK"
            />
          }
        />
        <Route
          path={`/brewing-instructions`}
          element={
            <ContentHub
              pageTitle={t('homepage.contentHub.BrewingInstructions')}
              type="BREWING_INSTRUCTIONS"
            />
          }
        />
        <Route
          path={`/partner-safety-and-global-guardian`}
          element={
            <ContentHub
              pageTitle={t(
                'homepage.contentHub.PartnerSafetyAndGlobalGuardian'
              )}
              type="PARTNER_SAFETY_AND_GLOBAL_GUARDIAN"
            />
          }
        />
        <Route
          path={`/announcement`}
          element={
            <ContentHub
              pageTitle={t('homepage.contentHub.Announcements')}
              type="ANNOUNCEMENTS"
            />
          }
        />
        <Route
          path={`/partner-anniversary`}
          element={
            <ContentHub
              pageTitle={t('homepage.contentHub.PartnerAnniversary')}
              type="PARTNER_ANNIVERSARY"
            />
          }
        />
        <Route
          path={`/newsletters`}
          element={
            <ContentHub
              pageTitle={t('homepage.contentHub.Newsletters')}
              type="NEWSLETTERS"
            />
          }
        />
        <Route
          path={`/faqs`}
          element={
            <ContentHub pageTitle={t('homepage.contentHub.Faq')} type="FAQS" />
          }
        />
        <Route path={`/content-hub/:type/:id`} element={<ContentHubDetail />} />
        <Route path={`/the-green-box`} element={<TheGreenBox />} />
        {process.env.REACT_APP_LOCATION === 'HK' && (
          <>
            <Route path={`/my-coupon`} element={<CouponContainer />}>
              <Route index element={<MyCoupon />} />
              <Route path={`detail/:id`} element={<CouponDetail />} />
              <Route path={`transfer-coupons`} element={<TransferCoupons />} />
              <Route path={`history`} element={<CouponHistory />} />
              <Route
                path={`transferred-coupons/:id`}
                element={<TransferredCoupons />}
              />
            </Route>
            <Route path={`/receive-coupon/`}>
              <Route index element={<ReceiveCoupon />} />
              <Route path=":hash" element={<ReceiveCoupon />} />
            </Route>
            <Route path={`/store-and-financial-updates`}>
              <Route index element={<StoreAndFinancialUpdates />} />
            </Route>
          </>
        )}

        {process.env.REACT_APP_LOCATION !== 'TWN' && (
          <>
            <Route path={`/partner-network`}>
              <Route index element={<PartnerNetwork />} />
              <Route
                path={`detail/:groupId`}
                element={<PartnerNetworkDetail />}
              />
              <Route
                path={`about/:groupId`}
                element={<PartnerNetworkAbout />}
              />
              {/* <Route path={`event/:groupId`} element={<Event isGroupEvent />} /> */}
              <Route path={`event/:groupId`}>
                <Route index element={<Event isGroupEvent />} />
                <Route path={`search`} element={<EventSearch />} />
                <Route path={`:id`} element={<EventDetailContainer />}>
                  <Route index element={<EventDetail />} />
                  <Route
                    path={`attendeesList`}
                    element={<EventAttendeesList />}
                  />
                  <Route path={`attachments`} element={<EventAttachments />} />
                  <Route path={`my-tickets`} element={<EventTickets />} />
                </Route>
                <Route path={`create`} element={<EventCreate />} />
                <Route path={`edit/:id`} element={<EventCreate />} />
              </Route>

              <Route
                path={`gallery/:groupId`}
                element={<PartnerNetworkGallery />}
              />
              <Route path={`search`} element={<PartnerNetworkSearch />} />
              <Route
                path={`management`}
                element={<PartnerNetworkManagement />}
              />
              <Route path={`joined`} element={<PartnerNetworkJoined />} />
              <Route path={`create-group`} element={<PartnerNetworkForm />} />
              <Route
                path={`create-post/:groupId`}
                element={<SocialWallCreate />}
              />
              <Route path={`edit-group/:id`} element={<PartnerNetworkForm />} />
            </Route>
            <Route path={`/pro`} element={<NewsAndSupports />} />
          </>
        )}
        {userState?.chat?.ChatFunction && (
          <Route path={`chat`}>
            <Route index element={<Chat />} />
            <Route path={`:channelUrl`} element={<Chat />} />
            <Route path={`:channelUrl/settings`} element={<Chat />} />
            <Route path={`:channelUrl/search`} element={<Chat />} />
          </Route>
        )}
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
