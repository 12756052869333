import { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import toHtml from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import Header from '../../components/header/header';

import styles from './me.module.scss';

const MeTermsConditions: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header title={t('myProfile.me.termsConditions')} enableBackButton />
      <Grid item xs className={styles.container}>
        <Box className={styles.content}>
          <Typography variant="h5" color="text.primary">
            {t('myProfile.me.termsConditions')}
          </Typography>
          <br />
          <Typography variant="body1" color="text.primary">
            {toHtml(
              t('myProfile.me.termsConditionsDetails', {
                PICS_link: process.env.REACT_APP_PICS_PDF_LINK,
                CAUSG_link: process.env.REACT_APP_CAUSG_LINK,
              })
            )}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default MeTermsConditions;
