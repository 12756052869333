import React, { FC, useCallback, useEffect, useState } from 'react';

import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ActionItem, SocialWallCard } from '../../components';
import SearchField from '../../components/search-field';

import { UserState } from '../../reducers/user-slice';
import {
  deleteRecentSearch,
  getSearchPostByHashTag,
  getSearchSocialWall,
  getSearchSuggestion,
  getSocialWallConfig,
  SocailWallListRequestType,
} from '../../services/social-wall';
import { RootState } from '../../store/store';
import theme from '../../theme';
import styles from './socialWallSearch.module.scss';

const SocialWallSearch: FC = () => {
  const { tag } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSuggestWord, setIsLoadingSuggestWord] = useState(false);
  const [beforeSearch, setBeforeSearch] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [result, setResult] = useState<Array<any>>([]);
  const [recentSearch, setRecentSearch] = useState<Array<string>>([]);
  const [suggestedWord, setSuggestWord] = useState<Array<string>>([]);
  const [delaySearchThread, setDelaySearchThread] =
    useState<NodeJS.Timeout | null>(null);

  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [socialWallPermission, setSocialWallPermission] = useState<any>();
  const [listParams, setListParams] = useState<SocailWallListRequestType>({
    page: 1,
    pageSize: 10,
    search: '',
  });
  const [hasMore, setHasMore] = useState(true);

  const handleResetField = () => {
    if (!isLoading && !isLoadingSuggestWord) {
      setResult([]);
      setSuggestWord([]);
      setSearchValue('');
      getSearchSuggestion().then((res) => {
        setRecentSearch(
          res.data.records.map((item: any) => {
            if (item.isHashTag) {
              return `#${item.value}`;
            } else {
              return item.value;
            }
          })
        );
      });
    }
  };

  const handleCloseChange = () => {
    navigate('/social-wall');
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowResult(false);
    setResult([]);
    const { value } = e.target;
    if (value) {
      if (delaySearchThread) {
        clearTimeout(delaySearchThread);
      }

      setRecentSearch([]);
      setSearchValue(value);

      const threadId = setTimeout(() => {
        setIsLoadingSuggestWord(true);
        setBeforeSearch(true);

        getSearchSuggestion(value).then((res) => {
          setSuggestWord(
            res.data.records.map((item: any) => {
              if (item.isHashTag) {
                return `#${item.value}`;
              } else {
                return item.value;
              }
            })
          );
          setIsLoadingSuggestWord(false);
        });
      }, 2000);

      setDelaySearchThread(threadId);
    } else {
      handleResetField();
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setResult([]);
    // setTimeout(() => {
    setRecentSearch([]);
    setSuggestWord([]);

    // if (value.charAt(0) === "#") {
    //   setBeforeSearch(false);
    //   getSearchPostByHashTag(value.slice(1)).then((res) => {
    //     setResult(res.data.tags.map((item: any) => item.post));
    //     setIsLoading(false);
    //   });
    // } else {
    setShowResult(true);
    setListParams((prev) => ({
      ...prev,
      page: 1,
      search: value,
    }));
    // }
    // }, 2000);
  };
  const removeRecentSearchValue = (valueToRemove: any) => {
    deleteRecentSearch(valueToRemove).then((res) => {
      if (res.status === 200) {
        const filtered = recentSearch.filter(function (value: any) {
          return value !== valueToRemove;
        });
        setRecentSearch(filtered);
      }
    });
  };
  useEffect(() => {
    const getConfig = async () => {
      const response = await getSocialWallConfig();
      setSocialWallPermission(response.data);
    };
    getConfig();

    if (!tag) {
      getSearchSuggestion().then((res) => {
        setRecentSearch(
          res.data.records.map((item: any) => {
            if (item.isHashTag) {
              return `#${item.value}`;
            } else {
              return item.value;
            }
          })
        );
      });
    }
  }, []);
  useEffect(() => {
    if (tag) {
      handleSearch(`#${tag}`);
      setResult([]);
      setRecentSearch([]);
      setSuggestWord([]);
    }
  }, [tag]);

  //for infinite scroll

  const getList = useCallback(async () => {
    let active = true;

    if (showResult) {
      setIsLoading(true);
      setBeforeSearch(false);
      try {
        var response: any;

        if (listParams.search?.charAt(0) === '#') {
          response = await getSearchPostByHashTag(listParams);
        } else {
          response = await getSearchSocialWall(listParams);
        }

        if (response.status === 200 && active) {
          if (listParams.search?.charAt(0) === '#') {
            setResult((prev) => [
              ...prev,
              ...response.data.tags.map((item: any) => item.post),
            ]);
          } else {
            setResult((prev) => [...prev, ...response.data.posts]);
          }

          setIsLoading(false);
          if (response.data.totalPage === listParams.page) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        } else {
          setIsLoading(false);
          setHasMore(false);
        }
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    }

    return () => {
      active = false;
    };
  }, [listParams]);

  useEffect(() => {
    getList();
  }, [getList]);
  // useEffect(() => {
  //   console.log("to check beforeSearch", beforeSearch);
  // }, [beforeSearch]);
  // useEffect(() => {
  //   console.log("showResult", showResult);
  // }, [showResult]);
  const nextPage = () => {
    if (!isLoading) {
      setListParams((prev) => ({
        ...prev,
        page: prev.page ? prev.page + 1 : 1,
      }));
    }
  };

  return (
    <>
      <SearchField
        id="search"
        name="search"
        value={searchValue}
        placeholder={t('general.search')}
        onChange={handleValueChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.keyCode === 13) {
            handleSearch(searchValue);
          }
        }}
        onReset={handleResetField}
        onClose={handleCloseChange}
      />
      <Grid
        item
        xs
        className={styles.searchResult}
        // {...(isEnterPressed && { style: { display: 'none' } })}
      >
        {/* {isLoading && (
          <Grid item xs className={styles.postList}>
            <SocialWallCardSkeleton />
          </Grid>
        )} */}
        {!isLoading && !isLoadingSuggestWord && (
          <>
            {recentSearch.length > 0 && (
              <Stack direction="row" className={styles.recentSearchHeader}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {t('socialWall.recent')}
                </Typography>
                <Typography
                  variant="body2"
                  className={styles.clearBtn}
                  onClick={() => {
                    deleteRecentSearch()
                      .then((result) => {
                        setRecentSearch([]);
                      })
                      .catch((err) => {});
                  }}
                >
                  {t('general.clear')}
                </Typography>
              </Stack>
            )}
            {recentSearch.map((item, index) => (
              <Box sx={{ display: 'flex' }} key={index}>
                <ActionItem
                  image="/assets/images/clock_icon.svg"
                  text={item}
                  padding="small"
                  onClick={() => handleSearch(item)}
                />
                <img
                  src="/assets/images/close_btn.svg"
                  style={{ width: '16px', margin: '13px 16px 13px -4px' }}
                  onClick={() => removeRecentSearchValue(item)}
                />
              </Box>
            ))}
          </>
        )}

        {!showResult && (
          <div>
            {beforeSearch && searchValue && (
              <ActionItem
                image="/assets/images/toolbar_search_black.svg"
                text={searchValue}
                padding="small"
                onClick={() => handleSearch(searchValue)}
              />
            )}
            {isLoadingSuggestWord && <SuggestedWordSkeleton />}
            {!isLoading &&
              !isLoadingSuggestWord &&
              searchValue &&
              suggestedWord.map((item, index) => (
                <ActionItem
                  key={index}
                  image="/assets/images/toolbar_search_black.svg"
                  text={item}
                  padding="small"
                  onClick={() => handleSearch(item)}
                />
              ))}
          </div>
        )}
        {/* {result.length !== 0 && ( */}
        {showResult && result.length > 0 && (
          <Grid item xs className={styles.postList} id="postListContainer">
            <InfiniteScroll
              dataLength={result.length} //This is important field to render the next data
              next={nextPage}
              hasMore={hasMore}
              loader={<SocialWallCardSkeleton />}
              scrollableTarget="postListContainer"
            >
              {result.map((data: any, index: number) => (
                <Box sx={{ marginTop: '8px' }} key={index}>
                  <SocialWallCard
                    {...data}
                    id={data._id}
                    size="large"
                    disableLike={
                      Boolean(userState.permission?.SocialWallAdmin)
                        ? !socialWallPermission?.like.admin
                        : !socialWallPermission?.like.all
                    }
                    disableComment={
                      Boolean(userState.permission?.SocialWallAdmin)
                        ? !socialWallPermission?.comment.admin
                        : !socialWallPermission?.comment.all
                    }
                  />
                </Box>
              ))}
            </InfiniteScroll>
          </Grid>
        )}

        {/* )} */}
        {!isLoading &&
          !isLoadingSuggestWord &&
          !beforeSearch &&
          searchValue &&
          result.length === 0 && (
            <Box className={styles.emptyResultContainer}>
              <img src="/assets/images/emptySearchResult.svg" alt="" />
              <Typography variant="h3">
                {t('myCalendar.noResult.title')}
              </Typography>
              <Typography
                variant="body1"
                color={theme.vars.palette.black.opacity38}
              >
                {t('myCalendar.noResult.description')}
              </Typography>
            </Box>
          )}
      </Grid>
    </>
  );
};
const SuggestedWordSkeleton: FC = () => {
  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Stack spacing="12px" direction="row" sx={{ padding: '13px 16px' }}>
          <img
            src="/assets/images/toolbar_search_black.svg"
            alt=""
            style={{ width: '20px' }}
          />

          <Skeleton variant="text" animation="wave" height={20} width={200} />
        </Stack>
      </Grid>
    </Grid>
  );
};
const SocialWallCardSkeleton: FC = () => {
  return (
    <Grid container alignItems="center" className={styles.SocialWallCard}>
      <Grid item xs>
        <Stack spacing={0.5}>
          <Stack spacing={0.5} direction="row">
            <Skeleton
              width={40}
              height={40}
              animation="wave"
              variant="circular"
            />
            <Box>
              <Skeleton
                variant="text"
                animation="wave"
                height={20}
                width={150}
              />
              <Skeleton
                variant="text"
                animation="wave"
                height={20}
                width={150}
              />
            </Box>
          </Stack>
          <Skeleton variant="text" animation="wave" height={20} width="100%" />
          <Skeleton
            variant="rounded"
            animation="wave"
            height={200}
            width="100%"
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SocialWallSearch;
