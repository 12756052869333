import React, { useEffect } from 'react';

import { Box, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useParams, useSearchParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import { NoResults } from '../../components';

import { redeemCoupons, redeemFromDogeSoft } from '../../services/coupon';
import { convertDateWithFullMonth, getApiDataByLang } from '../../utility';
import CouponItem from '../myCoupon/couponItem';
import { CouponItemProps } from '../myCoupon/interface';
import couponStyles from '../myCoupon/myCoupon.module.scss';

import 'swiper/css';
import 'swiper/css/pagination';

type Props = {
  availableCouponList?: CouponItemProps[];
  getActiveIndex?: (index: number) => void;
  initialSlide?: number | null;
  totalPage?: number;
};

const Coupon = ({
  data,
  pagination,
  isPagination,
  pageBackgroundColor,
}: any) => {
  const { t } = useTranslation();
  return (
    <Grid
      item
      className={couponStyles.couponDetail}
      sx={{
        paddingTop: '32px',
      }}
    >
      <Grid container className={couponStyles.detailContainer}>
        <Grid
          item
          className={couponStyles.detailWrapper}
          sx={{
            '&:before, &:after': {
              backgroundColor: pageBackgroundColor,
            },
          }}
        >
          <CouponItem {...data} isPublic isDetail />
          <div className={couponStyles.qrCode}>
            <div
              className={couponStyles.code}
              style={{ color: data.secondaryColor, marginBottom: '4px' }}
            >
              {t('coupon.couponCode')}: {data.code}
            </div>

            {data.usedDate && (
              <div
                className={couponStyles.expiryDate}
                style={{ color: data.secondaryColor }}
              >
                {t('coupon.usedDate')}:{' '}
                {convertDateWithFullMonth(data.usedDate)}
              </div>
            )}
            <div
              className={couponStyles.expiryDate}
              style={{ color: data.secondaryColor }}
            >
              {t('coupon.validUntil')}:{' '}
              {convertDateWithFullMonth(data.expiryDate)}
            </div>
            <div className={couponStyles.qrCodeText}>
              {t('coupon.qrCodeText')}
            </div>
            <Box className={couponStyles.qrCodeContainer}>
              <QRCode
                size={150}
                value={data.code}
                viewBox={`0 0 150 150`}
                {...(data.status !== 'Available' &&
                  data.status !== 'Transferred' && {
                    style: { opacity: 0.5 },
                  })}
              />
              {data.status !== 'Available' && data.status !== 'Transferred' && (
                <div className={couponStyles.qrStatus}>
                  {t(`coupon.${data.status?.toLowerCase()}`)}
                </div>
              )}
            </Box>
            {isPagination && (
              <Box className={couponStyles.pagination}>
                <div>{t('coupon.nextCoupon')}</div>
                <div>{pagination}</div>
              </Box>
            )}
          </div>
        </Grid>
        <Grid item className={couponStyles.tnc}>
          <div className={couponStyles.tncTitle}>{t('coupon.tncTitle')}</div>
          <pre className={couponStyles.tncContent}>{data.terms}</pre>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ReceiveCoupon = (props: Props) => {
  const {
    availableCouponList = null,
    getActiveIndex,
    initialSlide = null,
    totalPage = null,
  } = props;
  const swiperRef = React.useRef<any>(null);
  // console.log('initialSlide', initialSlide);
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code');
  const [data, setData] = React.useState<CouponItemProps[]>([]);
  const [loading, setLoading] = React.useState(false);
  const { hash } = useParams();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const containerRef = React.useRef<HTMLDivElement>(null!);

  useEffect(() => {
    console.log('availableCouponList', availableCouponList);
    if (availableCouponList) {
      setData(availableCouponList);
    }
  }, [availableCouponList]);

  useEffect(() => {
    // setInitialIndex(initialSlide);
    if (swiperRef.current) {
      swiperRef?.current?.swiper.slideTo(initialSlide);
    }
  }, [initialSlide]);

  useEffect(() => {
    console.log('code', { code });
    console.log('hash', { hash });
    const getData = async (
      hash: string | undefined,
      code: string | undefined | null
    ) => {
      setLoading(true);
      try {
        const response: any = hash
          ? await redeemCoupons({ hash })
          : code
            ? await redeemFromDogeSoft({ code })
            : null;

        // console.log('response', response);
        if (response.status === 200) {
          const availableCouponList = response.data.availableCouponList;
          const temp = availableCouponList.map((item: any) => {
            return {
              denomination: item.denomination,
              name: item.couponName ? getApiDataByLang(item.couponName) : '-',
              code: item.code,
              expiryDate: item.end_date,
              usedDate: item.used_date,
              logo: item.logoImage,
              background: item.backgroundImage,
              backgroundColor: item.cardBackgroundColour,
              primaryColor: item.primaryTextColour,
              secondaryColor: item.secondaryTextColour,
              pageBackgroundColor: item.pageBackgroundColour,
              status: item.status,
              terms: item.termsAndCondition
                ? getApiDataByLang(item.termsAndCondition)
                : '-',
            };
          });

          setData(temp);
        }
      } catch (error) {
        console.error('redeemCoupons', error);
      } finally {
        setLoading(false);
      }
    };
    (hash || code) && getData(hash, code);
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description" content="Digital Coupons" />
      </Helmet>
      <div
        className={couponStyles.swiperContainer}
        ref={containerRef}
        style={{
          height: '100vh',
          overflowX: 'hidden',
          ...(data.length > 0 && {
            backgroundColor: data[activeIndex].pageBackgroundColor,
          }),
        }}
      >
        {!loading && (
          <>
            {data.length > 0 ? (
              <Swiper
                ref={swiperRef}
                slidesPerView={'auto'}
                centeredSlides={true}
                spaceBetween={-15}
                breakpoints={{
                  375: {
                    spaceBetween: -21,
                  },
                  390: {
                    spaceBetween: -15,
                  },
                }}
                autoHeight={true}
                onBeforeSlideChangeStart={() => {
                  console.log('onBeforeSlideChangeStart');
                  if (containerRef.current) {
                    containerRef.current.style.overflowY = 'hidden';
                  }
                }}
                onTouchEnd={() => {
                  // console.log('onTouchEnd');
                  if (containerRef.current) {
                    setTimeout(() => {
                      containerRef.current.style.overflowY = 'auto';
                    }, 500);
                  }
                }}
                onSlideChangeTransitionStart={() => {
                  // console.log('onSlideChangeTransitionStart');
                  if (containerRef.current) {
                    containerRef.current.style.overflowY = 'hidden';
                  }
                }}
                onSlideChangeTransitionEnd={(swiper) => {
                  // console.log('onEnd');
                  setActiveIndex(swiper.activeIndex);
                  getActiveIndex && getActiveIndex(swiper.activeIndex);
                }}
                mousewheel={{
                  forceToAxis: true,
                }}
              >
                {data.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Coupon
                        data={item}
                        pagination={`${index + 1}/${totalPage ?? data.length}`}
                        isPagination={data.length > 1}
                        pageBackgroundColor={
                          data[activeIndex].pageBackgroundColor
                        }
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              <NoResults />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ReceiveCoupon;
