import React, { useEffect } from 'react';

import { Alert, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { RoundButton } from '../../components';
import Header from '../../components/header/header';

import { getTransferredCouponDetail } from '../../services/coupon';
import { convertDateTime, getApiDataByLang } from '../../utility';
import CouponItem from './couponItem';
import styles from './transferred.module.scss';

type Props = {};

const TransferredCoupons = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>(null!);

  useEffect(() => {
    const getData = async (couponId: string) => {
      setLoading(true);
      try {
        const response: any = await getTransferredCouponDetail(couponId);
        // console.log('getTransferredCouponDetail', response);
        if (response.status === 200) {
          const { data } = response;
          const temp = {
            transferredAt: data.createTime,
            remarks: data.remark,
            url: `${window.location.origin}/receive-coupon${data.hash ? `/${data.hash}` : `?code=${data.oldTransUrlSlug}`}`,
            coupon: data.transferredCouponList.map((item: any) => {
              return {
                id: item._id,
                denomination: item.denomination,
                name: item.couponName ? getApiDataByLang(item.couponName) : '-',
                code: item.code,
                expiryDate: item.end_date,
                logo: item.logoImage,
                background: item.backgroundImage,
                backgroundColor: item.cardBackgroundColour,
                primaryColor: item.primaryTextColour,
                secondaryColor: item.secondaryTextColour,
                status: item.status,
              };
            }),
          };
          // console.log('temp', temp);
          setData(temp);
        }
      } catch (error) {
        console.error('getTransferredCouponDetail', error);
      } finally {
        setLoading(false);
      }
    };
    id && getData(id);
  }, []);

  return (
    <>
      <Header
        title={t('coupon.transferredCoupons')}
        enableBackButton
        closeButtonFunction={() => navigate('/my-coupon/history')}
      />
      <Grid item className={styles.container}>
        <Alert
          icon={<img src="/assets/images/circle-information.svg" alt="" />}
          severity="warning"
        >
          {t('coupon.transferredCouponsAlert')}
        </Alert>
        <div className={styles.section}>
          <Grid container className={styles.row} direction="column">
            <label>{t('coupon.transferredAt')}</label>
            <div className={styles.text}>
              {data?.transferredAt && convertDateTime(data?.transferredAt)}
            </div>
          </Grid>
          <Grid container className={styles.row} direction="column">
            <label>{t('coupon.numberOfCoupons')}</label>
            <div className={styles.text}>{data?.coupon.length}</div>
          </Grid>
          <Grid container className={styles.row} direction="column">
            <label>{t('coupon.remarks')}</label>
            <div className={styles.text}>{data?.remarks}</div>
          </Grid>
        </div>
        <div
          className={styles.section}
          style={{ marginBottom: '60px', minHeight: 'calc(100vh - 480px)' }}
        >
          {data &&
            data.coupon.length > 0 &&
            data.coupon.map((item: any) => {
              return (
                <CouponItem
                  {...item}
                  key={`coupon-${item.id}`}
                  showBackground={false}
                  onItemClick={(item) => {
                    navigate(`/my-coupon/detail/${item.id}`);
                  }}
                />
              );
            })}
        </div>
        <RoundButton
          className={styles.copyLink}
          onClick={() => {
            data && navigator.clipboard.writeText(data.url);
          }}
        >
          {t('coupon.copyLink')}
        </RoundButton>
      </Grid>
    </>
  );
};

export default TransferredCoupons;
