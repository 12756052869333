import { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

import { ActionItem, NoResults } from '../../components';
import Header from '../../components/header/header';

import { getPastWinnersPeriods } from '../../services/leaderboard';
import { convertPeriod } from '../../utility';
import styles from './pastWinners.module.scss';

function PastWinners() {
  useEffect(() => {
    setIsLoading(true);
    getPastWinnersPeriods().then((response) => {
      setData(response.data.periods);
      setIsLoading(false);
    });
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Array<any>>([]);
  const [filter, setFilter] = useState(Array<any>);
  const navigate = useNavigate();

  return (
    <>
      <Box className={styles.headerContainer}>
        <Header
          title={t('leaderboard.pastWinners')}
          enableBackButton
          disableBottomBorder
          closeButtonFunction={() => {
            navigate(-1);
          }}
        ></Header>
      </Box>

      {!isLoading && data && (
        <Grid item xs className={styles.contentContainer}>
          {data.map((item: any, index: number) => (
            <ActionItem
              key={index}
              endIcon="/assets/images/chevron_right.svg"
              text={convertPeriod(item.startDate, item.endDate)}
              onClick={() => navigate(`list/${item._id}`)}
            />
          ))}
        </Grid>
      )}
      {!isLoading && data.length === 0 && (
        <Box className={styles.noResultsContainer}>
          <NoResults />
        </Box>
      )}
    </>
  );
}

export default PastWinners;
