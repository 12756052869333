import { AxiosPromise } from 'axios';

import { removeApiCacheSession } from '../utility';
import { instance, searchInstance } from './api-services';

const servicePath = '/documents';

const Region = process.env.REACT_APP_LOCATION === 'TWN' ? 'tw' : 'hk';

// Folder
export const getFolder = (input: {
  id?: string;
  search?: string;
}): AxiosPromise =>
  searchInstance({
    method: 'GET',
    id: `getFiles-folder-${input.id}`,
    url: `${servicePath}/folder/${Region}`,
    cache: false,
    params: input,
  });

export const getFolderDetail = (id: string): AxiosPromise =>
  instance({
    method: 'GET',
    id: `getFiles-folderDetail-${id}`,
    url: `${servicePath}/folder/tw/${id}`,
  });

export const getRootFolderDetail = (): AxiosPromise =>
  instance({
    method: 'GET',
    cache: false,
    url: `${servicePath}/folder/${Region}/root/detail`,
  });

export const createFolder = (input: any): AxiosPromise => {
  removeApiCacheSession('getFiles-');
  return instance({
    method: 'POST',
    data: input,
    url: `${servicePath}/folder/tw`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const updateFolder = (id: string, input: any): AxiosPromise => {
  removeApiCacheSession('getFiles-');
  return instance({
    method: 'PUT',
    data: input,
    url: `${servicePath}/folder/tw/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const deleteFolder = (id: string): AxiosPromise => {
  removeApiCacheSession('getFiles-');
  return instance({
    method: 'DELETE',
    url: `${servicePath}/folder/tw/${id}`,
  });
};

// File

export const getFilesDetail = (id: string): AxiosPromise =>
  instance({
    method: 'GET',
    id: `getFiles-filesDetail-${id}`,
    url: `${servicePath}/files/tw/${id}`,
  });

export const createFiles = (input: {
  name: string;
  folderId?: string;
  file: File;
}): AxiosPromise => {
  removeApiCacheSession('getFiles-');
  const formData = new FormData();
  formData.append('name', input.name);
  input.folderId && formData.append('folderId', input.folderId);
  formData.append('file', input.file);
  return instance({
    method: 'POST',
    data: formData,
    url: `${servicePath}/files/tw`,
    headers: {
      'Content-Type': 'form-data',
    },
  });
};

export const updateFiles = (id: string, input: any): AxiosPromise => {
  removeApiCacheSession('getFiles-');
  return instance({
    method: 'PUT',
    data: input,
    url: `${servicePath}/files/tw/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const deleteFiles = (id: string): AxiosPromise => {
  removeApiCacheSession('getFiles-');
  return instance({
    method: 'DELETE',
    url: `${servicePath}/files/tw/${id}`,
  });
};

export const getTeamsFileDirectoryList = (MSToken: string): AxiosPromise =>
  instance({
    method: 'GET',
    cache: false,
    url: `${servicePath}/files/${
      process.env.REACT_APP_LOCATION === 'PHL' ? 'ph' : 'hk'
    }/channels`,
    headers: {
      'Content-Type': 'application/json',
      MSToken: MSToken,
    },
  });

export const getTeamsFileList = (input: {
  folderId: string;
  driveId: string;
  MSToken: string;
}): AxiosPromise => {
  const { MSToken, ...rest } = input;
  return instance({
    method: 'GET',
    cache: false,
    url: `${servicePath}/files/${
      process.env.REACT_APP_LOCATION === 'PHL' ? 'ph' : 'hk'
    }`,
    headers: {
      'Content-Type': 'application/json',
      MSToken: MSToken,
    },
    params: rest,
  });
};

export const teamsFilesSearch = (input: {
  MSToken: string;
  search: string;
}): AxiosPromise => {
  const { MSToken, ...rest } = input;
  return instance({
    method: 'GET',
    cache: false,
    url: `${servicePath}/files/${
      process.env.REACT_APP_LOCATION === 'PHL' ? 'ph' : 'hk'
    }/search`,
    headers: {
      'Content-Type': 'application/json',
      MSToken: MSToken,
    },
    params: rest,
  });
};
