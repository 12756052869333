import React, { useEffect, useState } from 'react';

import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import Header from '../../components/header/header';

import { getBasket } from '../../services/leaderboard';
import { convertDateTime } from '../../utility';
import styles from './pointRecord.module.scss';

const RecordItem = (props: any) => {
  // console.log("props", props);
  return (
    <>
      {props.item.isShowLabel && (
        <Typography variant="h5" className={`${styles.filterHeader}`}>
          {moment.unix(props.item.lastUpdateTime).format('MMMM, YYYY')}
        </Typography>
      )}
      <Stack direction="row" className={styles.campaignItemWrapper}>
        <Stack gap="8px">
          <Typography variant="subtitle2">
            {t(`leaderboard.missions.${props.item.earningName}`)}
          </Typography>
          <Typography variant="body2" className={styles.dateTime}>
            {convertDateTime(props.item.lastUpdateTime)}
          </Typography>
        </Stack>
        <Typography variant="subtitle2" className={styles.points}>
          {props.item.addCherries > 0 ? '+' : ''} {props.item.addCherries}{' '}
          <span>{t('leaderboard.pts')}</span>
        </Typography>
      </Stack>
    </>
  );
};

function PointRecord() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [isEnded, setIsEnded] = useState<boolean>(false);
  const [apiPagination, setApiPagination] = React.useReducer(
    (prev: PaginationType, next: Partial<PaginationType>) => {
      return { ...prev, ...next };
    },
    {
      page: 1,
      pageSize: 10,
    }
  );

  useEffect(() => {
    setIsLoading(true);
    setData([]);
    const getBasketData = async () => {
      try {
        const response = await getBasket({
          page: apiPagination.page,
          pageSize: apiPagination.pageSize,
        });
        // processData(response.data.basket);
        if (response.status === 200) {
          // console.log("response", response);
          const temp = response.data.basket.map((item: any, index: number) => {
            const prev = response.data.basket[index - 1];
            const prevDate = prev
              ? moment.unix(prev.lastUpdateTime).format('MMMM, YYYY')
              : null;
            const itemDate = moment
              .unix(item.lastUpdateTime)
              .format('MMMM, YYYY');
            return {
              ...item,
              isShowLabel: prevDate !== itemDate,
            };
          });
          // console.log("temp", temp);
          // check header date
          setData(temp);
          if (
            apiPagination.page >= response.data.totalPage ||
            !response.data.totalPage
          ) {
            setIsEnded(true);
          } else {
            setApiPagination({ page: apiPagination.page + 1 });
          }
          setIsLoading(false);
        }
      } catch (error) {
        console.log('getBasketData', error);
        setIsLoading(false);
      }
    };
    getBasketData();
  }, []);

  async function fetchMoreData() {
    try {
      const response = await getBasket({
        page: apiPagination.page,
        pageSize: apiPagination.pageSize,
      });

      const temp = response.data.basket.map((item: any, index: number) => {
        const prev = response.data.basket[index - 1];
        const prevDate = prev
          ? moment.unix(prev.lastUpdateTime).format('MMMM, YYYY')
          : moment
              .unix(data[data.length - 1].lastUpdateTime)
              .format('MMMM, YYYY');
        const itemDate = moment.unix(item.lastUpdateTime).format('MMMM, YYYY');
        return {
          ...item,
          isShowLabel: prevDate !== itemDate,
        };
      });

      setData((prev: any) => [...prev, ...temp]);

      if (apiPagination.page >= response.data.totalPage) {
        setIsEnded(true);
      } else {
        setApiPagination({ page: apiPagination.page + 1 });
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Box className={styles.headerContainer}>
        <Header
          title={t('leaderboard.basket')}
          enableBackButton
          disableBottomBorder
          closeButtonFunction={() => {
            navigate(-1);
          }}
        ></Header>
      </Box>

      <Grid item xs>
        {!isLoading && (
          <Box className={styles.contentContainer}>
            {data.length > 0 ? (
              <InfiniteScroll
                dataLength={data.length}
                next={fetchMoreData}
                hasMore={!isEnded}
                loader={
                  <Skeleton
                    variant="text"
                    animation="wave"
                    height={40}
                    width="100%"
                  />
                }
                // scrollableTarget="scrollableDiv"
              >
                {data.map((record: any, index: number) => {
                  return <RecordItem item={record} />;
                })}
              </InfiniteScroll>
            ) : (
              <Box className={styles.noPostContainer}>
                <img src="/assets/images/empty.png" alt="" />
                <Typography variant="h3">{t('general.stayTuned')}</Typography>
                <Typography>{t('general.stayTunedMsg')}</Typography>
              </Box>
            )}
          </Box>
        )}
      </Grid>
    </>
  );
}

export default PointRecord;
