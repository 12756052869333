import React, { FC, useEffect, useState } from 'react';

import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  SvgIcon,
  SvgIconProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ObjectKey } from '../../interfaces/common-interface';
import { PaginationParamsType } from '../../services/common';
import { containsObject } from '../../utility';
import InputField from '../input-field';
import MultiFilter, { FilterItemProps, OptionsListType } from '../multi-filter';
import NoResults from '../no-results';
import SearchField from '../search-field';
import { SelectFieldProps } from '../select-field';
import SelectFieldContainer from '../select-field-container';
import { useSelectHook } from '../select-field/useSelectHook';
import styles from './auto-complete-field.module.scss';

interface AutoCompleteFieldProps extends Omit<SelectFieldProps, 'options'> {
  options?: Array<ObjectKey>;
  listFetch?: (data?: PaginationParamsType) => Promise<ObjectKey>;
  listFetchKey?: string;
  convertResponse?: (data: Array<ObjectKey>) => Array<ObjectKey>;
  disableActions?: boolean;
  disableSearchCancel?: boolean;
  freeText?: boolean;
  filterKey?: string | string[];
  filterFetch?: () => Promise<ObjectKey>;
  isEdit?: boolean;
  groupId?: string;
  showSearchIcon?: boolean;
}

const AutoCompleteField: FC<AutoCompleteFieldProps> = (props) => {
  const {
    value,
    options,
    multiple,
    disableLabel,
    disableActions,
    freeText,
    getOptionValue,
    getOptionLabel,
    filterKey,
    otherFieldValue,
    otherFieldProps,
    optionsItem,
    disableSearchCancel,
    listFetch,
    listFetchKey,
    convertResponse = (data) => data,
    filterFetch,
    showSearchIcon = true,
    ...containerProps
  } = props;
  const {
    multipleValueDisplayRef,
    selectedValue,
    enableOtherField,
    handleSelectOption,
    handleReset,
    displayValue,
    handleClear,
  } = useSelectHook({
    value,
    multiple,
    otherFieldValue,
    otherFieldProps,
    getOptionValue,
    getOptionLabel,
  });
  const [isEdit, setIsEdit] = useState<any>(props.isEdit ?? null);
  const [result, setResult] = useState<Array<ObjectKey>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listParams, setListParams] = useState<PaginationParamsType>({
    page: 1,
    pageSize: 20,
    search: '',
  });
  const [hasMore, setHasMore] = useState(true);
  const [initList, setInitList] = useState(false);
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filterList, setFilterList] = useState<Array<OptionsListType>>([]);
  const filterButtonProps = filterFetch
    ? {
        onFilter: () => setOpenFilter(true),
        activeFilter: listParams.filter && listParams.filter.length > 0,
      }
    : {};

  useEffect(() => {
    if (isEdit === true) {
      handleClear();
      setIsEdit(false);
    }
  }, [isEdit]);

  const getList = React.useCallback(async () => {
    if (!initList) return false;
    let active = true;

    if (listFetch) {
      setIsLoading(true);
      setHasMore(true);
      try {
        const { filter, ...request } = listParams;
        const filterRequest = filter
          ? filter.map((item: ObjectKey) => {
              return {
                key: item.key,
                selected: item.selected.map(
                  (option: ObjectKey) => option.value
                ),
              };
            })
          : [];
        // console.log("groupId", props.groupId);
        // console.log("listParams", {
        //   ...request,
        //   filter: filterRequest,
        //   ...(props.groupId && { partnerNetworkGroupId: props.groupId }),
        // });

        const response = await listFetch({
          ...request,
          filter: filterRequest,
          ...(props.groupId && { partnerNetworkGroupId: props.groupId }),
        });
        let responseList: Array<ObjectKey> = [];

        if (listFetchKey) {
          if (response.code !== 'ERR_CANCELED') {
            responseList = convertResponse(response.data[listFetchKey]);
          }
        } else {
          responseList = convertResponse(response.data);
        }

        if (responseList?.length > 0) {
          if (active) {
            setResult((prev) => [...prev, ...responseList]);
            if (
              listParams.pageSize &&
              responseList.length < listParams.pageSize
            ) {
              setHasMore(false);
            }
          }
        } else {
          setHasMore(false);
        }
        setIsLoading(false);
        // eslint-disable-next-line
      } catch (error: any) {
        if (error.code && error.code !== 'ERR_CANCELED') {
          setIsLoading(false);
        }
        throw error;
      }
    }

    return () => {
      active = false;
    };
  }, [listParams, initList]);

  useEffect(() => {
    if (options) {
      setResult(options);
    } else if (listFetch) {
      if (delaySearchThread) {
        clearTimeout(delaySearchThread);
      }
      const threadId = setTimeout(() => {
        getList();
      }, 2000);
      setDelaySearchThread(threadId);
    }
  }, [options, getList, listFetch]);

  const handleConfirmSelected = (callback: () => void) => {
    if (isEdit === false) {
      setIsEdit(null);
    }
    containerProps.onChange(selectedValue);
    callback();
  };
  const [delaySearchThread, setDelaySearchThread] =
    useState<NodeJS.Timeout | null>(null);
  const handleResetField = () => {
    setResult(options ?? []);
    setListParams((prev) => ({ ...prev, page: 1, search: '' }));
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setListParams((prev) => ({ ...prev, page: 1, search: value }));

    if (options && options.length > 0) {
      setResult(
        options.filter((option) => {
          if (filterKey) {
            if (Array.isArray(filterKey)) {
              return filterKey.some((key) => {
                return option[key].toLowerCase().includes(value.toLowerCase());
              });
            } else {
              return option[filterKey]
                .toLowerCase()
                .includes(value.toLowerCase());
            }
          } else {
            return getOptionLabel(option)
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        })
      );
    } else {
      setResult([]);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!freeText) return false;
    if (e.key === 'Enter' || e.keyCode === 13) {
      const freeTextValue = {
        id: '-999',
        name: listParams.search,
        address: listParams.search,
      };
      handleSelectOption(freeTextValue, multiple);
    }
  };

  const SelectedList = () => {
    if (!selectedValue || !Array.isArray(selectedValue)) return null;

    return (
      <List className={styles.optionsList}>
        {/* eslint-disable-next-line */}
        {(selectedValue as Array<any>).map((option: any, index: number) => {
          const selectedOptionID = `${
            containerProps.name
          }SelectedOption_${getOptionValue(option)}`;
          return (
            <Option
              key={index}
              option={option}
              id={selectedOptionID}
              onClick={() => {
                handleSelectOption(option, multiple);
              }}
              forRemove
            />
          );
        })}
      </List>
    );
  };

  const Option = (props: {
    // eslint-disable-next-line
    option: any;
    id: string;
    onClick: () => void;
    forRemove?: boolean;
  }) => {
    let isSelected = false;
    if (selectedValue) {
      if (multiple) {
        isSelected = containsObject(props.option, selectedValue);
      } else {
        isSelected =
          getOptionValue(selectedValue) === getOptionValue(props.option);
      }
    }

    const removeBtnProps = props.forRemove
      ? {
          secondaryAction: (
            <IconButton
              id={`remove-${props.id}-btn`}
              edge="end"
              className={styles.removeBtn}
              onClick={props.onClick}
            >
              <img src="/assets/images/close_btn.svg" alt="Remove Option" />
            </IconButton>
          ),
        }
      : {};

    const itemButtonClass = props.forRemove
      ? styles.optionItem
      : `${styles.optionItem} ${isSelected && styles.optionItem__active}`;

    const SelectedIcon = () => {
      const SelectedIconProps = {
        disableRipple: true,
        checked: isSelected,
        value: getOptionValue(props.option),
        inputProps: { 'aria-labelledby': props.id },
        tabIndex: -1,
      };

      if (!multiple) {
        // return (
        //   <ListItemIcon>
        //     <Radio
        //       {...SelectedIconProps}
        //       edge="end"
        //       className={styles.optionRadio}
        //     />
        //   </ListItemIcon>
        // );
        return null;
      }

      return (
        <ListItemIcon>
          <Checkbox
            {...SelectedIconProps}
            edge="end"
            className={styles.optionCheckbox}
            icon={<CheckBoxIcon />}
            checkedIcon={<CheckBoxCheckedIcon />}
          />
        </ListItemIcon>
      );
    };

    return (
      <ListItem id={props.id} disablePadding {...removeBtnProps}>
        <ListItemButton
          className={itemButtonClass}
          onClick={(e) =>
            !props.forRemove ? props.onClick() : e.preventDefault()
          }
        >
          {props.forRemove && !multiple ? null : <SelectedIcon />}
          {optionsItem ? (
            optionsItem(props.option, props.id)
          ) : (
            <ListItemText primary={getOptionLabel(props.option)} />
          )}
        </ListItemButton>
      </ListItem>
    );
  };

  const nextPage = () => {
    if (!isLoading) {
      setListParams((prev) => ({
        ...prev,
        page: prev.page ? prev.page + 1 : 1,
      }));
    }
  };

  useEffect(() => {
    const getFilterList = async () => {
      if (!filterFetch) return false;

      const response = await filterFetch();
      if (response.status === 200) {
        let convertFilter: Array<OptionsListType> = [];

        Object.keys(response.data).forEach((key: string) => {
          const categoryName =
            key === 'department'
              ? t(`staffDirectory.filter.function`)
              : t(`staffDirectory.filter.${key}`);
          convertFilter = [
            ...convertFilter,
            {
              key: key,
              name: categoryName,
              options: response.data[key].map((item: ObjectKey) => {
                const { _id, storeId, DMArea, OMArea, ...value } = item;
                const optionKey = Object.keys(value)[0];
                return {
                  categoryId: key,
                  value: _id ?? storeId,
                  displayValue: item[optionKey],
                };
              }),
            },
          ];
        });
        setFilterList(convertFilter);
      }
    };

    if (filterFetch) {
      getFilterList();
    }
  }, [filterFetch, t]);

  const handleApplySelectedFilter = (selected: ObjectKey[]) => {
    setResult([]);
    setListParams((prev) => ({
      ...prev,
      page: 1,
      filter: selected,
    }));
    setOpenFilter(false);
  };

  return (
    <SelectFieldContainer
      {...containerProps}
      ref={multiple ? multipleValueDisplayRef : null}
      disableLabel={disableLabel}
      disableActions={disableActions && !multiple}
      multiple={multiple}
      value={displayValue()}
      selectedValue={selectedValue}
      selectedList={<SelectedList />}
      onConfirmSelected={handleConfirmSelected}
      onClear={() => {
        handleClear();
      }}
      onCancel={() => {
        if (isEdit === false) {
          setIsEdit(true);
        }
        handleReset();
      }}
      onClickField={() => {
        if (!initList) setInitList(true);
      }}
    >
      <Stack className={styles.autoCompleteContainer}>
        <SearchField
          id={`${containerProps.name}Search`}
          name={`${containerProps.name}Search`}
          value={listParams.search ?? ''}
          placeholder={
            freeText
              ? containerProps.placeholder
                ? containerProps.placeholder
                : t('general.add') + ' ' + containerProps.label
              : containerProps.placeholder
          }
          onChange={handleValueChange}
          onReset={handleResetField}
          onClose={disableSearchCancel ? undefined : handleResetField}
          onKeyDown={handleKeyPress}
          {...filterButtonProps}
          showSearchIcon={showSearchIcon}
        />
        {filterFetch && (
          <MultiFilter
            id={`${containerProps.name}FilterPopup`}
            open={openFilter}
            selected={
              listParams.filter
                ? (listParams.filter as FilterItemProps[])
                : ([] as FilterItemProps[])
            }
            disableResetButton
            options={filterList}
            onChange={(value) => handleApplySelectedFilter(value)}
            onClose={() => setOpenFilter(false)}
          />
        )}
        <Grid
          id={`${containerProps.name}ListsContainer`}
          item
          xs
          className={styles.autoCompleteDropdown}
        >
          {!isLoading && result.length <= 0 && (
            <NoResults resultsType="SEARCH" />
          )}
          <InfiniteScroll
            dataLength={result.length} //This is important field to render the next data
            next={nextPage}
            hasMore={hasMore}
            loader={<LoadingSkeleton />}
            scrollableTarget={`${containerProps.name}ListsContainer`}
          >
            <List
              className={`${styles.optionsList} ${
                !multiple && styles.optionsList__multiple
              }`}
            >
              {result.map((option: ObjectKey, index: number) => {
                const optionID = `${
                  containerProps.name
                }SelectOption_${getOptionValue(option)}`;

                return (
                  <Option
                    key={index}
                    option={option}
                    id={optionID}
                    onClick={() => {
                      handleSelectOption(option, multiple);
                    }}
                  />
                );
              })}
              {enableOtherField && otherFieldProps && (
                <ListItem disablePadding className={styles.optionOtherField}>
                  <InputField {...otherFieldProps} />
                </ListItem>
              )}
            </List>
          </InfiniteScroll>
        </Grid>
      </Stack>
    </SelectFieldContainer>
  );
};

function LoadingSkeleton() {
  return (
    <Box sx={{ padding: 2 }}>
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={40}
      />
    </Box>
  );
}

function CheckBoxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2.5"
          y="2.5"
          width="19"
          height="19"
          rx="9.5"
          stroke="#BDBDBD"
        />
      </svg>
    </SvgIcon>
  );
}

function CheckBoxCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="2" width="20" height="20" rx="10" fill="#006241" />
        <path
          d="M6.66699 12.4074L9.94904 15.6667L17.3337 8.33337"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

export default AutoCompleteField;
