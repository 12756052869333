import React, { useEffect } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';

import { NoResults } from '../../components';

import { getCouponDetail } from '../../services/coupon';
import { convertDateWithFullMonth, getApiDataByLang } from '../../utility';
import CouponItem from './couponItem';
import { CouponItemProps } from './interface';
import styles from './myCoupon.module.scss';
import TransferCouponPopup from './transferCouponPopup';

type Props = {};

const CouponDetail = (props: Props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const couponContainerRef = React.useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const [data, setData] = React.useState<CouponItemProps>(null!);
  const [transferPopupOpen, setTransferPopupOpen] =
    React.useState<boolean>(false);
  // console.log("data", data?.status);

  useEffect(() => {
    const getData = async (couponId: string) => {
      setLoading(true);
      try {
        const response: any = await getCouponDetail(couponId);
        // console.log('getCouponDetail', response);
        if (response.status === 200) {
          const couponDetails = response.data.couponDetails;
          // console.log('couponDetails', couponDetails);
          const temp = {
            id: couponDetails._id,
            denomination: couponDetails.denomination,
            name: couponDetails.couponName
              ? getApiDataByLang(couponDetails.couponName)
              : '-',
            code: couponDetails.code,
            expiryDate: couponDetails.end_date,
            usedDate: couponDetails.used_date,
            logo: couponDetails.logoImage,
            background: couponDetails.backgroundImage,
            backgroundColor: couponDetails.cardBackgroundColour,
            primaryColor: couponDetails.primaryTextColour,
            secondaryColor: couponDetails.secondaryTextColour,
            pageBackgroundColor: couponDetails.pageBackgroundColour,
            status: couponDetails.status,
            terms: couponDetails.termsAndCondition
              ? getApiDataByLang(couponDetails.termsAndCondition)
              : '-',
          };
          // console.log(temp);
          setData(temp);
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    };
    id && getData(id);
  }, []);

  useEffect(() => {
    if (data && couponContainerRef.current) {
      if (window.innerHeight > couponContainerRef.current.clientHeight) {
        couponContainerRef.current.style.height = `100%`;
      }
    }
  }, [data]);
  return (
    <>
      <div
        className={styles.couponDetail}
        ref={couponContainerRef}
        style={{
          backgroundColor: data
            ? data.pageBackgroundColor
            : 'rgba(0, 98, 65, 1)',
        }}
      >
        <div className={styles.header}>
          <img
            src={'/assets/images/arrow_left_white.svg'}
            alt="back"
            onClick={() => navigate(-1)}
          />
          {data && data.status === 'Available' && (
            <button onClick={() => setTransferPopupOpen(true)}>
              {t('coupon.transfer')}
            </button>
          )}
        </div>
        {data ? (
          <>
            <Grid
              container
              className={styles.detailContainer}
              direction="column"
              style={{ maxWidth: '375px', margin: '0 auto' }}
            >
              <Grid
                item
                className={styles.detailWrapper}
                sx={{
                  '&:before, &:after': {
                    backgroundColor: data.pageBackgroundColor,
                  },
                }}
              >
                <CouponItem {...data} isDetail />
                <div className={styles.qrCode}>
                  <div
                    className={styles.code}
                    style={{ color: data.secondaryColor, marginBottom: '4px' }}
                  >
                    {t('coupon.couponCode')}: {data.code}
                  </div>
                  {data.usedDate && (
                    <div
                      className={styles.expiryDate}
                      style={{ color: data.secondaryColor }}
                    >
                      {t('coupon.usedDate')}:{' '}
                      {convertDateWithFullMonth(data.usedDate)}
                    </div>
                  )}
                  <div
                    className={styles.expiryDate}
                    style={{ color: data.secondaryColor }}
                  >
                    {t('coupon.validUntil')}:{' '}
                    {convertDateWithFullMonth(data.expiryDate)}
                  </div>
                  
                  <div className={styles.qrCodeText}>
                    {t('coupon.qrCodeText')}
                  </div>
                  <Box className={styles.qrCodeContainer}>
                    <QRCode
                      size={150}
                      value={data.code}
                      viewBox={`0 0 150 150`}
                      {...(data.status !== 'Available' && {
                        style: { opacity: 0.5 },
                      })}
                    />
                    {data.status !== 'Available' && (
                      <div className={styles.qrStatus}>
                        {t(`coupon.${data.status?.toLowerCase()}`)}
                      </div>
                    )}
                  </Box>
                </div>
              </Grid>
              <Grid item className={styles.tnc}>
                <div className={styles.tncTitle}>{t('coupon.tncTitle')}</div>
                <pre className={styles.tncContent}>{data.terms}</pre>
              </Grid>
            </Grid>
            <TransferCouponPopup
              title={data.name}
              selected={[data.id]}
              transferPopupOpen={transferPopupOpen}
              setTransferPopupOpen={setTransferPopupOpen}
            />
          </>
        ) : (
          <>{!loading && <NoResults />}</>
        )}
      </div>
    </>
  );
};

export default CouponDetail;
