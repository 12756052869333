import React, { useEffect, useState } from 'react';

import { decode, encode } from 'html-entities';
import { useParams } from 'react-router-dom';

import ActiveItemDetail from '../../components/content-hub/item-detail';
import Header from '../../components/header/header';

import { getContentHubDetail, Module } from '../../services/homepage';
import { getApiDataByLang } from '../../utility';

type Props = {};

const ContentHubDetail = (props: Props) => {
  const [activeItem, setActiveItem] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { type, id } = useParams();
  const ContentHubType: Record<string, Module> = {
    'art-walk': 'ART_WALK',
    'brewing-instructions': 'BREWING_INSTRUCTIONS',
    'partner-safety-and-global-guardian': 'PARTNER_SAFETY_AND_GLOBAL_GUARDIAN',
    announcement: 'ANNOUNCEMENTS',
    'partner-anniversary': 'PARTNER_ANNIVERSARY',
    newsletters: 'NEWSLETTERS',
    faq: 'FAQS',
  };

  console.log('useParams', type && ContentHubType[type], id);
  useEffect(() => {
    const getDetail = async (id: string, type: Module) => {
      try {
        const response: any = await getContentHubDetail(id, {
          module: type,
        });
        // console.log("getDetail", response);
        const detail = response.data;
        if (response && detail) {
          const temp = {
            id: detail.id,
            title: getApiDataByLang(detail.title),
            image: detail.coverImage,
            tab: getApiDataByLang(detail.contentCategory.categoryName),
            color: detail.contentCategory.color,
            date: detail.startDate,
            content: decode(getApiDataByLang(detail.content)).replace(
              /\[img /g,
              '<img src="'
            ),
          };
          setActiveItem(temp);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        // console.log(error);
        throw error;
      }
    };
    if (type && id) {
      getDetail(id, ContentHubType[type]);
    }
  }, []);

  return (
    <div>
      <Header title={''} enableBackButton></Header>
      {activeItem && <ActiveItemDetail {...activeItem} isLoading={loading} />}
    </div>
  );
};

export default ContentHubDetail;
