import React, { FC, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AccountItem, AccountItemSkeleton } from '../../components';
import Header from '../../components/header/header';
import MultiFilter, {
  FilterItemProps,
  OptionsListType,
} from '../../components/multi-filter';
import NoResults from '../../components/no-results';
import SearchField from '../../components/search-field';

import { ObjectKey } from '../../interfaces/common-interface';
import {
  getStaffFilterList,
  getStaffList,
  PaginationParamsType,
} from '../../services/common';
import {
  convertStaffList,
  setParamsToUrl,
  splitFilterParamsFromUrl,
  splitParamsFromUrl,
} from '../../utility';
import styles from './staffDirectory.module.scss';

const SocialWallSearch: FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState<Array<ObjectKey>>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filterList, setFilterList] = useState<Array<OptionsListType>>([]);
  const [listParams, setListParams] = useState<PaginationParamsType>({
    page: 1,
    pageSize: 20,
  });
  const [hasMore, setHasMore] = useState(true);
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [paramsConverting, setParamsConverting] = useState<boolean>(true);
  const [filterParams, setFilterParams] = useState<PaginationParamsType>({
    search: '',
    filter: [],
  });

  const [delaySearchThread, setDelaySearchThread] =
    useState<NodeJS.Timeout | null>(null);

  const handleResetField = () => {
    if (!isLoading) {
      setSearchData([]);
      setListParams((prev) => ({
        ...prev,
        page: 1,
        // search: "",
        // filter: [],
      }));
      setFilterParams((prev) => ({
        ...prev,
        search: '',
        filter: [],
      }));

      let paramsObj: ObjectKey = {
        search: '',
      };
      const filterParams = searchParams.get('filter');
      if (filterParams) {
        paramsObj = {
          ...paramsObj,
          filter: filterParams,
        };
      }
      const urlParams = setParamsToUrl(paramsObj);
      setSearchParams(urlParams);
    }
  };

  const handleFilter = () => {
    setOpenFilter(true);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchData([]);
    setFilterParams((prev) => ({
      ...prev,
      search: value,
    }));

    if (delaySearchThread) {
      clearTimeout(delaySearchThread);
    }

    const threadId = setTimeout(() => {
      setListParams((prev) => ({
        ...prev,
        page: 1,
        // search: value,
      }));

      let paramsObj: ObjectKey = {
        search: value,
      };
      const filterParams = searchParams.get('filter');
      if (filterParams) {
        paramsObj = {
          ...paramsObj,
          filter: filterParams,
        };
      }
      const urlParams = setParamsToUrl(paramsObj);
      setSearchParams(urlParams);
    }, 2000);

    setDelaySearchThread(threadId);
  };

  const getStaffDirectory = React.useCallback(async () => {
    let active = true;
    if (!paramsConverting) {
      setIsLoading(true);
      setHasMore(true);

      try {
        const search = searchParams.get('search');
        const filter = searchParams.get('filter');
        let newParamsObj: PaginationParamsType = {};
        if (filter) {
          newParamsObj = splitParamsFromUrl({ filter: [] }, 'filter=' + filter);
        }
        const request = {
          ...listParams,
          ...newParamsObj,
          search: search ?? '',
        };
        const response = await getStaffList(request);
        if (response.status === 200) {
          if (response.data.staffList.length > 0 && active) {
            const convertList = convertStaffList(response.data.staffList);
            setSearchData((prev) => [...prev, ...convertList]);
          } else {
            setHasMore(false);
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    }

    return () => {
      active = false;
    };
  }, [listParams, searchParams, paramsConverting]);

  useEffect(() => {
    const getFilterList = async () => {
      const response = await getStaffFilterList();
      if (response.status === 200) {
        let convertFilter: Array<OptionsListType> = [];

        Object.keys(response.data).forEach((key: string) => {
          const categoryName =
            key === 'department'
              ? t(`staffDirectory.filter.function`)
              : t(`staffDirectory.filter.${key}`);
          convertFilter = [
            ...convertFilter,
            {
              key: key,
              name: categoryName,
              options: response.data[key].map((item: ObjectKey) => {
                const { _id, storeId, DMArea, OMArea, ...value } = item;
                const optionKey = Object.keys(value)[0];
                return {
                  categoryId: key,
                  value: _id ?? storeId,
                  displayValue: item[optionKey],
                };
              }),
            },
          ];
        });
        setFilterList(convertFilter);
        const filter = searchParams.get('filter');
        if (filter) {
          const filterParamsArray = splitFilterParamsFromUrl(
            filter,
            convertFilter
          );
          setFilterParams({
            search: searchParams.get('search') ?? '',
            filter: filterParamsArray,
          });
          setSearchData([]);
          setParamsConverting(false);
        } else {
          setParamsConverting(false);
        }
      }
    };

    getFilterList();
  }, [t]);

  useEffect(() => {
    if (!paramsConverting) {
      const search = searchParams.get('search');
      let updateFilterParams = {
        search: '',
        filter: [],
      };
      if (search) {
        updateFilterParams = {
          ...updateFilterParams,
          search: search,
        };
      }
      const filter = searchParams.get('filter');
      if (filter) {
        const filterParamsArray = splitFilterParamsFromUrl(filter, filterList);
        updateFilterParams = {
          ...updateFilterParams,
          filter: filterParamsArray,
        };
      }
      setFilterParams((prev) => ({
        ...prev,
        ...updateFilterParams,
      }));
    }
  }, [searchParams]);

  useEffect(() => {
    getStaffDirectory();
  }, [getStaffDirectory]);

  const handleApplySelectedFilter = (selected: ObjectKey[]) => {
    setSearchData([]);
    setListParams((prev) => ({
      ...prev,
      page: 1,
      // filter: selected,
    }));
    setFilterParams((prev) => ({
      ...prev,
      filter: selected,
    }));
    let paramsObj: ObjectKey = {
      filter: selected,
    };
    const searchValueParams = searchParams.get('search');
    if (searchValueParams) {
      paramsObj = {
        ...paramsObj,
        search: searchValueParams,
      };
    }
    const urlParams = setParamsToUrl(paramsObj);
    setSearchParams(urlParams);
    setOpenFilter(false);
  };

  const nextPage = () => {
    if (!isLoading) {
      setListParams((prev) => ({
        ...prev,
        page: prev.page ? prev.page + 1 : 1,
      }));
    }
  };

  return (
    <>
      <Header
        title={t('staffDirectory.title')}
        enableBackButton
        closeButtonFunction={() => {
          navigate(-1);
        }}
      />
      <SearchField
        id="search"
        name="search"
        value={filterParams.search ?? ''}
        placeholder={t('general.search')}
        onChange={handleValueChange}
        onReset={handleResetField}
        onFilter={handleFilter}
        activeFilter={filterParams.filter?.length !== 0}
      />
      {filterList && (
        <MultiFilter
          id={'filterPopup'}
          open={openFilter}
          selected={
            filterParams.filter
              ? (filterParams.filter as FilterItemProps[])
              : ([] as FilterItemProps[])
          }
          disableResetButton
          options={filterList}
          onChange={(value) => handleApplySelectedFilter(value)}
          onClose={() => setOpenFilter(false)}
        />
      )}
      <Grid id="staffListsContainer" item xs className={styles.searchResult}>
        {/* {isLoading && <AccountItemSkeleton />} */}
        {!isLoading && !hasMore && searchData.length === 0 && (
          <NoResults resultsType="SEARCH" />
        )}
        <InfiniteScroll
          dataLength={searchData.length} //This is important field to render the next data
          next={nextPage}
          hasMore={hasMore}
          loader={<AccountItemSkeleton />}
          scrollableTarget="staffListsContainer"
        >
          {searchData.map((item, index) => (
            <AccountItem
              key={index}
              name={item.name}
              icon={item.avatar}
              description={item.jobTitle}
              padding="8px 16px"
              onClick={() => navigate(`/discover-people/profile/${item.id}`)}
            />
          ))}
        </InfiniteScroll>
      </Grid>
    </>
  );
};

export default SocialWallSearch;
