import { useCallback, useEffect, useState } from 'react';

import { Alert, Box, Grid, Skeleton, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CustomTab } from '../../components';
import Header from '../../components/header/header';
import InfiniteScrollContainer from '../../components/infinite-scroll-container/InfiniteScrollContainer';

import { getHistoryCount, getHistoryList } from '../../services/coupon';
import {
  convertDateTime,
  convertDateTimeWithFullMonth,
  getApiDataByLang,
} from '../../utility';
import CouponItem from './couponItem';
import styles from './history.module.scss';
import couponStyles from './myCoupon.module.scss';

type tabType = {
  value: string;
  display: string;
};

type Props = {};

const TransferredList = (props: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { couponType } = props;

  const convertData = (response: any, isNext: boolean) => {
    const data = response.historyLogList;
    if (data) {
      const temp = data.map((item: any) => {
        return {
          id: item._id,
          transferredAt: item.createTime,
          remarks: item.remark,
          count: item.couponCount,
          url: `${window.location.origin}/receive-coupon${item.hash ? `/${item.hash}` : `?code=${item.oldTransUrlSlug}`}`,
        };
      });
      if (!isNext) {
        setData(temp);
      } else {
        setData((prev: any) => [...prev, ...temp]);
      }
    }
  };
  return (
    <>
      <InfiniteScrollContainer
        fetchData={getHistoryList}
        returnData={convertData}
        apiParams={{ type: couponType }}
        list={data}
        pageSize={10}
        loadingSpinner={
          <Skeleton
            variant="rectangular"
            height={89}
            style={{ marginBottom: '2px' }}
          />
        }
      >
        {data.length > 0 && (
          <ul className={styles.transferredList}>
            {data.map((item: any) => {
              return (
                <li
                  key={`transferredItem-${item.id}`}
                  id={`transferredItem-${item.id}`}
                >
                  <div
                    className={styles.infoContainer}
                    onClick={() => {
                      navigate(`/my-coupon/transferred-coupons/${item.id}`);
                    }}
                  >
                    <img
                      src="/assets/images/coupon_icon.svg"
                      alt="transferred"
                    />
                    <div className={styles.info}>
                      <div
                        className={styles.title}
                      >{`${item.count} ${t('coupon.coupons')}`}</div>
                      <div className={styles.text}>
                        {t('coupon.transferredAt')}:&nbsp;
                        {convertDateTime(item.transferredAt)}
                      </div>
                      <div className={styles.text}>{item.remarks}</div>
                    </div>
                  </div>

                  <div className={styles.copyLink}>
                    <button
                      onClick={() => {
                        // console.log(item.url);
                        navigator.clipboard.writeText(item.url);
                        setIsCopied(true);
                      }}
                    >
                      {t('coupon.copyLink')}
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </InfiniteScrollContainer>
      <div className={styles.tips}>
        {t('coupon.disclaimer', { name: t('coupon.transferred') })}
      </div>
      <Snackbar
        open={isCopied}
        autoHideDuration={6000}
        onClose={() => setIsCopied(false)}
      >
        <Alert>{t('general.copiedLink')}</Alert>
      </Snackbar>
    </>
  );
};
const DisableCoupon = (props: any) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const { couponType } = props;
  // console.log('couponType', couponType);
  const convertData = (response: any, isNext: boolean) => {
    // console.log('convertData', response);
    const data = response.historyLogList;
    if (data) {
      const temp = data.map((item: any) => {
        return {
          id: item._id,
          denomination: item.denomination,
          name: item.couponName ? getApiDataByLang(item.couponName) : '-',
          code: item.code,
          expiryDate: item.end_date,
          usedDate: item.used_date,
          logo: item.logoImage,
          background: item.backgroundImage,
          backgroundColor: item.cardBackgroundColour,
          primaryColor: item.primaryTextColour,
          secondaryColor: item.secondaryTextColour,
          status: item.status,
        };
      });
      if (!isNext) {
        setData(temp);
      } else {
        setData((prev: any) => [...prev, ...temp]);
      }
    }
  };
  return (
    <>
      <InfiniteScrollContainer
        fetchData={getHistoryList}
        returnData={convertData}
        apiParams={{ type: couponType }}
        list={data}
        pageSize={10}
        loadingSpinner={
          <Skeleton variant="rounded" height={146} style={{ margin: '16px' }} />
        }
      >
        {data.length > 0 && (
          <Grid container className={couponStyles.container}>
            {data.map((item: any) => {
              return (
                <CouponItem
                  {...item}
                  showBackground={false}
                  key={`coupon-${item.id}`}
                  onItemClick={(item) => {
                    navigate(`/my-coupon/detail/${item.id}`);
                  }}
                />
              );
            })}
          </Grid>
        )}
      </InfiniteScrollContainer>
    </>
  );
};
const ExpiredList = (props: any) => {
  const { couponType } = props;
  return <DisableCoupon couponType={couponType} />;
};
const UsedList = (props: any) => {
  const { couponType } = props;
  const { t } = useTranslation();
  return (
    <>
      <DisableCoupon couponType={couponType} />
      <div className={styles.tips}>
        {t('coupon.disclaimer', { name: t('coupon.used') })}
      </div>
    </>
  );
};
const ReceivedList = (props: any) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const { couponType } = props;
  const convertData = (response: any, isNext: boolean) => {
    // console.log('convertData', response);
    const data = response.historyLogList;
    if (data) {
      const temp = data.map((item: any) => {
        return {
          id: item._id,
          receivedDate: item.createTime,
          receivedCoupon: item.couponCount,
        };
      });
      if (!isNext) {
        setData(temp);
      } else {
        setData((prev: any) => [...prev, ...temp]);
      }
    }
  };
  return (
    <>
      <InfiniteScrollContainer
        fetchData={getHistoryList}
        returnData={convertData}
        apiParams={{ type: couponType }}
        list={data}
        pageSize={10}
        loadingSpinner={
          <Skeleton
            variant="rectangular"
            height={53}
            style={{ margin: '16px' }}
          />
        }
      >
        {data.length > 0 && (
          <ul className={styles.receivedList}>
            {data.map((item: any) => {
              return (
                <li key={`received-${item.id}`}>
                  <div className={styles.date}>
                    {convertDateTimeWithFullMonth(item.receivedDate)}
                  </div>
                  <div className={styles.text}>
                    {t('coupon.receivedCoupon', {
                      number: item.receivedCoupon,
                    })}
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </InfiniteScrollContainer>
    </>
  );
};

const CouponHistory = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tabData, setTabData] = useState<tabType[]>([]);
  const [listData, setListData] = useState<any[]>([]);
  const historyTabList = [
    {
      id: 'Transferred',
      name: t('coupon.transferred'),
    },
    {
      id: 'Expired',
      name: t('coupon.expired'),
    },
    {
      id: 'Used',
      name: t('coupon.used'),
    },
    {
      id: 'Received',
      name: t('coupon.received'),
    },
  ];
  const [activeTab, setActiveTab] = useState<string>(historyTabList[0].id);

  useEffect(() => {
    const tab = sessionStorage.getItem('CouponHistoryTab');
    if (tab) {
      setActiveTab(tab);
    }
  }, []);

  useEffect(() => {
    // console.log('historyTabList', historyTabList);
    const getTabData = async () => {
      setLoading(true);
      try {
        const response = await getHistoryCount();

        if (response.status === 200) {
          const data = response.data;
          const temp = [
            {
              value: 'Transferred',
              display: `${t('coupon.transferred')} (${data.transferredCount ?? 0})`,
            },
            {
              value: 'Expired',
              display: `${t('coupon.expired')} (${data.expiredCount ?? 0})`,
            },
            {
              value: 'Used',
              display: `${t('coupon.used')} (${data.usedCount ?? 0})`,
            },
            {
              value: 'Received',
              display: `${t('coupon.received')} (${data.receivedCount ?? 0})`,
            },
          ];
          setTabData(temp);
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    };
    getTabData();
  }, []);

  const handleChangeTab = async (value: string) => {
    // console.log('handleChangeTab', value);
    sessionStorage.setItem('CouponHistoryTab', value);
    setActiveTab(value);
  };

  const handleSwitchTab = useCallback(() => {
    // console.log('activeTab', activeTab);
    switch (activeTab) {
      case 'Expired':
        return <ExpiredList couponType={'Expired'} />;
      case 'Used':
        return <UsedList couponType={'Used'} />;
      case 'Received':
        return <ReceivedList couponType={'Received'} />;
      default:
        return <TransferredList couponType={activeTab} />;
    }
  }, [activeTab]);

  return (
    <>
      <Header
        title={t('coupon.history')}
        enableBackButton
        closeButtonFunction={() => {
          sessionStorage.removeItem('CouponHistoryTab');
          navigate('/my-coupon');
        }}
      />
      {tabData.length > 0 && (
        <Grid item className={styles.contentContainer} id="scrollableDiv">
          <Box className={styles.tabWrapper}>
            <CustomTab
              data={tabData}
              activeTab={activeTab}
              onTabClick={handleChangeTab}
            />
          </Box>
          {handleSwitchTab()}
        </Grid>
      )}
    </>
  );
};

export default CouponHistory;
