import React, { FC, useEffect, useState } from 'react';

import { Box, Divider, Grid, Typography } from '@mui/material';
import toHtml from 'html-react-parser';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AccountItem, Popup } from '../../components';
import Header from '../../components/header/header';

import {
  getSocialWallDetails,
  getVoteDetails,
} from '../../services/social-wall';
import { convertDate, convertTime, convertUrlsToLinks } from '../../utility';
import styles from './socialWallPollResult.module.scss';

interface responseProps {
  id: string;
  value: string;
  vote: number;
}
interface dataProps {
  id: string;
  question: string;
  expiryDateTime: string;
  isAnonymous: boolean;
  isPictureChoice: boolean;
  isPublic: boolean;
  totalVote: number;
  maxVote: number;
  response: Array<responseProps>;
}

const SocialWallPollResult: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState<dataProps>();
  const [showResponseList, setShowResponseList] = useState<boolean>(false);
  const [responseListData, setResponseListData] = useState<Array<any>>([]);
  let { id } = useParams();
  useEffect(() => {
    setIsLoading(true);
    const countTotalVote = (votings: any, votingStat: any) => {
      let totalVote = 0;
      votings.forEach((item: any) => {
        totalVote = totalVote + votingStat[item.seq];
      });
      return totalVote;
    };

    const getResponse = (votings: any, votingStat: any) => {
      const response = votings.map((item: any, index: number) => {
        return {
          id: item.seq,
          value: item.voteName,
          vote: votingStat[item.seq],
          imageUrl: item.imageUrl,
        };
      });
      return response;
    };

    const convertResponse = (data: any) => ({
      id: data._id,
      question: handleTag(convertUrlsToLinks(data.pollInfo.title)),
      expiryDateTime: data.pollInfo?.pollEndTime,
      isAnonymous: data.pollInfo?.isVoteAnonymously,
      isPublic: data.isPublic,
      isPictureChoice: data.isPictureChoice ?? false,
      totalVote: countTotalVote(data.pollInfo.votings, data.votingStat),
      maxVote: data.totalAudienceCount,
      response: getResponse(data.pollInfo.votings, data.votingStat),
    });

    getSocialWallDetails(id as string).then((res) => {
      setData(convertResponse(res.data));
      setIsLoading(false);
    });

    // setData(mockData.find((data: any) => data.id === id));
  }, [id]);

  const handleShowVoteDetails = (option: number) => {
    setShowResponseList(true);
    getVoteDetails(id as string, option).then((res) => {
      setResponseListData(res.data.voteRecords);
    });
  };
  const handleTag = (content: any) => {
    const tag = content.match(
      /(#)([\u4e00-\u9fa5_a-zA-Z0-9]+)|(@@[[)([\u4e00-\u9fa5_a-zA-Z0-9\s]+)(]]&&[[)([\u4e00-\u9fa5_a-zA-Z0-9\s]+)(]]@@)/g
    );
    var remaining = content;
    if (tag) {
      const repl = tag.map((word: any, tagIndex: number) => {
        // console.log("handleTag progress", [content, remaining]);
        let index = remaining.indexOf(word);
        let before = remaining.slice(0, index);
        let after = remaining.slice(index + word.length, remaining.length);
        remaining = after;

        return (
          <React.Fragment key={tagIndex}>
            {toHtml(before)}
            <span
              className={styles.hashtag}
              onClick={() => {
                if (word.charAt(0) === '#') {
                  navigate(`/social-wall/search/${word.slice(1, word.length)}`);
                } else if (word.charAt(0) === '@') {
                  navigate(
                    `/discover-people/profile/${word
                      .split('&&')[1]
                      .replace('[[', '')
                      .replace(']]', '')
                      .replace('@@', '')}`
                  );
                }
              }}
            >
              {word.charAt(0) === '#'
                ? word
                : word
                    .split('&&')[0]
                    .replace('[[', '')
                    .replace(']]', '')
                    .replace('@@', '')}
            </span>
            {tagIndex === tag.length - 1 ? after : ''}
          </React.Fragment>
        );
      });

      return repl;
    }
    return toHtml(content);
  };

  const detailsItem = (text: string, content: string) => (
    <Box className={styles.itemWrapper}>
      <Typography className={styles.label} variant="body1">
        {text}
      </Typography>
      <Typography className={styles.info} variant="body1">
        {content}
      </Typography>
    </Box>
  );
  const reponsesItem = (response: Array<any>) => (
    <Box className={styles.detailsContainer}>
      {response.map((item, index) => (
        <AccountItem
          key={index}
          name={item.name.name}
          icon={item.profile.userProfilePictureUrl}
          description={item.profile.jobTitle}
        />
      ))}
    </Box>
  );

  const popupTitle = (
    <Box className={styles.title}>
      <span>{t('socialWall.poll.responses')}</span>
      <img
        src="/assets/images/close_btn.svg"
        onClick={() => {
          setShowResponseList(false);
        }}
      />
    </Box>
  );
  // console.log("data", data);
  const reponsesDetail = (
    text: string,
    response: Array<any>,
    isPictureChoice: boolean = false
  ) => (
    <>
      <Box className={styles.itemWrapper}>
        <Typography className={styles.label} variant="body1">
          {text}
        </Typography>
        {data &&
          response.map((item, index) => (
            <>
              <Box key={index} className={styles.menuItem}>
                <Box
                  sx={{
                    width: `${(item.vote / data.totalVote) * 100}%
              `,
                  }}
                  className={styles.precentageBar}
                ></Box>
                <Box
                  key={index}
                  className={styles.menuItemDetail}
                  onClick={() => {
                    if (data.totalVote > 0) {
                      handleShowVoteDetails(item.id);
                    }
                  }}
                >
                  {isPictureChoice && (
                    <div
                      className={styles.imageContainer}
                      style={{ aspectRatio: '4/3' }}
                    >
                      <img
                        src={
                          item.imageUrl ?? '/assets/images/survey_answer.svg'
                        }
                        className={`${styles.img}${
                          item.imageUrl ? ` ${styles.voteImage}` : ''
                        }`}
                        alt={item.voteName}
                      />
                    </div>
                  )}

                  <Typography className={styles.option} variant="body1">
                    {item.value}
                  </Typography>
                  <Typography variant="body2">
                    {item.vote} {t('socialWall.poll.vote')}
                    {data.totalVote > 0 ? (
                      <span>
                        {Math.round((item.vote / data.totalVote) * 100)}%
                      </span>
                    ) : null}
                  </Typography>
                  {!data.isAnonymous && item.vote !== 0 && (
                    <img src="/assets/images/chevron_right_black.svg" />
                  )}
                </Box>
              </Box>
              <Divider />
            </>
          ))}
      </Box>
    </>
  );
  return (
    <>
      <Header
        title={t('socialWall.poll.view')}
        enableCloseButton
        closeButtonFunction={() => {
          navigate(-1);
        }}
      />
      {data && (
        <>
          <Grid item xs className={styles.pollResultContainer}>
            <Box className={styles.numberOfVotes}>
              <img src="/assets/images/pie_chart_icon.svg" />
              <Typography className={styles.option} variant="subtitle2">
                {t('socialWall.poll.partnersVoted', {
                  number: data.totalVote,
                  total_number: data.isPublic
                    ? t('socialWall.all').toLowerCase()
                    : data.maxVote,
                })}
              </Typography>
            </Box>
            <Box className={styles.detailsContainer}>
              {detailsItem(t('socialWall.poll.question'), data.question)}
              {detailsItem(
                t('socialWall.poll.expiration'),
                `${convertDate(data.expiryDateTime, false, true)} ${t(
                  'socialWall.poll.at'
                )} ${convertTime(data.expiryDateTime)}`
              )}
              {detailsItem(
                t('socialWall.poll.voteType'),
                data.isAnonymous
                  ? t('socialWall.poll.anonymousPoll')
                  : t('socialWall.poll.identifiedPoll')
              )}
              {reponsesDetail(
                t('socialWall.poll.responses'),
                data.response,
                data.isPictureChoice
              )}
            </Box>
          </Grid>{' '}
          <Popup
            isOpen={showResponseList}
            setIsOpen={(close: boolean) => setShowResponseList(close)}
            title={popupTitle}
            content={reponsesItem(responseListData)}
            disableActions={true}
          />
        </>
      )}
    </>
  );
};

export default SocialWallPollResult;
