import React, { FC, useEffect, useRef, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import toHtml from 'html-react-parser';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NoResults, Popup, RoundButton, ShortcutMenu } from '../../components';
import Header from '../../components/header/header';
import SearchField from '../../components/search-field';

import { getSurveyTemplateList } from '../../services/survey';
import { convertDate, getApiDataByLang } from '../../utility';
import styles from './surveyTemplate.module.scss';

interface SurveyTemplateProps {
  _id: string;
  displayLanguage: Array<string>;
  thumbUrl: string;
  surveyTemplateName: Array<any>;
  status: string;
}
interface SurveyTemplateComponentProps {
  onBack?: () => void;
  onClickCreate?: (id?: string) => void;
}
function SurveyTemplate(props: SurveyTemplateComponentProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState<Array<SurveyTemplateProps>>([]);
  const [result, setResult] = useState<Array<SurveyTemplateProps>>([]);
  const [isSearching, setIsSearching] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getSurveyTemplateList().then((response) => {
      console.log(response);
      setData(
        response.data.surveyTemplate.filter(
          (item: any) => item.status.toLowerCase() === 'active'
        )
      );
      setResult(
        response.data.surveyTemplate.filter(
          (item: any) => item.status.toLowerCase() === 'active'
        )
      );
      setIsLoading(false);
    });
    setIsLoading(false);
  }, []);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const { value } = e.target;
    if (value !== '') {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }

    setSearchValue(value);
    setResult([]);

    setTimeout(() => {
      setIsLoading(false);
      // setResult(data.surveyTemplate);
      setResult(
        data.filter((item) =>
          getApiDataByLang(item.surveyTemplateName)
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      );
    }, 1000);
  };

  const handleResetField = () => {
    if (!isLoading) {
      setSearchValue('');
      setResult([]);
      setIsSearching(false);
      setTimeout(() => {
        setResult(data);
      }, 2000);
    }
  };

  const navigate = useNavigate();

  const handleClickCreateButton = () => {
    if (props.onClickCreate) {
      props.onClickCreate();
    } else {
      navigate('/survey/create');
    }
  };

  const handleClickTemplate = (templateId: string) => {
    if (props.onClickCreate) {
      props.onClickCreate(templateId);
    } else {
      navigate(`/survey/create/${templateId}`);
    }
  };

  return (
    <>
      <Box className={styles.headerContainer}>
        <Header
          title={t('survey.surveyTemplate')}
          enableBackButton
          disableBottomBorder
          closeButtonFunction={props.onBack}
        >
          <IconButton
            aria-label="Plus"
            sx={{ padding: '0px' }}
            onClick={handleClickCreateButton}
          >
            <img src="/assets/images/toolbar_plus_black.svg" alt="" />
          </IconButton>
        </Header>
      </Box>
      <SearchField
        id="search"
        name="search"
        value={searchValue}
        placeholder={t('general.search')}
        onChange={handleValueChange}
        onReset={handleResetField}
      />
      {!isLoading ? (
        <>
          {result.length !== 0 ? (
            <Grid item xs className={styles.contentContainer}>
              <Box className={styles.templateContainer}>
                {result.map((item: SurveyTemplateProps, index: number) => {
                  if (item?.status.toLowerCase() === 'active') {
                    return (
                      <Box
                        key={index}
                        className={styles.templateWrapper}
                        onClick={() => handleClickTemplate(item._id)}
                      >
                        <img
                          src={item.thumbUrl
                            .replace('_thumb.png', '.png')
                            .replace('_thumb.jpeg', '.jpeg')}
                          alt={getApiDataByLang(item.surveyTemplateName)}
                        />
                        <Typography
                          variant="body1"
                          className={styles.templateTitle}
                        >
                          {getApiDataByLang(item.surveyTemplateName)}
                        </Typography>
                      </Box>
                    );
                  }
                })}
              </Box>
            </Grid>
          ) : (
            <>
              {isSearching ? <NoResults resultsType="SEARCH" /> : <NoResults />}
            </>
          )}
        </>
      ) : (
        <Grid container gap="8px" py="16px">
          <Grid container spacing={2} px="16px">
            {Array.from({ length: 4 }).map((_, index) => (
              <Grid item xs={6}>
                <Skeleton
                  key={index}
                  variant="rounded"
                  height={132}
                  style={{ marginBottom: '16px' }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
}
export default SurveyTemplate;
