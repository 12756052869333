import React, { useEffect } from 'react';

import { Grid } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { BottomNavMenu } from '../../components';
import Header from '../../components/header/header';

import { getCountryList } from '../../services/homepage';
import styles from './storeAndFinancialUpdates.module.scss';

type Props = {};

const StoreAndFinancialUpdates = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = React.useState<any>({});

  useEffect(() => {
    const getCountryData = async () => {
      try {
        const response = await getCountryList();
        if (response.status === 200 && response.data) {
          let totalStore = 0;
          response.data.countryList.forEach((item: any) => {
            if (item.country !== 'Asia Pacific') {
              totalStore += item.endingStores;
            }
          });
          // console.log('totalStore', totalStore);
          setData({
            totalStore: totalStore,
            lastUpdate: `${t('homepage.storeLastUpdate')} ${moment
              .unix(response.data.lastUpdateDate)
              .format('YYYY-MM-DD')}`,
            list: response.data.countryList
              .filter((item: any) => item.country !== 'Asia Pacific')
              .sort((a: any, b: any) => a.country.localeCompare(b.country))
              .map((item: any) => {
                return {
                  name: item.country.replace(/[\s/]/g, ''),
                  flag: `/assets/images/national_flag/${item.country.replace(
                    /[\s/]/g,
                    ''
                  )}.png`,
                  storeCount: item.endingStores,
                };
              }),
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCountryData();
  }, []);

  return (
    <>
      <Header
        title={t('homepage.storeAndFinancialUpdates')}
        enableBackButton
        closeButtonNavigation="/home"
      />
      <Grid container>
        <Grid item className={styles.banner}>
          <Grid item className={styles.info}>
            <div className={styles.title}>{t('homepage.totalStore')}</div>
            <div className={styles.value}>{data.totalStore}</div>
            <div className={styles.date}>{data.lastUpdate}</div>
          </Grid>
          <img src="/assets/images/home_banner_bg_logo.png" alt="banner logo" />
        </Grid>
        <Grid item className={styles.list}>
          {data?.list?.length > 0 &&
            data.list.map((item: any, index: any) => {
              return (
                <div className={styles.item} key={`country-${index}`}>
                  <div className={styles.flag}>
                    <img src={item.flag} alt={item.name} />
                    <div className={styles.name}>
                      {t(`homepage.${item.name}`)}
                    </div>
                  </div>
                  <div className={styles.count}>{item.storeCount}</div>
                </div>
              );
            })}
        </Grid>
      </Grid>
      <BottomNavMenu />
    </>
  );
};

export default StoreAndFinancialUpdates;
