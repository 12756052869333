import { useEffect, useState } from 'react';

import { Box, Grid, Link, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { RoundButton } from '../../../components';
import Header from '../../../components/header/header';

import {
  getGroupDetail,
  joinGroup,
  quitGroup,
} from '../../../services/partner-network';
import { getApiDataByLang } from '../../../utility';
import usePermissionHook from '../hook/usePermissionHook';
import styles from '../partnerNetwork.module.scss';
import GroupActionPopup from './groupActionPopup';
import MorePopup from './morePopup';

type Props = {
  id: string;
  closeButtonNavigation: string;
  headerButton: JSX.Element;
  passDetail?: (detail: any) => void;
  isHidden?: (isHidden: boolean) => void;
};

const GroupDetail = (props: Props) => {
  // console.log("data", props.data);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isMoreOpen, setMoreOpen] = useState<boolean>(false);
  const [isQuitGroupOpen, setQuitGroupOpen] = useState<boolean>(false);
  const [detail, setDetail] = useState<any>(null!);
  const { t } = useTranslation();
  const { socialWallPermission, userState } = usePermissionHook();
  const isAdmin = Boolean(userState.permission?.SocialWallAdmin);
  useEffect(() => {
    setIsLoading(true);
    const getDetail = async () => {
      try {
        const response = await getGroupDetail(props.id);
        if (
          response.status === 200 &&
          Object.keys(response.data.partnerNetworkGroup).length > 0
        ) {
          // console.log("response", response);
          const result = response.data.partnerNetworkGroup;
          setDetail(result);
          props.passDetail &&
            props.passDetail({
              about: getApiDataByLang(result.description),
              memberCount: result.memberCount,
              members: result.memberList,
            });
          props.isHidden && props.isHidden(result.isHidden);
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };
    getDetail();
  }, []);

  const handleJoinGroup = async () => {
    try {
      const response = await joinGroup(props.id);
      if (response.status === 200) {
        // console.log("handleJoinGroup", response);
        window.location.reload();
      }
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

  const handleQuitGroup = async () => {
    try {
      const response = await quitGroup(props.id);
      if (response.status === 200) {
        // console.log("handleQuitGroup", response);
        window.location.reload();
      }
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

  return (
    <>
      {detail ? (
        <>
          <Header
            title={getApiDataByLang(detail.groupName)}
            enableBackButton
            closeButtonFunction={() => {
              console.log('window.location', window.location.href);
              navigate(-1);
            }}
          >
            {isAdmin && (
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-end"
              >
                <Grid item xs="auto">
                  {props.headerButton}
                </Grid>
              </Grid>
            )}
          </Header>
          <div className={'groupDetail'}>
            <div className={styles.groupDetailInfo}>
              <Box className={styles.groupCount}>
                <Box className={`countItem ${styles.countItem}`}>
                  <img
                    src="/assets/images/homepage_member_total.svg"
                    alt="total member"
                  />
                  <Typography variant="body2">{detail.memberCount}</Typography>
                </Box>
                <Box className={`countItem ${styles.countItem}`}>
                  <img
                    src="/assets/images/homepage_post_total.svg"
                    alt="total posts"
                  />
                  <Typography variant="body2">{detail.postCount}</Typography>
                </Box>
              </Box>
              {!detail.isJoined ? (
                <RoundButton
                  style={{ width: 'auto', padding: '10px 16px' }}
                  onClick={handleJoinGroup}
                >
                  {t('partnerNetwork.joinNow')}
                </RoundButton>
              ) : (
                <button
                  className={styles.button}
                  role="button"
                  onClick={() => {
                    setMoreOpen(true);
                  }}
                >
                  {t('partnerNetwork.isJoined')}
                </button>
              )}
            </div>
            <div className={styles.groupDetailContent}>
              <div className={styles.backgroundContainer}>
                <img
                  className={styles.background}
                  src={detail.coverImageUrl}
                  alt={getApiDataByLang(detail.groupName)}
                />
              </div>
              <div className={styles.action}>
                <div className={styles.actionItem}>
                  <Link
                    className={styles.itemWrapper}
                    onClick={() => {
                      navigate(`/partner-network/about/${detail._id}`);
                    }}
                  >
                    <img src="/assets/images/shortcutMenu_MPI.svg" alt="" />
                    <Typography variant="body2">
                      {t('partnerNetwork.about')}
                    </Typography>
                  </Link>
                </div>
                <div className={styles.actionItem}>
                  <Link
                    className={styles.itemWrapper}
                    onClick={() => {
                      navigate(`/partner-network/event/${detail._id}`);
                    }}
                  >
                    <img
                      src="/assets/images/shortcutMenu_calendar.svg"
                      alt=""
                    />
                    <Typography variant="body2">
                      {t('partnerNetwork.event')}
                    </Typography>
                  </Link>
                </div>
                <div className={styles.actionItem}>
                  <Link
                    className={styles.itemWrapper}
                    onClick={() => {
                      navigate(`/partner-network/gallery/${detail._id}`);
                    }}
                  >
                    <img src="/assets/images/Gallery.svg" alt="" />
                    <Typography variant="body2">
                      {t('partnerNetwork.gallery')}
                    </Typography>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {detail.isJoined && (
            <>
              <MorePopup
                isOpen={isMoreOpen}
                setIsOpen={setMoreOpen}
                option={[
                  {
                    image: '/assets/images/exit.svg',
                    text: t('partnerNetwork.quitGroup'),
                    onClick: () => {
                      setMoreOpen(false);
                      setQuitGroupOpen(true);
                    },
                  },
                ]}
                removeTitle
              />
              <GroupActionPopup
                isOpen={isQuitGroupOpen}
                setIsOpen={setQuitGroupOpen}
                title={t('partnerNetwork.quitGroup')}
                sub={t('partnerNetwork.wantQuit', {
                  group: getApiDataByLang(detail.groupName),
                })}
                confirmBtnText={t('partnerNetwork.quit')}
                onClickConfirm={handleQuitGroup}
              />
            </>
          )}
        </>
      ) : (
        <>
          <Skeleton
            variant="rectangular"
            height={48}
            width="100%"
            style={{ marginBottom: '4px' }}
          />
          <Skeleton
            variant="rectangular"
            height={150}
            width="100%"
            style={{ marginBottom: '4px' }}
          />
          <Skeleton
            variant="rectangular"
            height={84}
            width="100%"
            style={{ marginBottom: '4px' }}
          />
        </>
      )}
    </>
  );
};

export default GroupDetail;
