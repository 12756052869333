import React, { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NoResults, PageLoader } from '../../components';
import Header from '../../components/header/header';

import { UserState } from '../../reducers/user-slice';
import {
  getDistrictRankingList,
  getRankingList,
  getSupportCenterRankingList,
} from '../../services/leaderboard';
import { RootState } from '../../store/store';
import { addThousandSeparator, convertDate } from '../../utility';
import styles from './ranking.module.scss';

function Ranking() {
  const navigate = useNavigate();
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );

  const tabItem = [
    { id: 'topRanks', label: 'topRanks' },
    ...(process.env.REACT_APP_LOCATION !== 'HK'
      ? [
          { id: 'district', label: 'district' },
          { id: 'supportCentre', label: 'supportCentre' },
        ]
      : []),
  ];

  // console.log('tabItem', tabItem);

  function groupBy(objectArray: any, property: any) {
    return objectArray.reduce((acc: any, obj: any) => {
      const key = obj[property];
      const curGroup = acc[key] ?? [];

      return { ...acc, [key]: [...curGroup, obj] };
    }, {});
  }

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [endDate, setEndDate] = useState<number>(0);
  // const [myRank, setMyRank] = useState<number>(0);
  const [partnerCherries, setPartnerCherries] = useState<any>({});
  const [districtData, setDistrictData] = useState<any>([]);
  const [userDistrict, setUserDistrict] = useState<any>();
  const [districtList, setDistrictList] = useState<any>();
  const [isExpanded, setIsExpanded] = useState<any>();
  const [tabValue, setTabValue] = useState(0);
  const [noCampaign, setNoCampaign] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getRankingList()
      .then((result) => {
        setEndDate(result.data.endDate);
        setDistrictList(result.data.district);
        if (!result.data.supportCentreCheck) {
          setUserDistrict(
            result.data.district.find(
              (districtItem: any) => districtItem.id === result.data.districtId
            )
          );
        }
        setIsExpanded(
          result.data.district.find(
            (districtItem: any) => districtItem.id === result.data.districtId
          )
            ? result.data.district.find(
                (districtItem: any) =>
                  districtItem.id === result.data.districtId
              ).id
            : result.data.district[0].id
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setNoCampaign(true);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (tabItem[tabValue].id === 'topRanks') {
      getRankingList()
        .then((result) => {
          setData(result.data.topRanks);
          // setMyRank(checkMyRanking(result.data.topRanks));
          setPartnerCherries(result.data.partnerCherries);
          setIsLoading(false);
        })
        .catch((err) => {});
    } else if (tabItem[tabValue].id === 'district') {
      if (isExpanded) {
        getDistrictRankingList(isExpanded)
          .then((result) => {
            setPartnerCherries(result.data.partnerCherries);
            setDistrictData(result.data.topRanks);
            setIsLoading(false);
          })
          .catch((err) => {});
      } else {
        setIsLoading(false);
      }
    } else if (tabItem[tabValue].id === 'supportCentre') {
      getSupportCenterRankingList()
        .then((result) => {
          setData(result.data.topRanks);
          // setMyRank(checkMyRanking(result.data.topRanks));
          setPartnerCherries(result.data.partnerCherries);
          setIsLoading(false);
        })
        .catch((err) => {});
    }
  }, [tabValue]);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setData('');
    setTabValue(newValue);
  };

  const handleAccordionChange = (newValue: string) => {
    if (newValue === isExpanded) {
      setIsExpanded('');
    } else {
      getDistrictRankingList(newValue)
        .then((result) => {
          setDistrictData(result.data.topRanks);
          setIsExpanded(newValue);
        })
        .catch((err) => {
          setIsExpanded(newValue);
        });
    }
  };
  return noCampaign ? (
    <>
      <Header
        title={t('leaderboard.rankings')}
        enableBackButton
        disableBottomBorder
        closeButtonFunction={() => {
          navigate(-1);
        }}
      ></Header>
      <Box className={styles.noResultsContainer}>
        <NoResults />
      </Box>
    </>
  ) : (
    <>
      <Grid item xs className={styles.pageContainer}>
        <Header
          title={t('leaderboard.rankings')}
          enableBackButton
          disableBottomBorder
          colouredBackground
        ></Header>

        <Stack className={styles.contentContainer}>
          {endDate !== 0 && (
            <Typography className={styles.date} variant="body2">
              {t('leaderboard.seasonEndDate', {
                date: convertDate(endDate, false, true),
              })}
            </Typography>
          )}
          <Box className={styles.tabContainer}>
            <Tabs value={tabValue} onChange={handleChangeTab} centered>
              {tabItem.map((item, index) => (
                <Tab label={t(`leaderboard.${item.label}`)} />
              ))}
            </Tabs>
          </Box>

          {!isLoading && data && (
            <>
              {tabItem[tabValue].id === 'topRanks' && (
                <Box className={styles.topThreeContainer}>
                  <img
                    src="/assets/images/crown_deco.svg"
                    className={styles.crown}
                  />
                  <img
                    src="/assets/images/star_deco.svg"
                    className={styles.star1_1}
                  />
                  <img
                    src="/assets/images/star_deco.svg"
                    className={styles.star1_2}
                  />
                  <img
                    src="/assets/images/star_deco.svg"
                    className={styles.star2}
                  />
                  <img
                    src="/assets/images/star_deco.svg"
                    className={styles.star3}
                  />
                  {data.map((item: any, index: number) => {
                    if (index <= 2) {
                      return (
                        <Stack
                          key={index}
                          className={`${styles.rankItem} top${index + 1}`}
                          gap="6px"
                        >
                          <Box className={styles.iconWrapper}>
                            <Avatar
                              src={item.userProfilePictureUrl + '?cache=true'}
                              onClick={() =>
                                navigate(
                                  `/discover-people/profile/${item.partnerId}`
                                )
                              }
                            />
                            <Typography className={styles.rank} variant="body2">
                              {index + 1}
                            </Typography>
                          </Box>
                          <Typography>
                            {partnerCherries.rank === index + 1
                              ? t(`leaderboard.you`)
                              : item.userName}
                          </Typography>
                          <Typography className={styles.points}>
                            {addThousandSeparator(item.cherries)}
                          </Typography>
                        </Stack>
                      );
                    }
                  })}
                  {partnerCherries.rank &&
                    partnerCherries.rank <= 3 &&
                    partnerCherries.rank > data.length && (
                      <Stack
                        className={`${styles.rankItem} top${partnerCherries.rank}`}
                        gap="6px"
                      >
                        <Box className={styles.iconWrapper}>
                          <Avatar
                            src={
                              partnerCherries.userProfilePictureUrl +
                              '?cache=true'
                            }
                            onClick={() =>
                              navigate(
                                `/discover-people/profile/${partnerCherries.partnerId}`
                              )
                            }
                          />
                          <Typography className={styles.rank} variant="body2">
                            {partnerCherries.rank}
                          </Typography>
                        </Box>
                        <Typography>{t(`leaderboard.you`)}</Typography>
                        <Typography className={styles.points}>
                          {addThousandSeparator(partnerCherries.cherries)}
                        </Typography>
                      </Stack>
                    )}
                </Box>
              )}
              {tabItem[tabValue].id !== 'district' && (
                <>
                  {((tabItem[tabValue].id === 'topRanks' &&
                    partnerCherries.rank > 3) ||
                    tabItem[tabValue].id === 'supportCentre') &&
                    partnerCherries.rank && (
                      <Stack
                        className={`${styles.rankingList} ${styles.greyBg}`}
                      >
                        <Stack
                          direction="row"
                          className={styles.rankItem}
                          gap="8px"
                        >
                          <Typography className={styles.rank}>
                            {partnerCherries.rank}
                          </Typography>
                          <Avatar
                            src={userState.avatar + '?cache=true' ?? ''}
                            onClick={() =>
                              navigate(
                                `/discover-people/profile/${userState.partnerId}`
                              )
                            }
                          />
                          <Typography>{t(`leaderboard.you`)}</Typography>
                          <Stack className={styles.pointsContainer}>
                            <Typography
                              variant="caption"
                              className={styles.note}
                            >
                              {t('leaderboard.pointEarned')}
                            </Typography>
                            <Typography className={styles.points}>
                              {addThousandSeparator(partnerCherries.cherries)}
                              <span>{t('leaderboard.pts')}</span>
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    )}

                  <Stack
                    className={`${styles.rankingList} ${
                      (tabItem[tabValue].id === 'topRanks' &&
                        partnerCherries.rank > 3) ||
                      (tabItem[tabValue].id === 'supportCentre' &&
                        partnerCherries.rank)
                        ? styles.notRoundCorner
                        : ''
                    }`}
                    gap="16px"
                  >
                    {data.length === 0 && !partnerCherries.partnerId ? (
                      <NoResults />
                    ) : (
                      data.map((item: any, index: number) => {
                        if (
                          ((tabItem[tabValue].id === 'topRanks' &&
                            index >= 3) ||
                            tabItem[tabValue].id === 'supportCentre') &&
                          partnerCherries.rank !== index + 1
                        ) {
                          return (
                            <Stack
                              key={index}
                              direction="row"
                              className={styles.rankItem}
                              gap="8px"
                            >
                              <Typography className={styles.rank}>
                                {item.rank}
                              </Typography>
                              <Avatar
                                src={item.userProfilePictureUrl + '?cache=true'}
                                onClick={() =>
                                  navigate(
                                    `/discover-people/profile/${item.partnerId}`
                                  )
                                }
                              />
                              <Typography>
                                {partnerCherries.rank === index + 1
                                  ? t(`leaderboard.you`)
                                  : item.userName}
                              </Typography>

                              <Typography className={styles.points}>
                                {addThousandSeparator(item.cherries)}{' '}
                                <span>{t('leaderboard.pts')}</span>
                              </Typography>
                            </Stack>
                          );
                        }
                      })
                    )}
                  </Stack>
                </>
              )}{' '}
            </>
          )}
          {tabItem[tabValue].id === 'district' && (
            <Box className={styles.AccordionContainer}>
              {districtList && (
                <>
                  {userDistrict && (
                    <Accordion
                      expanded={isExpanded === userDistrict.id}
                      onChange={() => handleAccordionChange(userDistrict.id)}
                    >
                      <AccordionSummary
                        expandIcon={
                          <img src="/assets/images/chevron_right.svg" />
                        }
                        aria-controls={`panel${0}a-content`}
                        id={`panel${0}a-header`}
                      >
                        <Typography>{userDistrict.districtName}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {districtData && (
                          <>
                            {/* {districtData.find(
                              (item: any) =>
                                item.partnerId === userState.partnerId
                            )  */}
                            {partnerCherries && (
                              <Stack
                                className={`${styles.rankingList} ${styles.greyBg}`}
                              >
                                <Stack
                                  direction="row"
                                  className={styles.rankItem}
                                  gap="8px"
                                >
                                  <Typography className={styles.rank}>
                                    {partnerCherries.rank}
                                  </Typography>
                                  <Avatar
                                    src={userState.avatar + '?cache=true' ?? ''}
                                    onClick={() =>
                                      navigate(
                                        `/discover-people/profile/${userState.partnerId}`
                                      )
                                    }
                                  />
                                  <Typography>
                                    {t(`leaderboard.you`)}
                                  </Typography>
                                  <Stack className={styles.pointsContainer}>
                                    <Typography
                                      variant="caption"
                                      className={styles.note}
                                    >
                                      {t('leaderboard.pointEarned')}
                                    </Typography>
                                    <Typography className={styles.points}>
                                      {addThousandSeparator(
                                        partnerCherries.cherries
                                      )}{' '}
                                      <span>{t('leaderboard.pts')}</span>
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            )}

                            {districtData.map((item: any, index: number) => {
                              if (item.partnerId !== userState.partnerId) {
                                return (
                                  <Stack
                                    className={styles.rankingList}
                                    gap="16px"
                                  >
                                    <Stack
                                      key={index}
                                      direction="row"
                                      className={styles.rankItem}
                                      gap="8px"
                                    >
                                      <Typography className={styles.rank}>
                                        {index + 1}
                                      </Typography>
                                      <Avatar
                                        src={
                                          item.userProfilePictureUrl +
                                          '?cache=true'
                                        }
                                        onClick={() =>
                                          navigate(
                                            `/discover-people/profile/${item.partnerId}`
                                          )
                                        }
                                      />
                                      <Typography>{item.userName}</Typography>

                                      <Typography className={styles.points}>
                                        {addThousandSeparator(item.cherries)}{' '}
                                        <span>{t('leaderboard.pts')}</span>
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                );
                              }
                            })}
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )}

                  {districtList.map((districtItem: any, index: number) => {
                    if (districtItem.id !== userDistrict?.id) {
                      return (
                        <Accordion
                          expanded={isExpanded === districtItem.id}
                          onChange={() =>
                            handleAccordionChange(districtItem.id)
                          }
                        >
                          <AccordionSummary
                            expandIcon={
                              <img src="/assets/images/chevron_right.svg" />
                            }
                            aria-controls={`panel${index + 1}a-content`}
                            id={`panel${index + 1}a-header`}
                          >
                            <Typography>{districtItem.districtName}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {isExpanded === districtItem.id &&
                            districtData.length > 0 ? (
                              <Stack className={styles.rankingList} gap="16px">
                                {districtData.map(
                                  (item: any, index: number) => {
                                    return (
                                      <Stack
                                        key={index}
                                        direction="row"
                                        className={styles.rankItem}
                                        gap="8px"
                                      >
                                        <Typography className={styles.rank}>
                                          {index + 1}
                                        </Typography>
                                        <Avatar
                                          src={
                                            item.userProfilePictureUrl +
                                            '?cache=true'
                                          }
                                          onClick={() =>
                                            navigate(
                                              `/discover-people/profile/${item.partnerId}`
                                            )
                                          }
                                        />
                                        <Typography>{item.userName}</Typography>

                                        <Typography className={styles.points}>
                                          {addThousandSeparator(item.cherries)}{' '}
                                          <span>{t('leaderboard.pts')}</span>
                                        </Typography>
                                      </Stack>
                                    );
                                  }
                                )}
                              </Stack>
                            ) : (
                              <Box className={styles.emptyList}>
                                {t('general.stayTunedMsg')}
                              </Box>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      );
                    }
                  })}
                </>
              )}
            </Box>
          )}
          {isLoading && <PageLoader />}
        </Stack>
      </Grid>
    </>
  );
}

export default Ranking;
