import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { UserState } from '../../reducers/user-slice';
import { RootState } from '../../store/store';
import styles from './bottom-nav-menu.module.scss';

interface IBreadcrumbProps {
  // data: any;
  isUploading?: boolean;
  afterUpload?: (url: string) => void;
}

const BottomNavMenu = (props: IBreadcrumbProps) => {
  const { isUploading, afterUpload } = props;
  // const [activeItem, setActiveItem] = useState(0);
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const menuItems = [
    {
      id: 'Home',
      image: '/assets/images/navMenu_home.svg',
      colouredImage: '/assets/images/navMenu_home_coloured.svg',
      text: 'general.home',
      url: '/home',
    },
    {
      id: 'SocialWall',
      image: '/assets/images/navMenu_socialWall.svg',
      colouredImage: '/assets/images/navMenu_socialWall_coloured.svg',
      text: 'general.socialWall',
      url: '/social-wall',
    },
    {
      id: process.env.REACT_APP_LOCATION === 'HK' ? 'Coupon' : 'Event',
      image:
        process.env.REACT_APP_LOCATION === 'HK'
          ? '/assets/images/CouponButton.svg'
          : '/assets/images/navMenu_event.svg',
      colouredImage:
        process.env.REACT_APP_LOCATION === 'HK'
          ? '/assets/images/CouponButton.svg'
          : '/assets/images/navMenu_event_coloured.svg',
      text:
        process.env.REACT_APP_LOCATION === 'HK'
          ? 'general.coupon'
          : 'general.event',
      url: process.env.REACT_APP_LOCATION === 'HK' ? '/my-coupon' : '/event',
    },
    {
      id: 'Me',
      image: '/assets/images/navMenu_me.svg',
      colouredImage: '/assets/images/navMenu_me_coloured.svg',
      text: 'general.me',
      url: '/me',
    },
  ];

  if (userState?.chat?.ChatFunction) {
    menuItems.splice(3, 0, {
      id: 'Chat',
      image: '/assets/images/navMenu_chat.svg',
      colouredImage: '/assets/images/navMenu_chat_coloured.svg',
      text: 'general.chat',
      url: '/chat',
    });
  } else {
    if (process.env.REACT_APP_LOCATION === 'HK') {
      menuItems.splice(3, 0, {
        id: 'MyCard',
        image: '/assets/images/myCard.svg',
        colouredImage: '/assets/images/myCard_coloured.svg',
        text: 'general.myCard',
        url: '/green-apron-card',
      });
    }
  }

  // console.log('menuItems', menuItems);
  // const changePage = (event: React.MouseEvent<HTMLElement>, index: number) => {
  //   setActiveItem(index);
  // };
  const changePage = (url: string) => {
    !isUploading ? navigate(url) : afterUpload && afterUpload(url);
  };

  return (
    <Box className={styles.menuItemContainer}>
      {menuItems.map((item, index) => {
        // console.log('item', item);
        return (
          <Box
            className={`${styles.menuItem} ${
              location.pathname.includes(item.url) ? styles.active : ''
            }`}
            // href={item.url}
            onClick={(event) => changePage(item.url)}
            key={index}
            {...(item.id === 'Coupon' && {
              style: { justifyContent: 'flex-end', border: 'none' },
            })}
          >
            <img
              {...(item.id === 'Coupon' && { className: styles.couponIcon })}
              src={
                location.pathname.includes(item.url)
                  ? item.colouredImage
                  : item.image
              }
              alt=""
            />
            <Typography variant="body2" align="center">
              {t(item.text)}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default BottomNavMenu;
