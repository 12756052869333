import React, { useEffect, useState } from 'react';

import { Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BottomNavMenu } from '../../components';
import Header from '../../components/header/header';

import { getGroupList, getJoinedList } from '../../services/partner-network';
import { getApiDataByLang } from '../../utility';
import GroupList from './components/groupList';
import JoinedList from './components/joinedList';
import MorePopup from './components/morePopup';
import SearchSection from './components/search';
import SnackBar from './components/snackBar';
import usePermissionHook from './hook/usePermissionHook';
import styles from './partnerNetwork.module.scss';

type Props = {};

const PartnerNetwork = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const popupListItem = [
    {
      image: '/assets/images/group_create.svg',
      text: t('partnerNetwork.createGroup'),
      url: '/partner-network/create-group',
    },
    {
      image: '/assets/images/postActionIcon_edit.svg',
      text: t('partnerNetwork.groupManagement'),
      url: '/partner-network/management',
    },
  ];
  const [isMoreOpen, setMoreOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [joinedList, setJoinedList] = useState<Array<any>>([]);
  const [groupList, setGroupList] = useState<Array<any>>([]);
  const [isGroupEnded, setGroupEnded] = useState<boolean>(false);
  const { socialWallPermission, userState } = usePermissionHook();
  const isAdmin = Boolean(userState.permission?.SocialWallAdmin);
  const [apiPagination, setApiPagination] = React.useReducer(
    (prev: PaginationType, next: Partial<PaginationType>) => {
      return { ...prev, ...next };
    },
    {
      page: 1,
      pageSize: 10,
    }
  );
  useEffect(() => {
    setIsLoading(true);
    const getList = async () => {
      try {
        const joinedResponse = await getJoinedList({ page: 1, pageSize: 3 });
        const groupResponse = await getGroupList({
          page: apiPagination.page,
          pageSize: apiPagination.pageSize,
        });
        if (
          joinedResponse.status === 200 &&
          joinedResponse.data.result.length > 0
        ) {
          // console.log("joinedResponse", joinedResponse);
          const result = joinedResponse.data.result;
          setJoinedList(
            result.map((item: any) => {
              return {
                id: item._id,
                title: getApiDataByLang(item.groupName),
                image: item.coverImageUrl,
                subtitle: `${item.memberCount} ${t('partnerNetwork.members')}`,
              };
            })
          );
        }
        if (
          groupResponse.status === 200 &&
          groupResponse.data.result.length > 0
        ) {
          const result = groupResponse.data.result;
          setGroupList(
            result.map((item: any) => {
              return {
                id: item._id,
                content: {
                  id: item._id,
                  name: getApiDataByLang(item.groupName),
                  imageUrl: item.coverImageUrl,
                  members: item.memberList,
                  memberCount: item.memberCount,
                  totalPosts: item.postCount,
                },
              };
            })
          );
          if (
            apiPagination.page >= groupResponse.data.totalPage ||
            !groupResponse.data.totalPage
          ) {
            setGroupEnded(true);
          } else {
            setApiPagination({ page: apiPagination.page + 1 });
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };
    getList();
  }, []);

  async function fetchMoreGroups() {
    try {
      const groupResponse = await getGroupList({
        page: apiPagination.page,
        pageSize: apiPagination.pageSize,
      });
      if (
        groupResponse.status === 200 &&
        groupResponse.data.result.length > 0
      ) {
        const result = groupResponse.data.result;
        const temp = result.map((item: any) => {
          return {
            id: item._id,
            content: {
              id: item._id,
              name: getApiDataByLang(item.groupName),
              imageUrl: item.coverImageUrl,
              members: item.memberList,
              memberCount: item.memberCount,
              totalPosts: item.postCount,
            },
          };
        });
        setGroupList((prev: any) => [...prev, ...temp]);

        if (apiPagination.page >= groupResponse.data.totalPage) {
          setGroupEnded(true);
        } else {
          setApiPagination({ page: apiPagination.page + 1 });
        }
      }
    } catch (error) {
      throw error;
    }
  }

  return (
    <>
      <Header
        title={t('partnerNetwork.title')}
        enableBackButton
        closeButtonFunction={() => {
          navigate(-1);
        }}
      >
        {isAdmin && (
          <Grid container spacing={2} direction="row" justifyContent="flex-end">
            <Grid item xs="auto">
              <IconButton
                aria-label="Search"
                sx={{ padding: '0px' }}
                onClick={() => {
                  setMoreOpen(true);
                }}
              >
                <img src="/assets/images/moreAction_icon.svg" alt="" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Header>
      <SearchSection />
      <div className={styles.groupContainer}>
        {joinedList.length > 0 && <JoinedList data={joinedList} />}
        <GroupList
          data={groupList}
          next={fetchMoreGroups}
          hasMore={!isGroupEnded}
        />
      </div>
      <BottomNavMenu />
      <MorePopup
        isOpen={isMoreOpen}
        setIsOpen={setMoreOpen}
        option={popupListItem}
      />
      <SnackBar />
    </>
  );
};

export default PartnerNetwork;
